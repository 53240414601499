import './style.scss'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import LoginBg1 from '@/assets/login-bg1.webp'
import LoginBg2 from '@/assets/login-bg2.webp'
import LogoTitle from '@/assets/logo-title.webp'
import Logo from '@/assets/logo.webp'
import withAuth from '@/components/LoginAuth'
import MobileLogin from './components/mobie-login'
import PasswordLogin from './components/password-login'
import { LoginStore } from './states'

const Login: FC = () => {
  const { loginType } = LoginStore
  const navigate = useNavigate()

  useEffect(() => {
    requestAnimationFrame(() => {
      const marqueeUp = document.querySelector('.marquee-up') as any
      const marqueeDown = document.querySelector('.marquee-down') as any

      if (marqueeUp && marqueeDown) {
        marqueeUp.style.animation = 'none'
        marqueeDown.style.animation = 'none'

        requestAnimationFrame(() => {
          marqueeUp.style.animation = 'scroll 47s linear infinite'
          marqueeDown.style.animation = 'scroll-down 55s linear infinite'
        })
      }
    })
  }, [])

  const onLoginSuccess = (url?: string) => {
    if (url) {
      navigate(url)
    }
  }

  return (
    <div className="page-login">
      <div className="filter-bottom"></div>
      <div className="filter-top"></div>
      <div className="page-login-main">
        <div className="left">
          <div className="marquee-up">
            <img src={LoginBg1} alt="Long Image" className="marquee-image" />
            <img src={LoginBg1} alt="Long Image" className="marquee-image" />
          </div>
          <div className="marquee-down">
            <img src={LoginBg2} alt="Long Image" className="marquee-image" />
            <img src={LoginBg2} alt="Long Image" className="marquee-image" />
          </div>
        </div>
        <div className="right">
          <div className="login-main">
            <img className="logo" src={Logo} />
            <img className="logo-title" src={LogoTitle} />
            <div className="login-box">
              {loginType === 'mobile' ? <MobileLogin onLoginSuccess={onLoginSuccess} /> : <PasswordLogin />}
            </div>
          </div>
          {(location.href.includes('hifly.cc') || location.href.includes('hi.lingverse.co')) && (
            <div className="copyright">
              Copyright © 2024 上海灵之宇技术有限公司
              <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" rel="noreferrer">
                沪ICP备2022030268号-5
              </a>
              <a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch" rel="noreferrer">
                沪公网安备31011002006530号
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default withAuth(Login)
