import './style.scss'
import { Modal } from 'antd'
import { FC, memo, useEffect, useRef } from 'react'
import { IProduct } from '@/global-states'
import { eventTracking } from '@/libs/util'
import UserModalHeader from '../UserModalHeader'
import PlanProduct from './product'

export interface IProductList {
  base: IProduct[]
  high: IProduct[]
}

interface IProps {
  open: boolean
  onCancel?: () => void
  onSuccess?: () => void
}

const PlanModal: FC<IProps> = (props) => {
  const { open, onCancel } = props
  const productRef = useRef<any>()

  useEffect(() => {
    if (open) {
      eventTracking('ProductModalOpen')
      productRef.current?.update()
    }
  }, [open])

  return (
    <>
      <Modal className="plan-modal" footer={null} title={null} onCancel={() => onCancel?.()} open={open}>
        <div className="plan-content">
          <UserModalHeader />
          <PlanProduct onCancel={onCancel} ref={productRef} />
        </div>
      </Modal>
    </>
  )
}

export default memo(PlanModal)
