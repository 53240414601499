import './style.scss'
import { Button, Drawer, Input, message, notification, Space } from 'antd'
import { FC, memo, useEffect, useState } from 'react'
import * as homeApi from '@/api/home'
import SystemDeclare from '@/components/Declare'
import { UserStore } from '@/global-states'
import { showDeclare, urlSource } from '@/libs/util'
import { DeleteOutlined } from '@ant-design/icons'
import UploadFile from '../UploadFile'

interface IProps {
  open: boolean
  editId?: number
  onCancel?: () => void
  onOk?: () => void
}

const CreateKnowledgeDrawer: FC<IProps> = (props) => {
  const { open, onCancel, editId, onOk } = props
  const { declareChecked } = UserStore
  const [form, setForm] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [qaFiles, setQaFiles] = useState<any[]>([])
  // const [docFiles, setDocFiles] = useState<any[]>([])

  useEffect(() => {
    if (open) {
      setForm({
        name: '',
        description: '',
        wake_word: '',
        prompt: ''
      })
      setQaFiles([])
      if (editId) {
        getDetail()
      }
    }
  }, [open])

  const getDetail = async () => {
    const res = await homeApi.getAgentDetail(editId as number)
    setForm(res || {})
    setQaFiles(res.qa_files || [])
  }

  const onFormChange = (key: string, e: any) => {
    setForm({
      ...form,
      [key]: e.target.value
    })
  }

  const onQaUploadSuccess = (data: any) => {
    setQaFiles([
      ...qaFiles,
      {
        ...data
      }
    ])
  }

  const deleteQaFile = (index: number) => {
    setQaFiles(qaFiles.filter((_, i) => i !== index))
  }

  const onConfirm = async () => {
    if (!declareChecked) {
      await showDeclare()
    }

    if (!form.name?.trim()) {
      return message.warning('请输入智能体名字')
    }

    setLoading(true)
    try {
      if (editId) {
        await homeApi.updateAgent(editId, {
          ...form,
          qa_files: qaFiles || []
        })
      } else {
        await homeApi.addAgent({
          ...form,
          qa_files: qaFiles || []
        })
      }

      notification.success({
        message: '提交成功'
      })
      onCancel?.()
      onOk?.()
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Drawer
        className="create-drawer"
        title="创建智能体"
        width={720}
        onClose={onCancel}
        open={open}
        footer={
          <>
            <SystemDeclare />
            <Space>
              <Button onClick={onCancel}>取消</Button>
              <Button type="primary" onClick={onConfirm} loading={loading}>
                确定
              </Button>
            </Space>
          </>
        }
      >
        <div className="main">
          <div className="form-item">
            <div className="title">
              <label className="red">*</label>智能体名称
            </div>
            <Input
              maxLength={20}
              placeholder="请输入智能体名称"
              value={form?.name}
              onChange={onFormChange.bind(this, 'name')}
            />
          </div>
          <div className="form-item">
            <div className="title">唤醒词</div>
            <Input
              maxLength={20}
              placeholder="请输入唤醒词，在对话时唤醒智能体"
              value={form?.wake_word}
              onChange={onFormChange.bind(this, 'wake_word')}
            />
          </div>
          <div className="form-item">
            <div className="title">提示词</div>
            <Input.TextArea
              maxLength={500}
              placeholder="为您创建的智能体写一段提示词。示例：你是一个通用个人助手，拥有广泛的知识和灵活的应变能力。"
              value={form?.prompt}
              onChange={onFormChange.bind(this, 'prompt')}
              rows={3}
            />
          </div>

          <div className="form-item">
            <div className="title">
              问答库
              <a className="link gradient" href={urlSource('hiflys/template/问答库模板.csv')}>
                模板下载
              </a>
            </div>
            {open && <UploadFile onUploadSuccess={onQaUploadSuccess} uploadText="大小：不超过10MB" />}
            <div className="file-list">
              {qaFiles?.map((file, index) => (
                <div key={index} className="file-list__item">
                  <span title={file.name} className="name ellipsis">
                    {file.name}
                  </span>
                  <DeleteOutlined onClick={deleteQaFile.bind(this, index)} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default memo(CreateKnowledgeDrawer)
