import React, { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { authApi } from '@/api'
import { UserStore } from '@/global-states'

// 模拟一个异步的检查登录状态的函数
const checkIfUserIsAuthenticated = async () => {
  if (UserStore.getToken()) {
    return authApi.get('api/v1/meta/users/info/get')
  }
  return
}

/**
 * 高阶组件：withAuth
 * 功能：检查用户是否已登录，如果已登录则重定向到首页，否则渲染被包裹的组件。
 * @param WrappedComponent 需要被包裹的组件
 * @returns 一个新的组件
 */
const withAuth = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const WithAuth: React.FC<P> = (props) => {
    const location = useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null) // null 表示未完成检查

    useEffect(() => {
      // 异步检查用户是否已登录
      const checkAuth = async () => {
        try {
          const res: any = await checkIfUserIsAuthenticated()
          if (res?.userinfo?.uid) {
            setIsAuthenticated(true)
          } else {
            setIsAuthenticated(false)
          }
        } catch {
          setIsAuthenticated(false)
        }
      }

      checkAuth()
    }, [])

    if (isAuthenticated === null) {
      return null
    }

    if (isAuthenticated) {
      // 如果用户已登录，重定向到首页
      return <Navigate to="/" state={{ from: location }} replace />
    }

    // 如果用户未登录，渲染传入的组件
    return <WrappedComponent {...props} />
  }

  // 设置 displayName
  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component'
  WithAuth.displayName = `WithAuth(${wrappedComponentName})`

  return WithAuth
}

export default withAuth
