import './style.scss'
import { Button, Dropdown, Input, Menu, message, Modal, Tour } from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { cdnApi } from '@/api'
import * as UserApi from '@/api/account'
import * as Api from '@/api/home'
import AiInteract from '@/assets/ai-interact.png'
import LogoTitle from '@/assets/logo-title.webp'
import Logo from '@/assets/logo.webp'
import { Home, Video } from '@/assets/svg'
import { ArrowDown } from '@/assets/svg/arrow-down'
import { Study } from '@/assets/svg/study'
import { User } from '@/assets/svg/user'
import CheckLogin from '@/components/CheckLogin'
import CouponListModal from '@/components/Coupon/list'
import PayPointModal from '@/components/PayPointModal'
import { UserStore } from '@/global-states'
import { eventTracking, showPlanModal } from '@/libs/util'
import ContractListModal from '@/views/setting/components/ContractListModal'
import { AppstoreFilled, AudioFilled, CopyrightCircleFilled, PayCircleFilled, RightOutlined } from '@ant-design/icons'

import type { MenuProps } from 'antd'
type MenuItem = Required<MenuProps>['items'][number]

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  extra?: React.ReactNode,
  type?: string
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    extra,
    type
  } as MenuItem
}

const LayoutMenu: React.FC = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { uid, userPackage, phone, studyCenterClick, systemGuideStep } = UserStore
  const [data, setData] = useState<any[]>([])
  const [open, setOpen] = useState(false)
  const [showInteract, setShowInteract] = useState(false)
  const [convertModalOpen, setConvertModalOpen] = useState(false)
  const [couponListOpen, setCouponListOpen] = useState(false)
  const [code, setCode] = useState('')
  const [contractListModalOpen, setContractListModalOpen] = useState(false)
  const [contractList, setContractList] = useState([])
  const [payPointModalOpen, setPayPointModalOpen] = useState(false)
  const guideRef = useRef<any>()

  const steps: any = [
    {
      title: '由此开始创建视频',
      target: () => guideRef.current,
      closeIcon: false,
      placement: 'top'
    }
  ]

  const selectedKeys = useMemo(() => {
    if (pathname === '/' || pathname === '/home') {
      return ['/'] // 返回首页的 key
    }
    return [pathname]
  }, [pathname])

  const vip = useMemo(() => {
    return !!userPackage?.membership_expire_time
  }, [userPackage])

  const vipExpired = useMemo(() => {
    return !!userPackage?.membership_expired
  }, [userPackage])

  useEffect(() => {
    getInteractConfig()
    getContractList()
  }, [])

  const showMarketNew = useMemo(() => {
    if (data?.length) {
      return true
    }
    return false
  }, [data])

  const getContractList = async () => {
    const res = await Api.getContractList()
    setContractList(res?.list || [])
  }

  const getInteractConfig = async () => {
    const res = await cdnApi.get(`hiflys/interact/config.json?v=${Date.now()}`)
    if (res.open === true) {
      setShowInteract(true)
    }
  }

  const showItems = useMemo(() => {
    return [
      getItem(
        '创作',
        '/',
        undefined,
        [
          getItem('数字人', '/', <Home className="icon" />, undefined),
          getItem('声音克隆', '/clone', <CopyrightCircleFilled className="clone" />, undefined),
          getItem('数字人市场', '/market/digital', <AppstoreFilled className="market" />, undefined, undefined),
          getItem('声音市场', '/market/voice', <AudioFilled className="icon-voice" />, undefined)
        ],
        undefined,
        'group'
      ),
      { type: 'divider' },
      getItem(
        '管理',
        '/',
        undefined,
        [
          getItem('作品管理', '/video', <Video className="icon" />, undefined),
          getItem('免费积分', '/invite', <PayCircleFilled className="icon" />, undefined),
          getItem(
            '学习中心',
            `/study/index.html`,
            <Study className="study" />,
            undefined,
            !studyCenterClick ? <div className="menu-tag">new</div> : null
          ),
          getItem('个人中心', '/setting', <User className="icon" />, undefined)
        ],
        undefined,
        'group'
      )
    ]
  }, [studyCenterClick, showMarketNew])

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const [merchandises] = await Promise.all([Api.getMerchandisesV2()])
    setData([...(merchandises?.list || []).filter((l: any) => l.status !== 2)])
  }

  const jumpToStudyCenter = () => {
    localStorage.setItem('studyCenter', 'true')
    UserStore.studyCenterClick = true
    window.open(`/study/index.html?v=${Date.now()}`)
  }

  const onClick: MenuProps['onClick'] = (e) => {
    if (e.key === '/study/index.html') {
      jumpToStudyCenter()
    } else {
      navigate(e.key)
    }
  }

  const toCreateVideo = () => {
    if (systemGuideStep >= 1) {
      eventTracking('SystemGuideNextStep1')
      localStorage.setItem('systemGuideRead', '1')
      UserStore.systemGuideStep = 2
    } else {
      eventTracking('CreateDisplayClick')
    }
    navigate('/create-video')
  }

  const toInteract = () => {
    navigate('/digital')
  }

  const toLogin = () => {
    UserStore.loginOut()
    navigate('/login')
  }

  const toHome = () => {
    navigate('/')
  }

  const onConvertCouponClick = () => {
    eventTracking('CreditsRedemption')
    setConvertModalOpen(true)
    setCode('')
  }

  const useCode = async () => {
    await UserApi.useCode({ redeem_code: code })
    setConvertModalOpen(false)
    message.success('兑换成功')
    UserStore.updateUserPackage()
  }

  const toAddress = (link: string) => {
    window.open(link)
  }

  const toPayPoint = () => {
    eventTracking('RechargeCredits')
    setPayPointModalOpen(true)
  }

  return (
    <div className="layout-menu">
      <div className="layout-menu-header" onClick={toHome}>
        <img className="logo" src={Logo} />
        <img className="logo-title" src={LogoTitle} />
      </div>

      <div className="layout-menu-center">
        <Menu
          theme="light"
          onClick={onClick}
          style={{ width: 220 }}
          selectedKeys={selectedKeys}
          mode="inline"
          items={showItems as any}
        />

        <Button ref={guideRef} className="create-video" type="primary" onClick={toCreateVideo}>
          创建视频
        </Button>

        {showInteract && (
          <CheckLogin>
            <img className="interact" src={AiInteract} onClick={toInteract} />
          </CheckLogin>
        )}
      </div>

      <div className="layout-menu-bottom">
        {!uid ? (
          <div className="unlogin">
            <div className="left">
              <div className="free">游客模式</div>
              <div className="desc">未登录</div>
            </div>
            <div className="right">
              <CheckLogin>
                <Button className="btn-login" type="primary" onClick={toLogin}>
                  登录
                </Button>
              </CheckLogin>
            </div>
          </div>
        ) : (
          <Dropdown
            placement="top"
            overlayClassName="customer-dropdown"
            trigger={['click']}
            open={open}
            dropdownRender={() => {
              return (
                <div className="dropdown-user" onClick={() => setOpen(false)}>
                  <div className="header">Hi</div>
                  <div className="phone">{phone?.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')}</div>
                  {vip ? (
                    <>
                      {vipExpired ? (
                        <div className="level red">会员已过期</div>
                      ) : (
                        <div className="level gradient">{userPackage?.current_membership_package_title}会员</div>
                      )}
                    </>
                  ) : (
                    <div className="level gray">未开通会员</div>
                  )}

                  <div className="menu-list">
                    <div className="item" onClick={() => setCouponListOpen(true)}>
                      <label>我的代金券</label>
                      <RightOutlined />
                    </div>
                    <div className="item" onClick={onConvertCouponClick}>
                      <label>积分兑换</label>
                      <RightOutlined />
                    </div>
                    {!!contractList?.length && (
                      <div className="item" onClick={() => setContractListModalOpen(true)}>
                        <label>我的合同</label>
                        <RightOutlined />
                      </div>
                    )}

                    <div className="item" onClick={toAddress.bind(this, '/privacy_agreement.html')}>
                      <label>隐私政策</label>
                      <RightOutlined />
                    </div>
                    <div className="item" onClick={toAddress.bind(this, '/eula.html')}>
                      <label>用户协议</label>
                      <RightOutlined />
                    </div>
                  </div>
                  <Button onClick={showPlanModal} type="primary">
                    {vip ? (vipExpired ? '立即续费' : '续费 / 升级') : '开通会员'}
                  </Button>
                  <Button onClick={toPayPoint} color="default" variant="outlined">
                    充值积分
                  </Button>
                  <div className="switch" onClick={toLogin}>
                    退出登录
                  </div>
                </div>
              )
            }}
            onOpenChange={(open) => {
              setOpen(open)
            }}
          >
            <div className="logined">
              <div className="left">
                <div className="header">Hi</div>
                <div className="detail">
                  <div className="phone">{phone?.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')}</div>
                  {vip ? (
                    <>
                      {vipExpired ? (
                        <div className="title gray">会员已过期</div>
                      ) : (
                        <div className="title gradient">{userPackage?.current_membership_package_title}会员</div>
                      )}
                    </>
                  ) : (
                    <div className="title gray">未开通会员</div>
                  )}
                </div>
              </div>

              <div className="right">
                <ArrowDown />
              </div>
            </div>
          </Dropdown>
        )}
      </div>

      <CouponListModal
        open={couponListOpen}
        onCancel={() => setCouponListOpen(false)}
        toUseCoupon={showPlanModal}
        changeOpen={(t) => setCouponListOpen(t)}
      />

      <Modal
        okText="立即兑换"
        cancelText="取消"
        title="请输入兑换码"
        onCancel={() => setConvertModalOpen(false)}
        open={convertModalOpen}
        okButtonProps={{ disabled: !code }}
        onOk={useCode}
      >
        <div className="convert-content">
          <Input value={code} onChange={(e) => setCode(e.target.value)} />
        </div>
      </Modal>

      <PayPointModal open={payPointModalOpen} onCancel={() => setPayPointModalOpen(false)} />

      <ContractListModal open={contractListModalOpen} onCancel={() => setContractListModalOpen(false)} />

      <Tour
        open={systemGuideStep === 1}
        closeIcon={null}
        steps={steps}
        mask={{
          color: 'rgba(0, 0, 0, 0.7)'
        }}
      />
    </div>
  )
}

export default LayoutMenu
