import './style.scss'
import { Button, Dropdown, Input, message, Modal, Pagination, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { FC, memo, useEffect, useRef, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useNavigate } from 'react-router-dom'
import * as Api from '@/api/home'
import AudioBg from '@/assets/audio-bg.webp'
import Empty from '@/assets/empty.png'
import IconMusic from '@/assets/icon-music.png'
import { Ellipsis, Play } from '@/assets/svg'
import { Like } from '@/assets/svg/like'
import VideoModal from '@/components/VideoModal'
import { UserStore } from '@/global-states'
import { downlad, eventTracking, Render, urlSource } from '@/libs/util'
import {
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  LoadingOutlined
} from '@ant-design/icons'

const Index: FC = () => {
  const { creationList } = UserStore
  const navigate = useNavigate()
  const [editId, setEditId] = useState<any>()
  const editRef = useRef<any>()
  const [preview, setPreview] = useState<any>()
  const [openIndex, setOpenIndex] = useState<any>()
  const [data, setData] = useState<any[]>(undefined as any)
  const [mode, setMode] = useState<any>()
  const page = useRef<any>()
  const size = useRef<any>()
  const total = useRef<any>()
  const modeList = [
    {
      title: '全部'
    },
    {
      id: 0,
      title: '积分模式'
    },
    {
      id: 1,
      title: '暗黑模式'
    }
  ]

  useEffect(() => {
    size.current = window.innerWidth < 1800 ? 20 : 30
    eventTracking('MyCreationsPage')
  }, [])

  useEffect(() => {
    editRef.current = editId
  }, [editId])

  useEffect(() => {
    page.current = 0
    getData()
  }, [mode])

  useEffect(() => {
    if (data?.length) {
      setData(
        data?.map((d) => {
          const f = creationList.find((c: any) => c.id === d.id)
          if (f) {
            return f
          }
          return d
        })
      )
    }
  }, [creationList])

  const getData = async () => {
    if (editRef.current) return
    const res = await Api.getCreations({
      page: page.current || 0,
      size: size.current,
      mode
    })
    total.current = res?.total || 0
    setData(res?.list || [])
  }

  const previewVideo = (d: any) => {
    eventTracking('CreationsPlay', {
      url: d.url,
      id: d.id
    })
    setPreview({
      url: d.url,
      title: d.title,
      type: d.only_generate_audio ? 'audio' : 'video'
    })
  }

  const onValueChange = (e: any) => {
    setData(
      data.map((d) => {
        return d.id === editId
          ? {
              ...d,
              title: e.target.value
            }
          : d
      })
    )
  }

  const saveChange = (d: any) => {
    setEditId('')
    if (d.title.trim()) {
      Api.updateCreations(d.id, {
        title: d.title
      })
    } else {
      getData()
    }
  }

  const renameVideo = (id: any) => {
    setEditId(id)
    setOpenIndex(undefined)
  }

  const deleteVideo = async (id: any) => {
    Modal.confirm({
      title: '删除作品',
      content: '作品删除后不可恢复，确认删除作品？',
      onOk: async () => {
        setOpenIndex(undefined)
        await Api.deleteCreations(id)
        getData()
        message.success('删除成功')
      }
    })
  }

  const viewText = async (d: any) => {
    let showText
    if (d.segments?.length) {
      showText = d.segments.reduce((pre: any, next: any) => {
        return pre + (next.source_text || '')
      }, '')
    } else {
      showText = d.source_text
    }
    showText = showText.replace(/<break[^>]*><\/break>/g, '').replace(/&nbsp;|&gt;/g, '')
    Modal.confirm({
      width: 800,
      className: 'modal-source-text',
      title: null,
      content: (
        <div className="text-wrapper">
          <div className="header">
            <div className="left">查看台词</div>
            <div className="right">
              <CopyToClipboard
                text={showText}
                onCopy={() => {
                  message.success('已复制台词')
                }}
              >
                <CopyOutlined />
              </CopyToClipboard>

              <CloseOutlined onClick={() => Modal.destroyAll()} />
            </div>
          </div>
          <div className="content">{showText}</div>
        </div>
      ),
      icon: null,
      footer: null,
      maskClosable: true
    })
  }

  const onCreationVote = async (d: any, vote_status: number) => {
    await Api.creationVote(d.id, {
      vote_status
    })
    getData()
  }

  const onPaginationChange = (p: number, s: number) => {
    page.current = p - 1
    size.current = s
    getData()
  }

  const reCreate = async (d: any) => {
    eventTracking('CopyCreateVideo')
    const res = await Api.getCreationDetail(d.id)
    if (res?.origin_request) {
      navigate(`/create-video?id=${d.id}`)
    }
  }

  return (
    <div className="page-common page-video">
      <div className="common-header">作品管理</div>

      <div className="common-wrapper">
        {data && (
          <div className="page-video-main">
            <div className="page-video-wrapper">
              <div className="nav">
                {modeList.map((s) => (
                  <div
                    className={`nav-item ${s.id === mode ? 'actived' : ''}`}
                    key={s.id}
                    onClick={() => setMode(s.id)}
                  >
                    <label className={s.id === mode ? 'gradient' : ''}>{s.title}</label>
                  </div>
                ))}
              </div>

              {!!data.length && (
                <div className="page-container">
                  {data?.map((d, i) => (
                    <div className={`list-box ${openIndex === i ? 'hovered' : ''}`} key={i}>
                      {
                        <Render>
                          {function List() {
                            const [imgClass, setImgClass] = useState('')
                            const [percent, setPercent] = useState(-1)
                            const xhrRef = useRef<any>()
                            const downladVideo = (d: any, e: any) => {
                              eventTracking('CreationsDownload', {
                                url: d.url,
                                id: d.id
                              })
                              e.stopPropagation()
                              if (xhrRef.current) {
                                xhrRef.current.abort()
                                setPercent(0)
                              }
                              const url = d.url.split('?')[0]
                              const extension = url.substring(url.lastIndexOf('.')).toLowerCase()
                              const xhr: XMLHttpRequest = downlad(d.url, `${d.title}${extension}`)
                              xhr.onprogress = (e) => {
                                const p = Math.floor((e.loaded / e.total) * 100) // 下载进度
                                console.log('进度', p)
                                setPercent(p)
                                if (p === 100) {
                                  setTimeout(() => {
                                    setPercent(-1)
                                  }, 300)
                                }
                              }
                              xhrRef.current = xhr
                            }

                            return (
                              <div className="box-main" onClick={() => d.status === 2 && previewVideo(d)}>
                                <div className="bg"></div>
                                {d.status === 2 && percent === -1 && (
                                  <div className="play">
                                    <Play />
                                  </div>
                                )}

                                <div className="op" onClick={(e) => e.stopPropagation()}>
                                  {d.status === 2 && (
                                    <div className="btn" onClick={(e) => downladVideo(d, e)}>
                                      <DownloadOutlined />
                                    </div>
                                  )}

                                  {d.status !== 1 ? (
                                    <Dropdown
                                      menu={{
                                        items: [
                                          {
                                            key: '0',
                                            label: (
                                              <div className="dropdown-list" onClick={() => viewText(d)}>
                                                <EyeOutlined />
                                                查看台词
                                              </div>
                                            )
                                          },
                                          {
                                            key: '1',
                                            label: (
                                              <div className="dropdown-list" onClick={() => reCreate(d)}>
                                                <CopyOutlined />
                                                再次创作
                                              </div>
                                            )
                                          },
                                          {
                                            key: '2',
                                            label: (
                                              <div className="dropdown-list" onClick={() => renameVideo(d.id)}>
                                                <EditOutlined />
                                                重命名
                                              </div>
                                            )
                                          },
                                          {
                                            key: '3',
                                            label: (
                                              <div className="dropdown-list" onClick={() => deleteVideo(d.id)}>
                                                <DeleteOutlined />
                                                删除
                                              </div>
                                            )
                                          }
                                        ].filter((l) => {
                                          if (l.key === '0') {
                                            if (d.source_text || d.segments?.some((s: any) => s.type === 2)) {
                                              return true
                                            } else {
                                              return false
                                            }
                                          }
                                          if (l.key === '1' && !d.replicable) {
                                            return false
                                          }
                                          return true
                                        })
                                      }}
                                      placement="bottom"
                                      open={openIndex === i}
                                      onOpenChange={(open: boolean) => setOpenIndex(open ? i : undefined)}
                                    >
                                      <div className="btn" onClick={(e) => e.stopPropagation()}>
                                        <Ellipsis />
                                      </div>
                                    </Dropdown>
                                  ) : (
                                    <Dropdown
                                      menu={{
                                        items: [
                                          {
                                            key: '0',
                                            label: (
                                              <div className="dropdown-list" onClick={() => reCreate(d)}>
                                                <CopyOutlined />
                                                再次创作
                                              </div>
                                            )
                                          }
                                        ]
                                      }}
                                      placement="bottom"
                                      open={openIndex === i}
                                      onOpenChange={(open: boolean) => setOpenIndex(open ? i : undefined)}
                                    >
                                      <div className="btn" onClick={(e) => e.stopPropagation()}>
                                        <Ellipsis />
                                      </div>
                                    </Dropdown>
                                  )}
                                </div>
                                <div className="photo">
                                  {d.only_generate_audio ? (
                                    <img className="audio-bg" src={AudioBg} />
                                  ) : (
                                    <>
                                      <img
                                        className={`${imgClass}`}
                                        src={urlSource(d.digital_human_url, 'video')}
                                        onLoad={(e: any) => {
                                          const w = e.target?.naturalWidth
                                          const h = e.target?.naturalHeight
                                          if (w / h > 1.6) {
                                            setImgClass('portrait')
                                          }
                                        }}
                                      />
                                      <img className="shadow" src={urlSource(d.digital_human_url, 'video')} />
                                    </>
                                  )}
                                </div>

                                {percent > -1 && (
                                  <div className="mask">
                                    <div className="pending">
                                      <LoadingOutlined />
                                      <div>下载中 {percent}%</div>
                                    </div>
                                  </div>
                                )}

                                {d.status === 2 && d.tip && (
                                  <Tooltip placement="bottom" title={d.tip}>
                                    <span className="tips">{<InfoCircleOutlined />}</span>
                                  </Tooltip>
                                )}

                                {d.status !== 2 && (
                                  <div className="mask">
                                    {d.status === 1 && (
                                      <div className="pending">
                                        <LoadingOutlined />
                                        <div>生成中, 请稍等</div>
                                      </div>
                                    )}
                                    {d.status === 3 && (
                                      <Tooltip placement="bottom" title={d.error_message || d.tip || ''}>
                                        <span className="status error">
                                          生成失败
                                          {d.error_message && <InfoCircleOutlined />}
                                        </span>
                                      </Tooltip>
                                    )}
                                  </div>
                                )}

                                {d.only_generate_audio && (
                                  <div className="tag-music">
                                    <img src={IconMusic} />
                                  </div>
                                )}
                                {d.mode === 1 && <div className="tag">暗黑模式</div>}
                              </div>
                            )
                          }}
                        </Render>
                      }

                      <div className="bottom">
                        {editId === d.id ? (
                          <Input
                            value={d.title}
                            autoFocus
                            onChange={onValueChange}
                            onBlur={saveChange.bind(this, d)}
                            onPressEnter={saveChange.bind(this, d)}
                          />
                        ) : (
                          <div className="box-title">{d.title}</div>
                        )}
                        <div className="box-time">
                          {dayjs(d.create_time * 1000).format('YYYY-MM-DD HH:mm')}
                          <Like
                            className={d.vote_status === 1 ? 'actived' : ''}
                            onClick={onCreationVote.bind(this, d, d.vote_status === 1 ? 0 : 1)}
                          />
                          <Like
                            className={`unlike ${d.vote_status === 2 ? 'actived' : ''}`}
                            onClick={onCreationVote.bind(this, d, d.vote_status === 2 ? 0 : 2)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {!data.length && (
                <div className="data-empty">
                  <img className="empty" src={Empty} />
                  <p>你没有任何作品哦</p>
                  <Button type="primary" onClick={() => navigate(`/create-video`)}>
                    去创作
                  </Button>
                </div>
              )}
            </div>
            {total.current > 0 && !!data.length && (
              <Pagination
                showSizeChanger
                current={(page.current || 0) + 1}
                total={total.current}
                pageSize={size.current || 20}
                showTotal={(total) => `共 ${total} 条`}
                onChange={onPaginationChange}
                align="end"
              />
            )}
          </div>
        )}
      </div>

      <VideoModal preview={preview} onCancel={() => setPreview(undefined)} />
    </div>
  )
}

export default memo(Index)
