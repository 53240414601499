import './style.scss'
import { Button, Dropdown, Input, message, Modal, Popover, Tooltip } from 'antd'
import { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as AccountApi from '@/api/account'
import * as Api from '@/api/home'
import ArrowLeft from '@/assets/arrow-left.png'
import AvatarAi from '@/assets/avatar-ai.png'
import AvatarPhoto from '@/assets/avatar-photo.png'
import AvatarVideo from '@/assets/avatar-video.png'
import Empty from '@/assets/empty.png'
import IconAdd from '@/assets/icon-add.png'
import { CreateVideo, Ellipsis, Play } from '@/assets/svg'
import CheckLogin from '@/components/CheckLogin'
import VideoModal from '@/components/VideoModal'
import { IUserPackage } from '@/global-states'
import { eventTracking, Render, showPlanModal, urlSource } from '@/libs/util'
import {
  DeleteOutlined,
  DeliveredProcedureOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  StarFilled
} from '@ant-design/icons'
import CreateAvatarDrawer from './components/CreatAvatarDrawer'
import CreateAiAvatarDrawer from './components/CreateAiAvatarDrawer'
import CreatePhotoAvatarDrawer from './components/CreatePhotoAvatarDrawer'
import Groups from './components/Groups'

const Index: FC = () => {
  const navigate = useNavigate()
  const [editId, setEditId] = useState<any>()
  const [preview, setPreview] = useState<any>()
  const [openId, setOpenId] = useState<any>()
  const [data, setData] = useState<any[]>([])
  const [globalData, setGlobalData] = useState<any[]>([])
  const [createDrawerOpen, setCreateDrawerOpen] = useState(false)
  const [createPhotoDrawerOpen, setCreatePhotoDrawerOpen] = useState(false)
  const [createAIDrawerOpen, setCreateAIModalOpen] = useState(false)
  const [modelModal, setModelModal] = useState(false)
  const [group, setGroup] = useState<any>()
  const [avatarId, setAvatarId] = useState<number>()
  const [groupHumans, setGroupHumans] = useState<any[]>([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [bookHumans, setBookHumans] = useState<any[]>([])
  const timeRef = useRef<any>()

  const renderData = useMemo(() => {
    if (activeIndex === 1) {
      return bookHumans
    }
    return data
  }, [activeIndex, data, bookHumans])

  useEffect(() => {
    eventTracking('InstantAvatarPage')
    getData()
    getGlobalData()
    getBookedHumans()
    return () => clearEvent()
  }, [])

  useEffect(() => {
    if (group?.id) {
      getGroupHumans()
      loopGroupHumans()
    }
  }, [group])

  const getData = async () => {
    const res = await Api.getDigitalHumanGroups()
    setData(res?.list || [])
  }

  const getGlobalData = async () => {
    const res = await Api.getGlobalDigitalHumanGroups()
    setGlobalData(res?.list || [])
  }

  const getGroupHumans = async () => {
    const res = await Api.getDigitalHumansByGroup(group.id as number)
    setGroupHumans(res.list || [])
    if (!(res?.list || []).filter((d: any) => d.status === 1)?.length) {
      clearEvent()
    }
  }

  const getBookedHumans = async () => {
    try {
      const res = await Api.getBookmarkedCommunityHumans()
      setBookHumans(
        (res?.list || []).map((l: any) => {
          return {
            cover_url: l.merchandise.id > 349 ? l.merchandise.covers[0] : l.merchandise.images[0],
            id: l.group_id,
            member_count: l.merchandise.videos.length || 0,
            title: l.merchandise.name,
            booked: true
          }
        })
      )
    } catch {
      setBookHumans([])
    }
  }

  const loopGroupHumans = () => {
    clearEvent()
    timeRef.current = setInterval(() => {
      getGroupHumans()
    }, 5000)
  }

  const clearEvent = () => {
    if (timeRef.current) {
      clearInterval(timeRef.current)
      timeRef.current = undefined
    }
  }

  const previeGroup = (g: any) => {
    setGroup(g)
  }

  const previewVideo = (d: any) => {
    setOpenId(undefined)
    setPreview({
      url: d.video_url,
      title: d.title,
      tip: d.tip,
      id: d.id,
      source_type: d.source_type
    })
  }

  const onValueChange = (e: any) => {
    setGroupHumans(
      groupHumans.map((d) => {
        return d.id === editId
          ? {
              ...d,
              title: e.target.value
            }
          : d
      })
    )
  }

  const onGroupValueChange = (e: any) => {
    setData(
      data.map((d) => {
        return d.id === editId
          ? {
              ...d,
              title: e.target.value
            }
          : d
      })
    )
  }

  const saveChange = async (d: any) => {
    setEditId('')
    if (d.title.trim()) {
      Api.updateDigitalHumans(d.id, {
        title: d.title
      })
    } else {
      getData()
    }
  }

  const renameVideo = (id: any, e: any) => {
    e.stopPropagation()
    setOpenId(undefined)
    setEditId(id)
  }

  const deleteVideo = async (id: any, e: any) => {
    e.stopPropagation()
    Modal.confirm({
      title: '删除形象',
      content: '形象删除后不可恢复，确认删除形象？',
      onOk: async () => {
        setOpenId(undefined)
        await Api.deleteDigitalHumans(id)
        getGroupHumans()
        message.success('删除成功')
      }
    })
  }

  const renameGroup = (id: any, e: any) => {
    e.stopPropagation()
    setOpenId(undefined)
    setEditId(id)
  }

  const deleteGroup = (id: any, e: any) => {
    e.stopPropagation()
    Modal.confirm({
      title: '删除数字人',
      content: <div className="red">数字人删除时，会同时删除其关联形象，且不可恢复。确认删除？</div>,
      onOk: async () => {
        setOpenId(undefined)
        await Api.deleteDigitalGroup(id)
        getData()
        message.success('删除成功')
      }
    })
  }

  const saveGroupChange = async (d: any) => {
    setEditId('')
    if (d.title.trim()) {
      Api.updateDigitalGroup(d.id, {
        title: d.title
      })
    } else {
      getData()
    }
  }

  const moveGroup = (id: number, e: any) => {
    e.stopPropagation()
    setAvatarId(id)
  }

  const toChooseModel = () => {
    eventTracking('InstantAvatarCloneClick')
    setGroup({
      ...group,
      member_count: groupHumans.length
    })
    setModelModal(true)
  }

  const toCreateAvatar = () => {
    eventTracking('InstantAvatarVideoCloneClick')
    setModelModal(false)
    setCreateDrawerOpen(true)
  }

  const toCreatePhotoAvatar = () => {
    eventTracking('InstantAvatarPhotoCloneClick')
    setModelModal(false)
    setCreatePhotoDrawerOpen(true)
  }

  const toCreateAIAvatar = async () => {
    eventTracking('InstantAvatarAICloneClick')
    const res: IUserPackage = await AccountApi.getUserPackage()
    setModelModal(false)
    if (res && (res.current_membership_level || 0) < 20) {
      return upgradePlan()
    }
    setCreateAIModalOpen(true)
  }

  const upgradePlan = () => {
    eventTracking('AICloneAvatarLimit')
    Modal.confirm({
      title: '当前会员等级不够，无法使用',
      content: <div>AI生成数字演员是尊享版及以上会员专属功能，请您确认当前会员等级是否匹配</div>,
      okText: '升级会员',
      cancelText: '取消',
      onOk: () => {
        showPlanModal()
      }
    })
  }

  const toCreateVideo = (d: any) => {
    eventTracking('CreateAvatarClick', {
      avatarId: d.id
    })
    if (d.tip) {
      return Modal.confirm({
        title: '温馨提示',
        content: `当前数字人可能存在“${d.tip}”的情况，使用其创作有可能导致生成效果不好。建议先检查原视频，使用符合要求的视频来复刻`,
        okText: '继续创作',
        onOk: () => {
          navigate(`/create-video/${d.id}`)
        }
      })
    }

    if (group.booked) {
      sessionStorage.setItem('bookAvatarId', group.id)
    }
    navigate(`/create-video/${d.id}`)
  }

  const groupToCreateVideo = async (d: any) => {
    const res = await Api.getDigitalHumansByGroup(d.id as number)
    if (res?.list?.length) {
      const l = res.list.find((l: any) => l.status === 2)
      if (!l) {
        message.warning('当前数字人暂无可使用形象！')
      } else {
        eventTracking('CreateAvatarGroupClick', {
          avatarId: l.id
        })
        if (d.booked) {
          sessionStorage.setItem('bookAvatarId', d.id)
        }
        navigate(`/create-video/${l.id}`)
      }
    }
  }

  const onCreateSuccess = () => {
    if (group?.id) {
      getGroupHumans()
      loopGroupHumans()
    } else {
      getData()
    }
  }

  const cancelCollect = async (groupId: number, e: any) => {
    e.stopPropagation()
    await Api.cancelBookmarkedCommunityHumans(groupId)
    message.success('已取消收藏')
    getBookedHumans()
  }

  return (
    <div className="page-common page-home">
      <div className="common-header">
        {group?.id ? (
          <>
            <img
              src={ArrowLeft}
              onClick={() => {
                setGroup(undefined)
                setGroupHumans([])
                getData()
              }}
              className="arrow-left"
            />

            <div className="text">{group.title}</div>
          </>
        ) : (
          <div className="text">数字人</div>
        )}
      </div>

      {!group?.id ? (
        <div className="common-wrapper">
          <div className="home-tabs">
            <div className={`tabs-item ${activeIndex === 0 ? 'actived' : ''}`} onClick={() => setActiveIndex(0)}>
              <span>我的数字人</span>
            </div>
            <div className={`tabs-item ${activeIndex === 1 ? 'actived' : ''}`} onClick={() => setActiveIndex(1)}>
              <span>我的收藏</span>
            </div>
          </div>

          <div className="page-container">
            {activeIndex === 0 && (
              <div className="list-box">
                <CheckLogin>
                  <div className="box-main empty-group" onClick={toChooseModel}>
                    <div className="center">
                      <img className="icon-add" src={IconAdd} />
                      <div className="text gradient">快速创建数字人</div>
                    </div>
                  </div>
                </CheckLogin>
                <div className="box-title"></div>
              </div>
            )}
            {renderData.map((d) => (
              <div className="list-box" key={d.id}>
                <div
                  className="box-main"
                  onClick={() =>
                    previeGroup({
                      ...d,
                      global: activeIndex === 1
                    })
                  }
                >
                  <div className="op">
                    <Dropdown
                      menu={{
                        items:
                          activeIndex === 0
                            ? [
                                {
                                  key: '1',
                                  label: (
                                    <div className="dropdown-list" onClick={renameGroup.bind(this, d.id)}>
                                      <EditOutlined />
                                      重命名
                                    </div>
                                  )
                                },
                                {
                                  key: '2',
                                  label: (
                                    <div className="dropdown-list" onClick={deleteGroup.bind(this, d.id)}>
                                      <DeleteOutlined />
                                      删除
                                    </div>
                                  )
                                }
                              ]
                            : [
                                {
                                  key: '1',
                                  label: (
                                    <div className="dropdown-list" onClick={cancelCollect.bind(this, d.id)}>
                                      <StarFilled />
                                      取消收藏
                                    </div>
                                  )
                                }
                              ]
                      }}
                      placement="bottom"
                      open={openId === d.id}
                      onOpenChange={(open: boolean) => setOpenId(open ? d.id : undefined)}
                    >
                      <Button type="primary" className="btn" onClick={(e) => e.stopPropagation()}>
                        <Ellipsis />
                      </Button>
                    </Dropdown>
                  </div>

                  <div className="photo">
                    <Render>
                      {function LoadImg() {
                        const [imgClass, setImgClass] = useState('')
                        return (
                          <img
                            className={`${imgClass}`}
                            src={d.cover_url}
                            onLoad={(e: any) => {
                              const w = e.target?.naturalWidth
                              const h = e.target?.naturalHeight
                              if (w / h > 1.6) {
                                setImgClass('portrait')
                              }
                            }}
                          />
                        )
                      }}
                    </Render>

                    <img className="shadow" src={d.cover_url} />
                    {!editId && <div className="num">{d.member_count || 0}个形象</div>}
                  </div>
                </div>
                <div className="bottom">
                  {editId === d.id ? (
                    <Input
                      value={d.title}
                      autoFocus
                      onChange={onGroupValueChange}
                      onBlur={saveGroupChange.bind(this, d)}
                      onPressEnter={saveGroupChange.bind(this, d)}
                    />
                  ) : (
                    <div className="box-title">{d.title}</div>
                  )}

                  {d.member_count > 0 && (
                    <Button className="btn" type="primary" onClick={groupToCreateVideo.bind(this, d)}>
                      去创作
                      <CreateVideo />
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </div>
          {activeIndex === 1 && !renderData.length && (
            <div className="data-empty">
              <img className="empty" src={Empty} />
              <p>
                暂无任何收藏，去
                <span className="data-empty-link gradient" onClick={() => navigate('/market/digital')}>
                  数字人市场
                </span>
                逛逛
              </p>
            </div>
          )}
          <div className="module-title">
            <div className="text">公用数字人</div>
          </div>
          <div className="page-container page-container-digital">
            {globalData.map((d) => (
              <div className="list-box" key={d.id}>
                <div
                  className="box-main"
                  onClick={() =>
                    previeGroup({
                      ...d,
                      global: true
                    })
                  }
                >
                  <div className="bg"></div>

                  <div className="photo">
                    <Render>
                      {function LoadImg() {
                        const [imgClass, setImgClass] = useState('')
                        return (
                          <img
                            className={`img ${imgClass}`}
                            src={d.cover_url}
                            onLoad={(e: any) => {
                              const w = e.target?.naturalWidth
                              const h = e.target?.naturalHeight
                              if (w / h > 1.6) {
                                setImgClass('portrait')
                              }
                            }}
                          />
                        )
                      }}
                    </Render>
                    <img className="shadow" src={d.cover_url} />
                    <div className="num">{d.member_count || 0}个形象</div>
                  </div>
                </div>
                <div className="bottom">
                  {editId === d.id ? (
                    <Input
                      value={d.title}
                      autoFocus
                      onChange={onGroupValueChange}
                      onBlur={saveGroupChange.bind(this, d)}
                      onPressEnter={saveGroupChange.bind(this, d)}
                    />
                  ) : (
                    <div className="box-title">{d.title}</div>
                  )}

                  <Button className="btn" type="primary" onClick={groupToCreateVideo.bind(this, d)}>
                    去创作
                    <CreateVideo />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="common-wrapper">
          <div className="page-container">
            {!group.global && (
              <div className="list-box">
                <div className="box-main empty" onClick={toChooseModel}>
                  <div className="center">
                    <div className="text gradient">
                      <PlusOutlined />
                      创建更多形象
                    </div>
                    <div className="desc">上传更多素材来创建数字人的更多形象</div>
                  </div>
                </div>
              </div>
            )}

            {groupHumans.map((d) => (
              <div className={`list-box ${openId === d.id ? 'hovered' : ''} `} key={d.id}>
                <div className="box-main" onClick={() => d.status === 2 && previewVideo(d)}>
                  {d.status === 2 && (
                    <div className="play">
                      <Play />
                    </div>
                  )}

                  {d.status !== 1 && !group?.global && (
                    <div className="op" onClick={(e) => e.stopPropagation()}>
                      <Dropdown
                        menu={{
                          items: [
                            {
                              key: '1',
                              label: (
                                <div className="dropdown-list" onClick={renameVideo.bind(this, d.id)}>
                                  <EditOutlined />
                                  重命名
                                </div>
                              )
                            },
                            {
                              key: '2',
                              label: (
                                <div className="dropdown-list" onClick={moveGroup.bind(this, d.id)}>
                                  <DeliveredProcedureOutlined />
                                  移动到
                                </div>
                              )
                            },

                            {
                              key: '3',
                              label: (
                                <div className="dropdown-list" onClick={deleteVideo.bind(this, d.id)}>
                                  <DeleteOutlined />
                                  删除
                                </div>
                              )
                            }
                          ]
                        }}
                        placement="bottom"
                        open={openId === d.id}
                        onOpenChange={(open: boolean) => setOpenId(open ? d.id : undefined)}
                      >
                        <div className="btn" onClick={(e) => e.stopPropagation()}>
                          <Ellipsis />
                        </div>
                      </Dropdown>
                    </div>
                  )}

                  {d.status === 1 && (
                    <div className="mask">
                      <div className="pending">
                        <LoadingOutlined />
                        <div>审核中, 请稍等</div>
                      </div>
                    </div>
                  )}

                  {(d.status === 3 || d.tip) && (
                    <div className={`status ${d.status === 3 ? 'error' : ''}`}>
                      {d.status === 3 && <span>失败</span>}

                      {d.tip && (
                        <Tooltip placement="bottom" title={d.tip}>
                          <span className="tips">{<InfoCircleOutlined />}</span>
                        </Tooltip>
                      )}
                    </div>
                  )}

                  <div className="photo">
                    <Render>
                      {function LoadImg() {
                        const [imgClass, setImgClass] = useState('')
                        return (
                          <img
                            className={`${imgClass}`}
                            src={
                              d.source_type === 3 || (d.source_type === 2 && d.status !== 2)
                                ? d.video_url
                                : urlSource(d.video_url, 'video')
                            }
                            onLoad={(e: any) => {
                              const w = e.target?.naturalWidth
                              const h = e.target?.naturalHeight
                              if (w / h > 1.6) {
                                setImgClass('portrait')
                              }
                            }}
                          />
                        )
                      }}
                    </Render>

                    <img
                      className="shadow"
                      src={
                        d.source_type === 3 || (d.source_type === 2 && d.status !== 2)
                          ? d.video_url
                          : urlSource(d.video_url, 'video')
                      }
                    />
                  </div>

                  {d.special_cost && (
                    <Popover
                      content={
                        <div className="common-popover w400" onClick={(e) => e.stopPropagation()}>
                          <div>
                            <strong>创作优惠：</strong>
                          </div>
                          <ul>
                            <li>
                              标有“创作优惠”的数字演员。雇佣后，在创作视频时享受积分消耗优惠（
                              {d.creation_cost_per_second}
                              积分/秒），即：
                              <div className="ml10">
                                a. 使用音频驱动生成视频时：{d.creation_cost_per_second} 积分/秒；
                              </div>
                              <div className="ml10">
                                b. 使用文本驱动生成视频时：{d.creation_cost_per_second + 1} 积分/秒；
                              </div>
                            </li>
                            <li>仅从数字市场雇佣才有“创作优惠”标记，其他方式上传的视频均无法享有；</li>
                          </ul>
                        </div>
                      }
                      placement="bottom"
                    >
                      <div className="discount" onClick={(e) => e.stopPropagation()}>
                        <span className="gradient">创作优惠</span>
                        <ExclamationCircleOutlined />
                      </div>
                    </Popover>
                  )}
                </div>

                <div className="bottom">
                  {editId === d.id ? (
                    <Input
                      value={d.title}
                      autoFocus
                      onChange={onValueChange}
                      onBlur={saveChange.bind(this, d)}
                      onPressEnter={saveChange.bind(this, d)}
                    />
                  ) : (
                    <div className="box-title">{d.title}</div>
                  )}
                  {d.status === 2 && (
                    <Button type="primary" className="btn" onClick={toCreateVideo.bind(this, d)}>
                      去创作
                      <CreateVideo />
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <Modal open={modelModal} title="模式选择" footer={null} onCancel={() => setModelModal(false)}>
        <div className="avatar-model">
          <div className="model-item" onClick={toCreatePhotoAvatar}>
            <div className="left">
              <img src={AvatarPhoto} />
            </div>
            <div className="center">
              <div className="title">照片生成数字人</div>
              <div className="desc">上传一张照片，快速复刻自己的数字人</div>
            </div>
          </div>
          <div className="model-item" onClick={toCreateAvatar}>
            <div className="left">
              <img src={AvatarVideo} />
            </div>
            <div className="center">
              <div className="title">视频生成数字人</div>
              <div className="desc">上传一段视频，快速复刻自己的数字人</div>
            </div>
            <div className="right recommond">
              <span className="gradient">推荐选择</span>
            </div>
          </div>
          <div className="model-item" onClick={toCreateAIAvatar}>
            <div className="left">
              <img src={AvatarAi} />
            </div>
            <div className="center">
              <div className="title">AI生成数字人</div>
              <div className="desc">AI生成独一无二的人脸，选择动作融合生成专属数字人</div>
            </div>
            <div className="right high">
              <span className="gradient-yellow">尊享专属</span>
            </div>
          </div>
        </div>
      </Modal>

      <VideoModal
        preview={preview}
        onCancel={() => setPreview(undefined)}
        btnShow={true}
        btnClick={toCreateVideo.bind(this, preview)}
      />

      <CreateAvatarDrawer
        open={createDrawerOpen}
        onCancel={() => setCreateDrawerOpen(false)}
        onOk={onCreateSuccess}
        group={group}
      />

      <CreatePhotoAvatarDrawer
        open={createPhotoDrawerOpen}
        onCancel={() => setCreatePhotoDrawerOpen(false)}
        onOk={onCreateSuccess}
        group={group}
      />

      <CreateAiAvatarDrawer
        open={createAIDrawerOpen}
        onCancel={() => setCreateAIModalOpen(false)}
        onOk={onCreateSuccess}
        group={group}
      />

      <Groups avatarId={avatarId} onSuccess={getGroupHumans} onCancel={() => setAvatarId(undefined)} />
    </div>
  )
}

export default memo(Index)
