import './style.scss'
import { Button } from 'antd'
import { FC, memo, useEffect, useState } from 'react'
import * as Api from '@/api/account'
import IconActivity from '@/assets/activity.gif'
import ActivityModal from '@/components/ActivityModal'
import CheckLogin from '@/components/CheckLogin'
import { IProduct, UserStore } from '@/global-states'
import { eventTracking, showPlanModal } from '@/libs/util'

interface IProps {
  title?: string
}

const LayoutHeader: FC<IProps> = (props) => {
  const { userPackage, systemReady, systemGuideStep } = UserStore
  const [activityModalOpen, setActivityModalOpen] = useState(false)
  const [productList, setProductList] = useState<IProduct[]>([])

  useEffect(() => {
    if (systemReady) {
      getData()
    }
  }, [systemReady])

  const getData = async () => {
    const res = await Api.getProductList()
    const list: IProduct[] = res?.list || []
    const activityProduct = list?.filter((p) => p.activity?.includes('activity'))
    setProductList(activityProduct || [])
    if (
      activityProduct?.length &&
      !sessionStorage.getItem('activity') &&
      !location.href.includes('setting') &&
      !systemGuideStep
    ) {
      setActivityModalOpen(true)
    }
  }

  const switchVersion = async () => {
    await eventTracking('SwitchToOldVersion')
    const token = UserStore.getToken() || ''
    let param = ''
    if (token.length > 12) {
      param = `/?token=${token}`
    }
    if (location.href.includes('dev')) {
      location.replace(`https://light-dev.hifly.cc${param}`)
    } else {
      location.replace(`https://light.hifly.cc${param}`)
    }
  }

  return (
    <div className="layout-header">
      <div className="layout-header-main">
        {!!productList?.length && (
          <img
            className="icon-activity"
            src={IconActivity}
            onClick={() => {
              eventTracking('ActivityIconClick-618')
              setActivityModalOpen(true)
            }}
          />
        )}

        <Button className="swtich" onClick={switchVersion}>
          切回旧版
        </Button>

        {(!userPackage?.membership_expire_time || userPackage?.membership_expired) && (
          <CheckLogin>
            <Button type="primary" onClick={() => showPlanModal()}>
              开通VIP
            </Button>
          </CheckLogin>
        )}
      </div>

      <ActivityModal
        open={activityModalOpen}
        onCancel={() => {
          setActivityModalOpen(false)
        }}
      />
    </div>
  )
}

export default memo(LayoutHeader)
