import './style.scss'
import { Button, Drawer, Input, message, Modal, Progress, Radio, Space, Spin } from 'antd'
import { RcFile } from 'antd/es/upload'
import Dragger from 'antd/es/upload/Dragger'
import axios from 'axios'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { cmsApi } from '@/api'
import * as homeApi from '@/api/home'
import error1 from '@/assets/error1.png'
import error2 from '@/assets/error2.png'
import error3 from '@/assets/error3.png'
import error4 from '@/assets/error4.png'
import SystemDeclare from '@/components/Declare'
import VideoModal from '@/components/VideoModal'
import { UserStore } from '@/global-states'
import { eventTracking, showDeclare, showPlanModal } from '@/libs/util'
import { CloudUploadOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons'

interface IProps {
  open: boolean
  onCancel?: () => void
  onOk?: () => void
  group: any
}

const CreatePhotoAvatarDrawer: FC<IProps> = (props) => {
  const { open, group, onCancel, onOk } = props
  const { declareChecked } = UserStore
  const [currentStep, setCurrentStep] = useState(0)
  const [photoFile, setPhotoFile] = useState<RcFile>(undefined as any)
  const [photoUrl, setPhotoUrl] = useState<string>(undefined as any)
  const [photoInfo, setPhotoInfo] = useState<any>({})
  const [percent, setPercent] = useState(0)
  const cancelTokenSource = useRef<any>()
  const [name, setName] = useState('')
  const [title, setTitle] = useState('')
  const [guideModalOpen, setGuideModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [preview, setPreview] = useState<any>()
  const [pictureInfo, setPictureInfo] = useState<any>()
  const [mode, setMode] = useState<any>()

  const modeInfo = useMemo(() => {
    if (pictureInfo) {
      return pictureInfo?.provider_info_list?.find((l: any) => l.provider === mode)
    }
    return
  }, [pictureInfo, mode])

  useEffect(() => {
    if (open) {
      setName('')
      restartUpload()
      setMode(2)
      getPictureInfo()
    }
  }, [open])

  useEffect(() => {
    if (photoFile) {
      setCurrentStep(1)
      setPhotoUrl(URL.createObjectURL(photoFile))
    } else {
      setPhotoUrl(undefined as any)
    }
  }, [photoFile])

  useEffect(() => {
    if (group?.id) {
      setTitle(`${group.title}-形象${(group.member_coun || 0) + 1}`)
    }
  }, [group])

  const getPictureInfo = async () => {
    const res = await homeApi.getPictureAvatarInfo()
    setPictureInfo(res)
  }

  const onGuideModalClose = () => {
    setGuideModalOpen(false)
  }

  const beforeVideoUpload = async (file: RcFile) => {
    return new Promise((resolve) => {
      const type = file?.name?.split(/\./)?.slice(-1)?.[0]?.toLocaleLowerCase() as string
      if (['png', 'jpg', 'jpeg'].includes(type)) {
        const size = file?.size || 0
        if (size / 1000 / 1000 > 10) {
          message.warning('照片大小不能大于 10MB')
          resolve(false)
        }
      } else {
        message.warning('请上传png、jpg、jpeg格式照片')
        resolve(false)
      }
      resolve(true)
    })
  }

  const onDrop = async (file: RcFile) => {
    const type = file?.name?.split(/\./)?.slice(-1)?.[0]?.toLocaleLowerCase() as string
    if (!['png', 'jpg', 'jpeg'].includes(type)) {
      message.warning('请上传png、jpg、jpeg格式照片')
    }
  }

  // media_type -  0 未定义  1 视频  2 音频 3 图片
  const uploadFile = async (file: RcFile, media_type: 0 | 1 | 2 | 3) => {
    try {
      const segs = (file.name || '').split(/\./)
      const { upload_url, oss_key, content_type } =
        (await cmsApi.post('upload_url', {
          extension: segs[segs.length - 1],
          media_type
        })) || {}
      if (!upload_url) {
        throw new Error('failed to upload file')
      }

      cancelTokenSource.current = axios.CancelToken.source()
      setPercent(0)
      setCurrentStep(1)

      await cmsApi.upload(upload_url.replace(/^http:\/\//, 'https://').replace('-internal', ''), file, {
        onUploadProgress: (progress) => {
          const percent = Math.round((progress.progress || 0) * 100)
          setPercent(percent)
        },
        headers: {
          'Content-Type': content_type
        },
        cancelToken: cancelTokenSource.current?.token
      })

      setCurrentStep(2)
      setPhotoInfo({
        title: segs[0],
        oss_key,
        file
      })
    } catch (err: any) {
      if (err?.code !== 'ERR_CANCELED') {
        message.error(err?.message || err)
      }
    }
  }

  const restartUpload = () => {
    cancelUpload()
    setCurrentStep(0)
    setPercent(0)
    setPhotoFile(undefined as any)
    setPhotoInfo(undefined)
  }

  const cancelUpload = () => {
    if (cancelTokenSource) {
      cancelTokenSource.current?.cancel('取消上传')
    }
  }

  const completeCreate = async () => {
    if (!declareChecked) {
      await showDeclare()
    }

    if (modeInfo?.out_of_stock) {
      return Modal.warning({
        title: '体验名额已满，请明日再来！',
        content: '非常抱歉，今日体验名额已满，请您明日再来体验，感谢您的理解与支持。'
      })
    }

    if (!name?.trim() && !group?.id) {
      return message.warning('请输入数字人的名字')
    }

    if (!photoInfo?.oss_key) {
      return message.warning('请上传图片')
    }

    submitCreate()
  }

  const submitCreate = async (ignoreFace?: boolean) => {
    eventTracking('InstantCloneConfirm')
    setLoading(true)
    try {
      const params: any = {
        oss_key: photoInfo?.oss_key,
        with_group: {
          new_group_title: name
        },
        source_type: 3,
        live_portrait_provider: mode,
        ignore_no_face_warning: ignoreFace
      }

      if (group?.id) {
        params.title = title
        params.with_group = {
          group_id: group.id
        }
      }
      await homeApi.addDigitalHumans(params)
      message.success('提交成功')
      onCancel?.()
      onOk?.()
    } catch (error: any) {
      if (error.code === 1014) {
        eventTracking('AvatarCloneLimit')
        Modal.confirm({
          title: '数字人复刻到达数量上限',
          content: <div>免费用户数字人复刻的创建次数上限为5，成为会员后解锁更多创建次数</div>,
          okText: '开通会员',
          cancelText: '取消',
          onOk: () => {
            showPlanModal()
          }
        })
      }

      if (error.code === 1024) {
        Modal.confirm({
          title: '未检测到人脸',
          content: (
            <div>
              系统未检测到人脸，或人脸特征与真实人脸差异较大，请更换图片以确保最佳效果。若继续生成，可能影响作品驱动效果。
            </div>
          ),
          okText: '继续生成',
          cancelText: '重新上传',
          onOk: () => {
            submitCreate(true)
          },
          onCancel: () => {
            restartUpload()
          }
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const onModeChange = (e: any) => {
    const v = e.target.value
    setMode(v)
  }

  const getTag = (index: number) => {
    const list = pictureInfo?.provider_info_list
    if (list?.length) {
      const { discounted_credits, origin_credits } = list[index] || {}
      if (!discounted_credits) {
        return <span className="discount-tag">限时免费</span>
      } else if (discounted_credits && discounted_credits !== origin_credits) {
        return <span className="discount-tag">限时特惠</span>
      }
    }
  }

  return (
    <Drawer
      className="create-photo-drawer"
      open={open}
      title="图片生成数字人"
      width={740}
      onClose={onCancel}
      footer={
        <>
          <SystemDeclare />
          <Space>
            {modeInfo && (
              <div className="fee">
                <div className="score">
                  {!modeInfo.discounted_credits && (
                    <>
                      <label className="through">{modeInfo.origin_credits}积分/次</label>
                      <label className="light">限时免费</label>
                    </>
                  )}
                  {modeInfo.discounted_credits && (
                    <>
                      {modeInfo.origin_credits === modeInfo.discounted_credits ? (
                        <label className="light">{modeInfo.origin_credits}积分/次</label>
                      ) : (
                        <>
                          <label className="through">{modeInfo.origin_credits}积分/次</label>
                          <label className="light">{modeInfo.discounted_credits}积分/次</label>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}

            <Button onClick={onCancel}>取消</Button>
            <Button type="primary" loading={loading} onClick={completeCreate}>
              {loading ? '复刻中' : '提交'}
            </Button>
          </Space>
        </>
      }
    >
      <Spin spinning={loading} tip={'数字人复刻中'}>
        <div className="main">
          <div className="form-item">
            <div className="title">数字人名称</div>
            <Input
              maxLength={20}
              placeholder="请输入数字人名称"
              value={group?.title || name}
              onChange={(e) => setName(e.target.value)}
              disabled={group?.id}
            />
          </div>

          {group?.id && (
            <div className="form-item">
              <div className="title">形象名称</div>
              <Input
                maxLength={20}
                placeholder="请输入形象名称"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          )}
          <div className="form-item">
            <div className="title">
              上传图片
              <ExclamationCircleFilled
                onClick={() => {
                  eventTracking('InstantCloneTips')
                  setGuideModalOpen(true)
                }}
              />
            </div>

            {currentStep === 0 && (
              <>
                <div className="warning">
                  <h4>图片要求</h4>
                  <div className="desc">
                    <div>
                      <label className="label">人物：</label>
                      <label>正面、半身</label>
                    </div>
                    <div>
                      <label className="label">格式：</label>
                      <label>png/jpg/jpeg</label>
                    </div>
                    <div>
                      <label className="label">限制：</label>
                      <label>不支持多人、人脸无遮挡</label>
                    </div>
                    <div className="w200">
                      <label className="label">大小：</label>
                      <label>不超过10MB，小于4000px</label>
                    </div>
                    <div className="w200">
                      <label className="label">比例：</label>
                      <label>v1版本无限制，v2版本限制宽高比为5:3或3:5（超出自动裁剪）</label>
                    </div>
                  </div>
                </div>
                <div className="content">
                  <Dragger
                    accept=".png,.PNG,.jpg,.JPG,.jepg,.JPEG"
                    showUploadList={false}
                    beforeUpload={async (file) => {
                      const flag = (await beforeVideoUpload(file)) as any
                      if (flag) {
                        setPhotoFile(file)
                        uploadFile(file, 3)
                      }
                      return flag
                    }}
                    onDrop={(e) => onDrop(e.dataTransfer.files?.[0] as any)}
                  >
                    <p className="ant-upload-drag-icon">
                      <CloudUploadOutlined />
                    </p>
                    <p className="ant-upload-text">请上传一张照片，用于生成照片数字人</p>
                    <p className="ant-upload-hint">将文件拖到此处，或点击此区域上传</p>
                  </Dragger>
                </div>
              </>
            )}
            {currentStep === 1 && (
              <div className="step-progress">
                <div className="step-progress-content">
                  <div className="percent">{percent}%</div>
                  <Progress percent={percent} showInfo={false} />
                  <div className="tips">照片上传中</div>
                </div>
                <div className="btns">
                  <Button onClick={restartUpload}>取消</Button>
                </div>
              </div>
            )}
            {currentStep === 2 && (
              <div className="step-view">
                <div className="step-view-box video">
                  <img src={photoUrl} />
                  <div className="trash" onClick={restartUpload}>
                    <DeleteOutlined />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="form-item">
            <div className="title">选择模型</div>

            <Radio.Group onChange={onModeChange} value={mode}>
              <Radio value={2}>
                <strong>v2.1-生动版</strong>
                {getTag(0)}
              </Radio>
              <Radio value={1}>
                <strong>v2.0-生动版</strong>
                {getTag(1)}
              </Radio>
            </Radio.Group>
          </div>

          <Modal
            title="图片要求"
            open={guideModalOpen}
            footer={
              <Button type="primary" onClick={onGuideModalClose}>
                知道了
              </Button>
            }
            closeIcon={false}
            width={580}
            onCancel={onGuideModalClose}
          >
            <div className="modal-guide">
              <ul>
                <li>
                  <label>1.</label>确保人物正面
                </li>
                <li>
                  <label>2.</label>不支持多人，请确保图片中只有一张人脸
                </li>
                <li>
                  <label>3.</label>人脸不要太小，建议人脸宽度占整体画面宽度的1/4以上
                </li>
                <li>
                  <label>4.</label>人脸不要太大，确保整张人脸都在屏幕区域内，人脸不要出屏幕
                </li>
                <li>
                  <label>5.</label>确保面部特征没有被遮挡，并努力让面部清晰可见
                </li>
                <li>
                  <label>6.</label>图片大小不超过10MB，长宽需小于4000px
                </li>
              </ul>
              <div className="guide-error">
                <div className="title-error">错误示例</div>
                <div className="error-list">
                  <div className="item">
                    <img className="img1" src={error1} />
                    <div>多张人脸</div>
                  </div>
                  <div className="item">
                    <img className="img2" src={error2} />
                    <div>人脸太大</div>
                  </div>
                  <div className="item">
                    <img className="img3" src={error3} />
                    <div>脸部遮挡</div>
                  </div>
                  <div className="item">
                    <img className="img3" src={error4} />
                    <div>检测不到人脸</div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </Spin>

      <VideoModal preview={preview} onCancel={() => setPreview(undefined)} />
    </Drawer>
  )
}

export default CreatePhotoAvatarDrawer
