import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { UserStore } from '@/global-states'
import { Render } from '@/libs/util'
import LoginModal from '../LoginModal'

interface CheckLoginProps {
  children: React.ReactElement
  onLoginSuccess?: () => void // 登录成功后的回调
}

export const openLoginModal = () => {
  if (UserStore.loginModalInstance) {
    UserStore.loginModalInstance.setOpen(true)
    return
  }

  const div = document.createElement('div')
  document.body.appendChild(div)

  const root = ReactDOM.createRoot(div)

  root.render(
    <ConfigProvider
      locale={zhCN}
      theme={{
        token: {
          colorPrimary: '#6828a8' // 设置主题色
        }
      }}
    >
      <Render>
        {function showPlanModal() {
          const [open, setOpen] = useState(true)

          UserStore.loginModalInstance = { setOpen }

          return <LoginModal open={open} onCancel={() => setOpen(false)} />
        }}
      </Render>
    </ConfigProvider>
  )
}

const CheckLogin: React.FC<CheckLoginProps> = ({ children, onLoginSuccess }) => {
  const { loginStatus, phone } = UserStore
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    if (phone) {
      setIsLoggedIn(true)
    }
  }, [phone])

  useEffect(() => {
    if (loginStatus) {
      setIsLoggedIn(true)
      onLoginSuccess?.()
    }
  }, [loginStatus])

  const handleClick = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (!isLoggedIn) {
      openLoginModal()
    } else {
      children.props.onClick?.()
    }
  }

  return (
    <>
      {React.cloneElement(children, {
        onClick: handleClick
      })}
    </>
  )
}

export default CheckLogin
