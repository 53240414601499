import { Radio } from 'antd'
import { FC } from 'react'
import { UserStore } from '@/global-states'
import { showDeclare } from '@/libs/util'

const SystemDeclare: FC = () => {
  const { declareChecked } = UserStore

  return (
    <div className="declare">
      <Radio checked={declareChecked} onClick={() => (UserStore.declareChecked = !UserStore.declareChecked)}></Radio>
      我已阅读并同意
      <label className="link" onClick={showDeclare}>
        《使用者承诺须知》
      </label>
    </div>
  )
}

export default SystemDeclare
