import './style.scss'
import { Button, Carousel, message, Modal, Popover } from 'antd'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { FC, memo, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Api from '@/api/home'
import MarketCircle1 from '@/assets/market-circle1.png'
import MarketCircle2 from '@/assets/market-circle2.png'
import MarketCircle3 from '@/assets/market-circle3.png'
import { Play } from '@/assets/svg'
import VideoModal from '@/components/VideoModal'
import { IUserPackage, UserStore } from '@/global-states'
import { eventTracking, Render, urlSource } from '@/libs/util'
import { CaretLeftOutlined, CaretRightOutlined, CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

dayjs.extend(duration)

interface IProps {
  open: boolean
  marketDetail: any
  onCancel?: () => void
}

const MarketModal: FC<IProps> = (props) => {
  const navigate = useNavigate()
  const { marketDetail, onCancel, open } = props
  const [preview, setPreview] = useState<any>()
  const [market, setMarket] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [videoCurrent, setVideoCurrent] = useState(0)
  const [videoIndex, setVideoIndex] = useState(0)

  const trackData = useMemo(() => {
    return {
      id: marketDetail?.id,
      name: marketDetail?.name
    }
  }, [marketDetail])

  useEffect(() => {
    if (open) {
      setMarket(marketDetail)
    }
  }, [open])

  const playVideo = () => {
    eventTracking('DigitalAvatarVideo', trackData)
    setPreview({ url: market.videos[videoIndex] })
  }

  const onVideoChange = (index: number) => {
    setVideoIndex(index)
  }

  const onClose = () => {
    setVideoIndex(0)
    setMarket(undefined)
    setVideoCurrent(0)
    onCancel?.()
  }

  const buyNow = async () => {
    eventTracking('DigitalAvatarOneQuote', trackData)
    setLoading(true)

    let totalPoint = 0
    try {
      const userPackage: IUserPackage = await UserStore.updateUserPackage()
      totalPoint =
        (userPackage.membership_credits || 0) + (userPackage.permanent_credits || 0) + (userPackage.free_credits || 0)
    } finally {
      setLoading(false)
    }

    Modal.confirm({
      title: '积分消耗确认',
      content: (
        <div>
          <div>你的积分余额：{totalPoint}积分</div> <div>本次消耗：{market.price}积分</div>
        </div>
      ),
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        await Api.buyMerchandises(market.id)
        Modal.warning({
          title: '雇佣完成',
          content: (
            <div>
              恭喜成功雇佣数字演员，前往
              <a
                style={{ textDecoration: 'underline', padding: '0 2px' }}
                onClick={() => {
                  Modal.destroyAll()
                  navigate('/home')
                }}
              >
                我的数字人
              </a>
              页面查看！
            </div>
          ),
          okText: '好的',
          onOk: () => {
            Modal.destroyAll()
            navigate('/home')
          }
        })
      }
    })
  }

  const addCollect = async () => {
    await Api.addBookmarkedCommunityHumans({
      merchandise_id: market.id
    })
    message.success('收藏成功')
    setMarket({
      ...market,
      bookmarked_by_user: true
    })
  }

  const cancelCollect = async () => {
    await Api.cancelBookmarkedCommunityHumansByMerchandiseId(market.id)
    message.success('已取消收藏')
    setMarket({
      ...market,
      bookmarked_by_user: false
    })
  }

  const toCreate = async () => {
    const res = await Api.addBookmarkedCommunityHumans({
      merchandise_id: market.id
    })
    if (res.group_id) {
      sessionStorage.setItem('bookAvatarId', res.group_id)
      navigate(`/create-video`)
    }
  }

  if (!market) return null

  return (
    <>
      <Modal
        maskClosable={true}
        title={null}
        open={open}
        width={'800px'}
        footer={null}
        onCancel={onClose}
        className="market-modal"
        closeIcon={null}
      >
        <div className="market-modal-content">
          <div className="modal-content-main">
            <div className="left">
              <Render>
                {function LoadImg() {
                  const [imgClass, setImgClass] = useState('')
                  return (
                    <img
                      onClick={playVideo}
                      className={`img ${imgClass}`}
                      src={urlSource(market?.videos?.[videoIndex], 'video')}
                      onLoad={(e: any) => {
                        const w = e.target?.naturalWidth
                        const h = e.target?.naturalHeight
                        if (h > w) {
                          setImgClass('portrait')
                        }
                      }}
                    />
                  )
                }}
              </Render>

              <Play onClick={playVideo} className="play" />
              {market?.type !== 3 && (
                <div className="tag-desc">
                  <Popover
                    content={
                      <div className="common-popover w400" onClick={(e) => e.stopPropagation()}>
                        <div>
                          <strong>雇佣说明</strong>
                        </div>
                        <ul>
                          <li>数字演员由AI生成，独一无二；</li>
                          <li>雇佣后没有使用限制</li>
                        </ul>
                      </div>
                    }
                    placement="bottom"
                  >
                    雇佣说明
                  </Popover>
                </div>
              )}

              <div className="video-list">
                <Carousel
                  infinite={false}
                  arrows={true}
                  prevArrow={<CaretLeftOutlined />}
                  nextArrow={<CaretRightOutlined />}
                  dots={false}
                  afterChange={(current: number) => {
                    if (videoCurrent < current) {
                      eventTracking('DigitalAvataRightClick', trackData)
                    } else {
                      eventTracking('DigitalAvataLeftClick', trackData)
                    }
                    setVideoCurrent(current)
                  }}
                >
                  {market?.videos?.map(
                    (video: any, index: number) =>
                      index % 4 === 0 && (
                        <div className="carousel-videos" key={index}>
                          <div className="video" onClick={onVideoChange.bind(this, index)}>
                            <Render>
                              {function LoadImg() {
                                const [imgClass, setImgClass] = useState('')
                                return (
                                  <img
                                    className={`${imgClass}`}
                                    src={urlSource(video, 'video')}
                                    onLoad={(e: any) => {
                                      const w = e.target?.naturalWidth
                                      const h = e.target?.naturalHeight
                                      if (h > w) {
                                        setImgClass('portrait')
                                      }
                                    }}
                                  />
                                )
                              }}
                            </Render>

                            {videoIndex === index && (
                              <>
                                <CheckOutlined className="check" />
                                <div className="mask"></div>
                              </>
                            )}
                          </div>

                          {market.videos[index + 1] && (
                            <div className="video" onClick={onVideoChange.bind(this, index + 1)}>
                              <Render>
                                {function LoadImg() {
                                  const [imgClass, setImgClass] = useState('')
                                  return (
                                    <img
                                      className={`${imgClass}`}
                                      src={urlSource(market.videos[index + 1], 'video')}
                                      onLoad={(e: any) => {
                                        const w = e.target?.naturalWidth
                                        const h = e.target?.naturalHeight
                                        if (h > w) {
                                          setImgClass('portrait')
                                        }
                                      }}
                                    />
                                  )
                                }}
                              </Render>

                              {videoIndex === index + 1 && (
                                <>
                                  <CheckOutlined className="check" />
                                  <div className="mask"></div>
                                </>
                              )}
                            </div>
                          )}
                          {market.videos[index + 2] && (
                            <div className="video" onClick={onVideoChange.bind(this, index + 2)}>
                              <Render>
                                {function LoadImg() {
                                  const [imgClass, setImgClass] = useState('')
                                  return (
                                    <img
                                      className={`${imgClass}`}
                                      src={urlSource(market.videos[index + 2], 'video')}
                                      onLoad={(e: any) => {
                                        const w = e.target?.naturalWidth
                                        const h = e.target?.naturalHeight
                                        if (h > w) {
                                          setImgClass('portrait')
                                        }
                                      }}
                                    />
                                  )
                                }}
                              </Render>
                              {videoIndex === index + 2 && (
                                <>
                                  <CheckOutlined className="check" />
                                  <div className="mask"></div>
                                </>
                              )}
                            </div>
                          )}

                          {market.videos[index + 3] && (
                            <div className="video" onClick={onVideoChange.bind(this, index + 3)}>
                              <Render>
                                {function LoadImg() {
                                  const [imgClass, setImgClass] = useState('')
                                  return (
                                    <img
                                      className={`${imgClass}`}
                                      src={urlSource(market.videos[index + 3], 'video')}
                                      onLoad={(e: any) => {
                                        const w = e.target?.naturalWidth
                                        const h = e.target?.naturalHeight
                                        if (h > w) {
                                          setImgClass('portrait')
                                        }
                                      }}
                                    />
                                  )
                                }}
                              </Render>
                              {videoIndex === index + 3 && (
                                <>
                                  <CheckOutlined className="check" />
                                  <div className="mask"></div>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      )
                  )}
                </Carousel>
              </div>
            </div>
            <div className="right">
              <div className="right-center">
                <div className="circle">
                  <img className="circle-1" src={MarketCircle1} />
                  <img className="circle-2" src={MarketCircle2} />
                  <img className="circle-3" src={MarketCircle3} />
                  <div className="img">
                    <img src={market?.covers?.[0]} />
                  </div>
                  <div className="name">{market?.name}</div>
                  <div className="scene">{market?.scene}</div>
                </div>
              </div>
              <div className="right-bottom">
                {market.type === 3 ? (
                  <div className="btns">
                    {market.bookmarked_by_user ? (
                      <Button color="primary" variant="dashed" onClick={cancelCollect}>
                        取消收藏
                      </Button>
                    ) : (
                      <Button type="default" onClick={addCollect}>
                        收藏
                      </Button>
                    )}
                    <Button type="primary" disabled={market.status === 2} onClick={toCreate}>
                      去创作
                    </Button>
                  </div>
                ) : (
                  <div className="btns">
                    {market.status === 2 ? (
                      <Button type="primary" disabled={market.status === 2}>
                        已被雇佣
                      </Button>
                    ) : (
                      <Button type="primary" disabled={market.status === 2} onClick={buyNow} loading={loading}>
                        {market.price}积分雇佣
                      </Button>
                    )}
                  </div>
                )}

                {market?.special_cost && (
                  <Popover
                    content={
                      <div className="common-popover w400" onClick={(e) => e.stopPropagation()}>
                        <div>
                          <strong>创作优惠：</strong>
                        </div>
                        <ul>
                          <li>
                            标有“创作优惠”的数字演员。雇佣后，在创作视频时享受积分消耗优惠（
                            {market.creation_cost_per_second} 积分/秒），即：
                            <div className="ml10">
                              a. 使用音频驱动生成视频时：{market.creation_cost_per_second} 积分/秒；
                            </div>
                            <div className="ml10">
                              b. 使用文本驱动生成视频时：{market.creation_cost_per_second + 1} 积分/秒；
                            </div>
                          </li>
                          <li>仅从数字市场雇佣才有“创作优惠”标记，其他方式上传的视频均无法享有；</li>
                        </ul>
                      </div>
                    }
                    placement="bottom"
                  >
                    <div className="market-discount" onClick={(e) => e.stopPropagation()}>
                      此数字在创作视频时享受积分消耗优惠
                      <ExclamationCircleOutlined />
                    </div>
                  </Popover>
                )}
              </div>
            </div>
          </div>

          <VideoModal preview={preview} onCancel={() => setPreview(undefined)} />
        </div>
      </Modal>
    </>
  )
}

export default memo(MarketModal)
