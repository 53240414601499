import './style.scss'
import { Button } from 'antd'
import { FC, memo, useEffect, useRef, useState } from 'react'
import { demoAudios, urlSource } from '@/libs/util'
import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons'

interface IProps {
  onSelect?: (v: any) => void
}

const AudioDemos: FC<IProps> = (props) => {
  const [previewUrl, setPreviewUrl] = useState<string>(undefined as any)
  const audioRef = useRef<any>()

  useEffect(() => {
    setPreviewUrl('')
    return () => clearEvent()
  }, [])

  useEffect(() => {
    if (previewUrl) {
      clearEvent()
      const audio = new Audio()
      audio.src = urlSource(previewUrl)
      audio.play()
      audioRef.current = audio

      audio.addEventListener('ended', () => {
        setPreviewUrl('')
      })
    } else {
      clearEvent()
    }
  }, [previewUrl])

  const clearEvent = () => {
    if (audioRef.current) {
      audioRef.current.pause()
      audioRef.current = undefined
    }
  }

  return (
    <div className="audio-demo">
      <div className="header-title">
        示例音频<span className="yellow">暂时没有音频素材？使用我们的示例素材来感受效果吧！</span>
      </div>
      <div className="demo-list">
        {demoAudios.map((v) => (
          <div className="list-item" key={v.id}>
            <audio src={urlSource(v.url)} />
            <div className="audio">
              {previewUrl === v.url ? (
                <PauseCircleOutlined onClick={() => setPreviewUrl(undefined as any)} />
              ) : (
                <PlayCircleOutlined onClick={() => setPreviewUrl(v.url)} />
              )}
              <span className="name ellipsis">{v.name}</span>
              <Button type="primary" onClick={() => props.onSelect?.(v)}>
                选用
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default memo(AudioDemos)
