import { message as Message } from 'antd'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { openLoginModal } from '@/components/CheckLogin'
import { UserStore } from '@/global-states'
import { eventTracking } from '@/libs/util'

axios.defaults.timeout = 1000 * 60 * 60
axios.defaults.headers.post['Content-Type'] = 'application/json'

// 创建axios实例
const service = axios.create()

// axios实例拦截请求
service.interceptors.request.use(
  (config: any) => {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${UserStore.getToken()}`,
      'X-Client-Type': 'web',
      'X-Name': 'hiflyworks-web'
    }
    return config
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  }
)

// axios实例拦截响应
service.interceptors.response.use(
  // 2xx时触发
  (response: AxiosResponse) => {
    // response.data就是后端返回的数据，结构根据约定来定义
    const { code, data, message } = response.data || {}
    if (code === 0 || code === 200 || code === 201 || !code) {
      return data || response.data || {}
    } else if (code === 12) {
      if (UserStore.getToken()) {
        location.replace('/login')
      } else {
        openLoginModal()
      }

      return Promise.reject(response.data)
    } else if (response.data) {
      // 错误上报
      if (code === 99) {
        eventTracking('system_error', {
          url: response.config?.url
        })
      }
      if (code !== 1024) {
        Message.error(message)
      }
      return Promise.reject(response.data)
    }
  },
  // 非2xx时触发
  (error: any) => {
    Message.destroy()
    if (error?.config?.method === 'get' && error?.config?.url?.includes('app/v1/creations')) {
      console.log(error)
    } else if (error.code === 'ERR_NETWORK') {
      Message.error('网络开小差了，请检查网络设置')
    } else {
      const message = error?.response?.data?.message || error?.message
      Message.error(message)
    }

    return Promise.reject(error)
  }
)

export type { AxiosResponse, AxiosRequestConfig }

export default service
