export const Play = (props: any) => {
  return (
    <svg
      style={{ border: '2px solid #fff', width: '36px', height: '36px', borderRadius: '36px' }}
      {...props}
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="72" height="72" rx="36" fill="black" fillOpacity="0.49" />
      <path
        d="M27 45.5873V26.4127C27 24.0564 29.5919 22.6199 31.59 23.8687L46.9296 33.456C48.8096 34.631 48.8096 37.369 46.9296 38.544L31.59 48.1313C29.5919 49.3801 27 47.9436 27 45.5873Z"
        fill="white"
      />
    </svg>
  )
}
