import './style.scss'
import { Button, Input, Modal } from 'antd'
import { Buffer } from 'buffer'
import { FC, useEffect, useRef, useState } from 'react'
import wavConverter from 'wav-converter'
import * as homeApi from '@/api/home'
import AvatarAi from '@/assets/avatar-ai.png'
import AvatarPhoto from '@/assets/avatar-photo.png'
import AvatarVideo from '@/assets/avatar-video.png'
import IconCheckbox from '@/assets/icon-checkbox.png'
import { PlayFill } from '@/assets/svg/play-fill'
import { UserStore } from '@/global-states'
import { eventTracking, randomTexts, urlSource } from '@/libs/util'
import { LoadingOutlined, PauseOutlined } from '@ant-design/icons'

interface IProps {
  updateAvatar: (avatar: any) => void
  updateVoice: (voice: any) => void
  updateText: (text: string) => void
}

const SystemGuide: FC<IProps> = (props) => {
  const { systemGuideStep } = UserStore
  const { updateAvatar, updateVoice, updateText } = props
  const [avatars, setAvatars] = useState<any[]>([])
  const [voices, setVoices] = useState<any[]>([])
  const [selectAvatar, setSelectAvatar] = useState<any>()
  const [selectVoice, setSelectVoice] = useState<any>()
  const [text, setText] = useState('')
  const [randomKey, setRandomKey] = useState()
  const [previewId, setPreviewId] = useState()
  const [playingId, setPlayingId] = useState()
  const previewIdRef = useRef<any>()
  const audioRef = useRef<any>()

  useEffect(() => {
    setRandomText()
    getSystemGuideConfig()
  }, [])

  useEffect(() => {
    previewIdRef.current = previewId
  }, [previewId])

  const getSystemGuideConfig = async () => {
    const [guideInfo, globalTts, globalHumans] = await Promise.all([
      homeApi.getGuideInfo(),
      homeApi.getGlobalTts(),
      homeApi.getGlobalDigitalHumans()
    ])

    const filterAvatars = guideInfo?.digital_human_ids?.map((id: any) => {
      const f = globalHumans?.list?.find((g: any) => g.id === id)
      return f
    })

    const filterVoices = guideInfo?.tts_voice_ids?.map((id: any) => {
      const f = globalTts?.list?.find((g: any) => g.id === id)
      return f
    })

    setSelectAvatar(filterAvatars?.[0])
    setSelectVoice(filterVoices?.[0])
    setAvatars(filterAvatars)
    setVoices(filterVoices)
  }

  const nextStep = () => {
    if (systemGuideStep === 2) {
      updateAvatar(selectAvatar)
      eventTracking('SystemGuideNextStep2')
    }

    if (systemGuideStep === 3) {
      updateVoice(selectVoice)
      eventTracking('SystemGuideNextStep3')
    }

    if (systemGuideStep === 4) {
      updateText(text)
      eventTracking('SystemGuideNextStep4')
    }
    UserStore.systemGuideStep = systemGuideStep + 1
  }

  const setRandomText = () => {
    const r: any = randomTexts.filter((t) => t.key !== randomKey)
    const index = Math.floor(Math.random() * r.length)
    setRandomKey(r[index].key)
    setText(r[index].text)
  }

  const clearAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause?.()
      audioRef.current.src = ''
    }
  }

  const closeAudio = () => {
    clearAudio()
    setPlayingId(undefined)
    setPreviewId(undefined)
  }

  const previewTts = async (tts: any, text: string) => {
    if (playingId === tts.id) {
      closeAudio()
      return
    } else if (previewId === tts.id) {
      return
    }

    setPreviewId(tts.id)
    setPlayingId(undefined)
    clearAudio()

    try {
      let res
      if (tts.parent_id) {
        res = await homeApi.previewCommunityVoice(tts.parent_id, tts.id, {
          text,
          voice_parameters: tts.voice_parameters
        })
      } else {
        res = await homeApi.previewTts(tts.id, { text, voice_parameters: tts.voice_parameters })
      }
      if (previewIdRef.current !== tts.id) {
        return
      }
      setPlayingId(tts.id)

      clearAudio()

      const audio = new Audio()
      audio.src = `data:audio/wav;base64,${wavConverter
        .encodeWav(new Buffer(res.audio_base64, 'base64'), {
          numChannels: 1,
          sampleRate: 16000,
          byteRate: 32_000
        })
        .toString('base64')}`
      audio.play()
      audioRef.current = audio

      audio.addEventListener('ended', function () {
        closeAudio()
      })

      audio.addEventListener('pause', function () {
        closeAudio()
      })
    } catch {
      closeAudio()
    }
  }

  const skipStep = () => {
    eventTracking('SystemGuideSkipStep', {
      step: systemGuideStep
    })
    UserStore.systemGuideStep = undefined
    localStorage.setItem('systemGuideRead', '1')
  }

  return (
    <Modal
      title={null}
      open={systemGuideStep > 1 && systemGuideStep < 5}
      width={564}
      footer={null}
      className="system-guide-modal"
      maskClosable={false}
      closeIcon={null}
    >
      <div className="system-guide-step">
        {systemGuideStep === 2 && (
          <div className="guide-step step-one">
            <div className="step-title">选择数字人</div>
            <div className="step-desc">挑选一个喜欢的数字人生成视频吧</div>

            <div className="step-avatar">
              <div className="img-list">
                {avatars?.map((v) => (
                  <div key={v.id} className="img-list__item">
                    <div className="img-box" onClick={() => setSelectAvatar(v)}>
                      <img className="img" src={urlSource(v.group_cover_url, 'video')} />

                      {v.id === selectAvatar?.id && (
                        <div className="mask">
                          <img className="checked" src={IconCheckbox} />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="desc">
                <div className="left">稍后也可以克隆自己的数字分身哦</div>
                <div className="right">
                  <img src={AvatarPhoto} />
                  <img src={AvatarVideo} />
                  <img src={AvatarAi} />
                </div>
              </div>
            </div>

            <div className="step-footer">
              <Button type="primary" onClick={nextStep}>
                下一步
              </Button>
            </div>
          </div>
        )}

        {systemGuideStep === 3 && (
          <div className="guide-step step-one">
            <div className="step-title">选择声音</div>
            <div className="step-desc">为你的数字人挑选声音，既可以选择公共声音也可以克隆声音</div>

            <div className="step-voice">
              <div className="detail-list">
                {voices?.map((t) => (
                  <div
                    className={`detail-list__item ${selectVoice.name === t.name ? 'actived' : ''}`}
                    key={t.id}
                    onClick={() => setSelectVoice(t)}
                  >
                    <div
                      className="left"
                      onClick={(e) => {
                        e.stopPropagation()
                        previewTts(t, t.preview_text || '现在的一切都是为将来的梦想编织翅膀，让梦想在现实中展翅高飞。')
                      }}
                    >
                      {previewId === t.id ? (
                        playingId === t.id ? (
                          <PauseOutlined />
                        ) : (
                          <LoadingOutlined />
                        )
                      ) : (
                        <PlayFill className="play" />
                      )}
                    </div>
                    <div className="center">
                      <div className={`name ellipsis ${selectVoice.name === t.name ? 'gradient' : ''}`}>
                        {t.display_name}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="step-footer mt0">
              <Button type="primary" onClick={nextStep}>
                下一步
              </Button>
            </div>
          </div>
        )}

        {systemGuideStep === 4 && (
          <div className="guide-step step-one">
            <div className="step-title">编辑文本</div>
            <div className="step-desc">选择一段文本，让数字人读出来！</div>

            <div className="step-text">
              <div className="box">
                <Input.TextArea
                  value={text}
                  placeholder="请输入文本"
                  className="textarea"
                  maxLength={3000}
                  onChange={(e) => setText(e.target.value)}
                />
                <div className="btn" onClick={setRandomText}>
                  <span className="gradient">随机文案</span>
                </div>
              </div>
            </div>

            <div className="step-footer">
              <Button disabled={text?.length < 5} type="primary" onClick={nextStep}>
                下一步
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className="skip-step" onClick={skipStep}>
        跳过指引（{systemGuideStep - 1}/3）
      </div>
    </Modal>
  )
}

export default SystemGuide
