import './style.scss'
import { Buffer } from 'buffer'
import { FC, memo, useEffect, useRef, useState } from 'react'
import wavConverter from 'wav-converter'
import * as homeApi from '@/api/home'
import { PlayGray } from '@/assets/svg/play-gray'
import CheckLogin from '@/components/CheckLogin'
import { eventTracking } from '@/libs/util'
import { LoadingOutlined, PauseOutlined } from '@ant-design/icons'

interface IProps {
  list: any[]
  onItemClick: (t: any) => void
  onDelete?: () => void
}

const VoiceMarketBox: FC<IProps> = (props) => {
  const { list, onItemClick, onDelete } = props
  const audioRef = useRef<any>()
  const [previewId, setPreviewId] = useState()
  const [playingId, setPlayingId] = useState()
  const previewIdRef = useRef<any>()

  useEffect(() => {
    return () => closeAudio()
  }, [])

  useEffect(() => {
    previewIdRef.current = previewId
  }, [previewId])

  const previewTts = async (groupId: number, tts: any, text: string, e: any) => {
    e.stopPropagation()
    eventTracking('VoicePreview', { group_id: groupId, tts_id: tts?.id })
    if (playingId === tts.id) {
      closeAudio()
      return
    } else if (previewId === tts.id) {
      return
    }

    setPreviewId(tts.id)
    setPlayingId(undefined)
    clearAudio()

    try {
      const res = await homeApi.previewCommunityVoice(groupId, tts.id, { text, voice_parameters: tts.voice_parameters })

      if (previewIdRef.current !== tts.id) {
        return
      }
      setPlayingId(tts.id)

      clearAudio()

      const audio = new Audio()
      audio.src = `data:audio/wav;base64,${wavConverter
        .encodeWav(new Buffer(res.audio_base64, 'base64'), {
          numChannels: 1,
          sampleRate: 16000,
          byteRate: 32_000
        })
        .toString('base64')}`
      audio.play()
      audioRef.current = audio

      audio.addEventListener('ended', function () {
        closeAudio()
      })

      audio.addEventListener('pause', function () {
        closeAudio()
      })
    } catch {
      closeAudio()
    }
  }

  const clearAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause?.()
      audioRef.current.src = ''
    }
  }

  const closeAudio = () => {
    clearAudio()
    setPlayingId(undefined)
    setPreviewId(undefined)
  }

  const cancelCollectVoice = async (group: any) => {
    await homeApi.delteBookmarkedCommunityVoice(group.id)
    onDelete?.()
  }

  return (
    <>
      {list?.map((t) => (
        <div className="voice-market-box" key={t.id}>
          <CheckLogin>
            <div className={`box-audio`} onClick={() => onItemClick(t)}>
              {/* <div className="top ellipsis">来自：{t.from_user}</div> */}
              <div className="center">
                <div className="center-left">
                  <img src={t.cover_url} />
                </div>
                <div className="center-right">
                  <div className="name ellipsis">{t.title}</div>
                  {t.scenes && <div className="scene ellipsis">适合场景：{t.scenes?.join('、')}</div>}

                  <div className="style-list">
                    {t.members?.map((m: any) => (
                      <div className="style-item" key={m.id}>
                        <div
                          className="item"
                          onClick={previewTts.bind(
                            this,
                            t.id,
                            m,
                            m.preview_text || '现在的一切都是为将来的梦想编织翅膀，让梦想在现实中展翅高飞。'
                          )}
                        >
                          {previewId === m.id ? (
                            playingId === m.id ? (
                              <PauseOutlined />
                            ) : (
                              <LoadingOutlined />
                            )
                          ) : (
                            <PlayGray className="play" />
                          )}
                          <label>{t.title.includes(m.title) ? '默认风格' : m.title}</label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </CheckLogin>

          {t.share_canceled && (
            <div className="box-delete">
              <div>已删除，不可用</div>
              <div className="btn" onClick={cancelCollectVoice.bind(this, t)}>
                移除
              </div>
            </div>
          )}
        </div>
      ))}
    </>
  )
}

export default memo(VoiceMarketBox)
