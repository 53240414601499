import './style.scss'
import { Button, Input, List, message, Modal, Tooltip } from 'antd'
import dayjs from 'dayjs'
import VirtualList from 'rc-virtual-list'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import * as HomeApi from '@/api/home'
import ArrowRight from '@/assets/arrow_right.png'
import IconCreationEmpty from '@/assets/icon-creation-empty.png'
import { Play } from '@/assets/svg'
import { UserStore } from '@/global-states'
import { downlad, eventTracking, Render, urlSource } from '@/libs/util'
import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  FormOutlined,
  InfoCircleOutlined,
  LoadingOutlined
} from '@ant-design/icons'

interface IProps {
  onRecreate: (id: number) => void
}

const VideoList = forwardRef((props: IProps, ref) => {
  const { creationList } = UserStore
  const [videos, setVideos] = useState<any[]>()
  const [videoIndex, setVideoIndex] = useState(-1)
  const [imgIndex, setImgIndex] = useState(0)
  const [isManualScroll, setIsManualScroll] = useState(false) // 标志位
  const virtualListRef = useRef<any>(null)
  const imgListRef = useRef<any>(null)
  const [open, setOpen] = useState(true)
  const [editInfo, setEditInfo] = useState<any>()
  const [renameModalOpen, setRenameModalOpen] = useState(false)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    getVideoList()
    updateVirtualConfig()
  }, [])

  useImperativeHandle(ref, () => ({
    // 暴露给父组件的方法
    updateVideos: () => {
      setVideoIndex(-1)
      handleThumbnailClick(0)
      imgListRef.current?.scrollIntoView()
      getVideoList()
    }
  }))

  useEffect(() => {
    if (!open) {
      setImgIndex(-1)
      setVideoIndex(-1)
    }
  }, [open])

  useEffect(() => {
    if (videos?.length) {
      setVideos(
        videos?.map((d: any) => {
          const f = creationList.find((c: any) => c.id === d.id)
          if (f) {
            return f
          }
          return d
        })
      )
    }
  }, [creationList])

  const updateVirtualConfig = () => {
    const h = window.innerHeight - 104 // 计算高度
    setHeight(h)
  }

  const getVideoList = async () => {
    const res = await HomeApi.getCreations({
      page: 0,
      size: 500
    })
    setVideos((res.list || []).filter((l: any) => !l.only_generate_audio))
  }

  const onScroll = (e: any) => {
    if (!open) return
    if (isManualScroll) {
      // 如果是手动触发的滚动，不更新 imgIndex
      setIsManualScroll(false) // 重置标志位
      return
    }
    const id = e.target.querySelector('.ant-list-item')?.getAttribute('data-id')
    if (id) {
      const index = videos?.findIndex((v) => v.id === +id) || 0
      setImgIndex(index)
      if (index > videoIndex) {
        setVideoIndex(-1)
      }
    }
  }

  const handleThumbnailClick = (index: number) => {
    setOpen(true)
    setTimeout(
      () => {
        setImgIndex(index)
        if (virtualListRef.current) {
          setIsManualScroll(true)
          const scrollTop = index * 389
          virtualListRef.current.scrollTo(scrollTop)
        }
      },
      open ? 0 : 50
    )
  }

  const reCreate = async (d: any) => {
    eventTracking('CopyCreateVideo')
    const res = await HomeApi.getCreationDetail(d.id)
    if (res?.origin_request) {
      props.onRecreate(d.id)
    }
  }

  const deleteVideo = async (id: number) => {
    Modal.confirm({
      title: '删除作品',
      content: '作品删除后不可恢复，确认删除作品？',
      onOk: async () => {
        await HomeApi.deleteCreations(id)
        getVideoList()
        message.success('删除成功')
      }
    })
  }

  const sureRename = async () => {
    await HomeApi.updateCreations(editInfo.id, {
      title: editInfo.title
    })
    setRenameModalOpen(false)
    setVideos(
      videos?.map((d: any) => {
        return d.id === editInfo.id
          ? {
              ...d,
              title: editInfo.title
            }
          : d
      })
    )
  }

  if (!height) return null

  return (
    <div className="list-wrapper" style={{ width: open ? `${640}px` : '124px' }}>
      <div className="close-icon" onClick={() => setOpen(!open)}>
        <img src={ArrowRight} style={{ transform: open ? 'rotate(0)' : 'rotate(180deg)' }} />
      </div>
      <>
        {videos && (
          <>
            {videos.length ? (
              <>
                <div className="video-wrapper-left">
                  <List>
                    <VirtualList
                      itemHeight={389}
                      height={height}
                      className="virtual-videos"
                      data={videos}
                      itemKey="email"
                      onScroll={onScroll}
                      ref={virtualListRef}
                    >
                      {(item: any, index) => (
                        <List.Item key={item.id} data-id={item.id}>
                          <Render>
                            {function ListItem() {
                              const [percent, setPercent] = useState(-1)
                              const xhrRef = useRef<any>()
                              const downladVideo = (d: any) => {
                                eventTracking('CreationsDownload', {
                                  url: d.url,
                                  id: d.id
                                })

                                if (xhrRef.current) {
                                  xhrRef.current.abort()
                                  setPercent(0)
                                }
                                const url = d.url.split('?')[0]
                                const extension = url.substring(url.lastIndexOf('.')).toLowerCase()
                                const xhr: XMLHttpRequest = downlad(d.url, `${d.title}${extension}`)
                                xhr.onprogress = (e) => {
                                  const p = Math.floor((e.loaded / e.total) * 100) // 下载进度
                                  console.log('进度', p)
                                  setPercent(p)
                                  if (p === 100) {
                                    setTimeout(() => {
                                      setPercent(-1)
                                    }, 300)
                                  }
                                }
                                xhrRef.current = xhr
                              }

                              return (
                                <div className="video-item">
                                  <div className="header">
                                    <div className="name">{item.title}</div>
                                    <div className="time">
                                      {dayjs(item.create_time * 1000).format('YYYY-MM-DD HH:mm')}
                                    </div>
                                  </div>
                                  <div className="video">
                                    {videoIndex === index ? (
                                      <video
                                        autoPlay
                                        poster={urlSource(item.digital_human_url, 'video')}
                                        controls
                                        src={item.url}
                                      />
                                    ) : (
                                      <>
                                        {item.status === 2 && (
                                          <div
                                            className="play"
                                            onClick={() => {
                                              eventTracking('CreationsPlay', {
                                                url: item.url,
                                                id: item.id
                                              })
                                              setVideoIndex(index)
                                            }}
                                          >
                                            <Play />
                                          </div>
                                        )}

                                        <img src={urlSource(item.digital_human_url, 'video')} />
                                      </>
                                    )}
                                    {item.status === 1 && (
                                      <div className="mask">
                                        <div className="pending">
                                          <LoadingOutlined />
                                          <div>生成中, 请稍等</div>
                                        </div>
                                      </div>
                                    )}

                                    {item.status === 2 && item.tip && (
                                      <Tooltip placement="bottom" title={item.tip}>
                                        <span className="tips">{<InfoCircleOutlined />}</span>
                                      </Tooltip>
                                    )}

                                    {item.status === 3 && (
                                      <div className="mask">
                                        <Tooltip placement="bottom" title={item.error_message || item.tip || ''}>
                                          <span className="status error">
                                            生成失败
                                            {item.error_message && <InfoCircleOutlined />}
                                          </span>
                                        </Tooltip>
                                      </div>
                                    )}

                                    {percent > -1 && (
                                      <div className="mask">
                                        <div className="pending">
                                          <LoadingOutlined />
                                          <div>下载中 {percent}%</div>
                                        </div>
                                      </div>
                                    )}

                                    {item.mode === 1 && <div className="tag">暗黑模式</div>}
                                  </div>
                                  <div className="bottom">
                                    {item.status === 2 && (
                                      <Tooltip placement="top" title="下载">
                                        <Button
                                          className="download"
                                          icon={<DownloadOutlined />}
                                          onClick={() => downladVideo(item)}
                                        ></Button>
                                      </Tooltip>
                                    )}

                                    {item.replicable && (
                                      <Tooltip placement="top" title="再次创作">
                                        <Button
                                          className="copy"
                                          icon={<CopyOutlined />}
                                          onClick={() => reCreate(item)}
                                        ></Button>
                                      </Tooltip>
                                    )}

                                    <Tooltip placement="top" title="重命名">
                                      <Button
                                        className="copy"
                                        icon={<FormOutlined />}
                                        onClick={() => {
                                          setRenameModalOpen(true)
                                          setEditInfo(item)
                                        }}
                                      ></Button>
                                    </Tooltip>

                                    {item.status !== 1 && (
                                      <Button
                                        className="delete"
                                        icon={<DeleteOutlined />}
                                        onClick={() => deleteVideo(item.id)}
                                      >
                                        删除
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              )
                            }}
                          </Render>
                        </List.Item>
                      )}
                    </VirtualList>
                  </List>
                </div>
                <div
                  className="video-wrapper-right"
                  style={{ borderLeft: open ? '1px solid #e7e7e7' : '1px solid transparent' }}
                >
                  <div className="title">最新作品</div>

                  <div className="img-list">
                    <div ref={imgListRef}></div>
                    {videos.map((v: any, index) => (
                      <div
                        className={`img-list-item ${imgIndex === index ? 'actived' : ''}`}
                        key={v.id}
                        onClick={() => handleThumbnailClick(index)}
                      >
                        <div className="img-box">
                          <img src={urlSource(v.digital_human_url, 'video')} />
                          {v.status === 1 && (
                            <div className="mask">
                              <div className="pending">
                                <LoadingOutlined />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <div className={`empty ${open ? 'open' : 'close'}`}>
                <img src={IconCreationEmpty} />
                <div className="desc">快去生成你的作品吧</div>
              </div>
            )}
          </>
        )}
      </>

      <Modal
        open={renameModalOpen}
        title="重命名"
        okText="确定"
        cancelText="取消"
        onCancel={() => setRenameModalOpen(false)}
        onOk={sureRename}
      >
        <div style={{ padding: 20 }}>
          <Input
            style={{ height: 40 }}
            value={editInfo?.title}
            maxLength={20}
            onChange={(e) =>
              setEditInfo({
                ...editInfo,
                title: e.target.value
              })
            }
          />
        </div>
      </Modal>
    </div>
  )
})

VideoList.displayName = 'VideoList'

export default VideoList
