import './style.scss'
import { Button, Drawer, Input, message, Modal, notification, Space, Spin } from 'antd'
import { FC, useEffect, useState } from 'react'
import * as homeApi from '@/api/home'
import error1 from '@/assets/error1.png'
import error2 from '@/assets/error2.png'
import error3 from '@/assets/error3.png'
import error4 from '@/assets/error4.png'
import SystemDeclare from '@/components/Declare'
import Upload from '@/components/UploadVideo'
import { UserStore } from '@/global-states'
import { eventTracking, showDeclare } from '@/libs/util'
import { ExclamationCircleFilled } from '@ant-design/icons'

interface IProps {
  open: boolean
  onCancel?: () => void
  onOk?: () => void
  group?: any
}

const CreateInteractAvatarDrawer: FC<IProps> = (props) => {
  const { open, group, onCancel, onOk } = props
  const { declareChecked } = UserStore
  const [videoInfo, setVideoInfo] = useState<any>({})
  const [name, setName] = useState('')
  const [title, setTitle] = useState('')
  const [guideModalOpen, setGuideModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const uploadList = [
    {
      key: 'open',
      value: '请上传一段开口视频'
    },
    {
      key: 'close',
      value: '请上传一段闭口视频'
    }
  ]

  useEffect(() => {
    setGuideModalOpen(!localStorage.getItem('guideRead'))
  }, [])

  useEffect(() => {
    if (open) {
      setName('')
      setVideoInfo({
        closed_mouth_video_oss_key: undefined,
        open_mouth_video_oss_key: undefined
      })
    }
  }, [open])

  useEffect(() => {
    if (group?.id) {
      setTitle(`${group.name}-形象${(group.avatar_count || 0) + 1}`)
    }
  }, [group])

  const onGuideModalClose = () => {
    setGuideModalOpen(false)
    localStorage.setItem('guideRead', '1')
  }

  const onUploadSuccess = (key: string, video: any) => {
    if (key === 'open') {
      setVideoInfo((v: any) => {
        return {
          ...v,
          open_mouth_video_oss_key: video.oss_key
        }
      })
    }
    if (key === 'close') {
      setVideoInfo((v: any) => {
        return {
          ...v,
          closed_mouth_video_oss_key: video.oss_key
        }
      })
    }
  }

  const onRestartUpload = (key: string) => {
    if (key === 'open') {
      setVideoInfo({
        ...videoInfo,
        open_mouth_video_oss_key: undefined
      })
    }
    if (key === 'close') {
      setVideoInfo({
        ...videoInfo,
        closed_mouth_video_oss_key: undefined
      })
    }
  }

  const completeCreate = async () => {
    if (!declareChecked) {
      await showDeclare()
    }

    if (!name?.trim() && !group?.id) {
      return message.warning('请输入数字人的名字')
    }

    if (!videoInfo?.open_mouth_video_oss_key) {
      return message.warning('请上传开口视频')
    }

    if (!videoInfo?.closed_mouth_video_oss_key) {
      return message.warning('请上传闭口视频')
    }

    eventTracking('InstantCloneConfirm')
    setLoading(true)
    try {
      if (group?.id) {
        await homeApi.addDigitalAvatar(group.id, {
          avatars: [
            {
              name: title,
              closed_mouth_video_oss_key: videoInfo.closed_mouth_video_oss_key,
              open_mouth_video_oss_key: videoInfo.open_mouth_video_oss_key
            }
          ]
        })
      } else {
        await homeApi.addDigital({
          name,
          init_avatars: [
            {
              name: `${name}-形象1`,
              closed_mouth_video_oss_key: videoInfo.closed_mouth_video_oss_key,
              open_mouth_video_oss_key: videoInfo.open_mouth_video_oss_key
            }
          ]
        })
      }

      notification.success({
        message: '提交成功'
      })
      onCancel?.()
      onOk?.()
    } finally {
      setLoading(false)
    }
  }

  return (
    <Drawer
      className="create-drawer"
      open={open}
      title="创建实时互动数字人"
      width={720}
      onClose={onCancel}
      footer={
        <>
          <SystemDeclare />
          <Space>
            <Button onClick={onCancel}>取消</Button>
            <Button type="primary" loading={loading} onClick={completeCreate}>
              {loading ? '生成中' : '提交'}
            </Button>
          </Space>
        </>
      }
    >
      <Spin spinning={loading} tip={'数字人复刻中'}>
        <div className="main">
          <div className="form-item">
            <div className="title">数字人名称</div>
            <Input
              maxLength={20}
              placeholder="请输入数字人名称"
              value={group?.name || name}
              onChange={(e) => setName(e.target.value)}
              disabled={group?.id}
            />
          </div>

          {group?.id && (
            <div className="form-item">
              <div className="title">形象名称</div>
              <Input
                maxLength={20}
                placeholder="请输入形象名称"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          )}

          <div className="form-item">
            <div className="title">
              上传视频
              <ExclamationCircleFilled
                onClick={() => {
                  eventTracking('InstantCloneTips')
                  setGuideModalOpen(true)
                }}
              />
            </div>

            <div className="warning">
              <h4>视频要求</h4>
              <div className="desc">
                <div>
                  <label className="label">视频方向：</label>
                  <label>横向或纵向</label>
                </div>
                <div>
                  <label className="label">文件格式：</label>
                  <label>mp4、mov</label>
                </div>
                <div>
                  <label className="label">视频时长：</label>
                  <label>10秒~5分钟</label>
                </div>
                <div>
                  <label className="label">分辨率：</label>
                  <label>720p~1080p</label>
                </div>
                <div>
                  <label className="label">文件大小：</label>
                  <label>小于500MB</label>
                </div>
              </div>
            </div>
            {open && (
              <div className="upload-wrapper">
                {uploadList.map((l) => (
                  <div className="content" key={l.key}>
                    <Upload
                      onUploadSuccess={onUploadSuccess.bind(this, l.key)}
                      onRestartUpload={onRestartUpload.bind(this, l.key)}
                      format={'mp4、mov'}
                      size={500}
                      duration={{ min: 10, max: 300, desc: '10秒～5分钟' }}
                      dpi={{ min: 720, max: 1080, desc: '720p~1080p' }}
                      type="interact"
                      uploadContent={
                        <>
                          <p className="ant-upload-text">{l.value}</p>
                          <p className="ant-upload-hint">将文件拖到此处，或点击此区域上传</p>
                        </>
                      }
                    />
                  </div>
                ))}
              </div>
            )}
          </div>

          <Modal
            title="视频要求"
            open={guideModalOpen}
            footer={
              <Button type="primary" onClick={onGuideModalClose}>
                知道了
              </Button>
            }
            closeIcon={false}
            width={580}
            onCancel={onGuideModalClose}
          >
            <div className="modal-guide">
              <ul>
                <li>
                  <label>1.</label>不要使用有多人的视频
                </li>
                <li>
                  <label>2.</label>确保人脸不要太小，建议人脸宽度占整体画面宽度的1/4以上
                </li>
                <li>
                  <label>3.</label>人脸不要太大，确保整张人脸都在屏幕区域内，人脸不要出屏幕
                </li>
                <li>
                  <label>4.</label>确保面部特征没有被遮挡，并努力让面部清晰可见
                </li>
                <li>
                  <label>5.</label>上传的视频分辨率不应低于360p，也不应超过3840p，建议分辨率为720p或1080p
                </li>
                <li>
                  <label>6.</label>视频长度不应少于5秒，不超过30分钟
                </li>
              </ul>
              <div className="guide-error">
                <div className="title-error">错误示例</div>
                <div className="error-list">
                  <div className="item">
                    <img className="img1" src={error1} />
                    <div>多张人脸</div>
                  </div>
                  <div className="item">
                    <img className="img2" src={error2} />
                    <div>人脸太大</div>
                  </div>
                  <div className="item">
                    <img className="img3" src={error3} />
                    <div>脸部遮挡</div>
                  </div>
                  <div className="item">
                    <img className="img3" src={error4} />
                    <div>检测不到人脸</div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </Spin>
    </Drawer>
  )
}

export default CreateInteractAvatarDrawer
