import './style.scss'
import { Button, message, Modal } from 'antd'
import dayjs from 'dayjs'
import { FC, useEffect, useMemo, useState } from 'react'
import * as Api from '@/api/home'
import IconExpried from '@/assets/icon-expried.png'
import IconUsed from '@/assets/icon-used.png'
import { UserStore } from '@/global-states'
import ConvertCouponModal from '../ConvertCouponModal'
import Coupon from './'

interface IProps {
  open: boolean
  toUseCoupon: () => void
  onCancel: () => void
  changeOpen?: (t: boolean) => void
}

const CouponListModal: FC<IProps> = (props) => {
  const { open, onCancel, toUseCoupon, changeOpen } = props
  const [coupons, setCoupons] = useState<any[]>()
  const [status, setStatus] = useState(-1)
  const [couponOpen, setCouponOpen] = useState(false)
  const [couponId, setCouponId] = useState<number>()
  const [convertCouponModalOpen, setConvertCouponModalOpen] = useState(false)
  const tabs = [
    {
      id: -1,
      name: '全部'
    },
    {
      id: 2,
      name: '可使用'
    },
    {
      id: 3,
      name: '已使用'
    },
    {
      id: 4,
      name: '已过期'
    }
  ]

  const showCoupons = useMemo(() => {
    if (status === -1) {
      return coupons
    }
    return coupons?.filter((c) => c.status === status)
  }, [coupons, status])

  useEffect(() => {
    if (open) {
      getClaimedCoupons()
    }
  }, [open])

  const getClaimedCoupons = async () => {
    const res = await Api.getClaimedCoupons()
    setCoupons(res.list || [])
  }

  const getUnclaimedCoupons = async () => {
    const res = await UserStore.getUnclaimedCoupons()
    if (res?.id) {
      setCouponId(res?.id)
      setCouponOpen(true)
    } else {
      message.warning('暂无可领取代金劵')
    }
  }

  const changeStatus = (status: number) => {
    setStatus(status)
  }

  const onConvertSuccess = () => {
    setConvertCouponModalOpen(false)
    UserStore.updatePayStatus()
    Modal.confirm({
      title: '兑换成功',
      content: <div>已成功兑换代金劵</div>,
      okText: '立即使用',
      cancelText: '前往查看',
      onOk: () => {
        toUseCoupon()
      },
      onCancel: () => {
        changeOpen?.(true)
      }
    })
  }

  return (
    <>
      <Modal
        maskClosable={true}
        className="title-modal coupon-list-modal"
        onCancel={() => onCancel()}
        footer={null}
        width={700}
        open={open}
        title="我的代金劵"
      >
        <div className="coupon-tabs">
          {tabs.map((t) => (
            <div
              key={t.id}
              className={`coupon-tabs-item ${status === t.id ? 'actived' : ''}`}
              onClick={changeStatus.bind(this, t.id)}
            >
              <label className={status === t.id ? 'gradient' : ''}>{t.name}</label>
            </div>
          ))}
        </div>

        <div
          className="convert"
          onClick={() => {
            setConvertCouponModalOpen(true)
            onCancel?.()
          }}
        >
          <label className="gradient">积分兑换代金劵</label>
        </div>
        {showCoupons && (
          <div className="coupon-list">
            {showCoupons?.length ? (
              <>
                {showCoupons.map((c) => (
                  <div key={c.id} className={`coupon-list-item ${c.status !== 2 ? 'disabled' : ''}`}>
                    <div className="left">
                      <label className="gradient">{c.deduct_amount / 100}</label>
                      <label className="gradient unit">元</label>
                    </div>
                    <div className="right">
                      <div className="detail">
                        <div className="desc">{c.title}</div>
                        {c.expire_time && (
                          <div className="expried">
                            有效期至 {dayjs(c.expire_time * 1000).format('YYYY-MM-DD HH:mm')}
                          </div>
                        )}
                        <div className="rules">
                          {c.descriptions?.map((d: string, index: number) => (
                            <div key={index}>{d}</div>
                          ))}
                        </div>
                      </div>
                      {c.status === 2 && (
                        <Button
                          type="primary"
                          onClick={() => {
                            onCancel()
                            toUseCoupon()
                          }}
                        >
                          去使用
                        </Button>
                      )}
                    </div>
                    {c.status === 3 && <img className="tag" src={IconUsed} />}
                    {c.status === 4 && <img className="tag" src={IconExpried} />}
                  </div>
                ))}
              </>
            ) : (
              <div className="list-no-data">
                当前暂无{status !== -1 && tabs.find((t) => t.id === status)?.name}代金劵
                {(status === -1 || status === 2) && (
                  <label>
                    ，
                    <span className="receive gradient" onClick={getUnclaimedCoupons}>
                      领取代金劵
                    </span>
                  </label>
                )}
              </div>
            )}
          </div>
        )}
      </Modal>

      <ConvertCouponModal
        open={convertCouponModalOpen}
        onCancel={() => setConvertCouponModalOpen(false)}
        onSuccess={onConvertSuccess}
      />

      <Coupon
        open={couponOpen}
        couponId={couponId}
        onCancel={() => setCouponOpen(false)}
        onSuccess={() => {
          getClaimedCoupons()
          setCouponOpen(false)
        }}
      />
    </>
  )
}

export default CouponListModal
