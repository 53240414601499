import './style.scss'
import { Button, Checkbox, Drawer, Input, message, Modal, notification, Popconfirm, Radio, Space, Spin } from 'antd'
import dayjs from 'dayjs'
import { FC, useEffect, useRef, useState } from 'react'
import { cdnApi } from '@/api'
import * as homeApi from '@/api/home'
import error1 from '@/assets/error1.png'
import error2 from '@/assets/error2.png'
import error3 from '@/assets/error3.png'
import error4 from '@/assets/error4.png'
import { Play } from '@/assets/svg'
import SystemDeclare from '@/components/Declare'
import UploadVideo from '@/components/UploadVideo'
import VideoModal from '@/components/VideoModal'
import { UserStore } from '@/global-states'
import { demoVideos, eventTracking, showDeclare, showPlanModal, urlSource } from '@/libs/util'
import { DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons'

interface IProps {
  open: boolean
  onCancel?: () => void
  onOk?: () => void
  group?: any
}

const CreateAvatarDrawer: FC<IProps> = (props) => {
  const { open, group, onCancel, onOk } = props
  const { declareChecked } = UserStore
  const [videoUrl, setVideoUrl] = useState<string>(undefined as any)
  const [videoInfo, setVideoInfo] = useState<any>({})
  const [step, setStep] = useState(0)
  const cancelTokenSource = useRef<any>()
  const [name, setName] = useState('')
  const [title, setTitle] = useState('')
  const [guideModalOpen, setGuideModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [preview, setPreview] = useState<any>()
  const [cloneVoice, setCloneVoice] = useState(false)
  const [createOpen, setCreateOpen] = useState(false)
  const [warrantOsskey, setWarrantOsskey] = useState('')
  const [checkedWarrant, setCheckedWarrant] = useState<any>()
  const [cloneMode, setCloneMode] = useState<any>(2)
  const [warrantVideos, setWarrantVideos] = useState<any[]>([])

  useEffect(() => {
    setGuideModalOpen(!localStorage.getItem('guideRead'))
  }, [])

  useEffect(() => {
    if (open) {
      setName('')
      restartUpload()
      getOpenConfig()
    }
  }, [open])

  useEffect(() => {
    if (group?.id) {
      setTitle(`${group.title}-形象${(group.member_count || 0) + 1}`)
    }
  }, [group])

  useEffect(() => {
    if (cloneMode === 1) {
      getHistoryWarrantVideos()
    }
  }, [cloneMode])

  const getOpenConfig = async () => {
    const res = await cdnApi.get(`hiflys/create-avatar/config.json?v=${Date.now()}`)
    if (res.open === true) {
      setCreateOpen(true)
    }
  }

  const getHistoryWarrantVideos = async () => {
    const res = await homeApi.getHistoryWarrantVideos({
      page: 0,
      size: 100
    })
    setWarrantVideos(res?.list || [])
  }

  const onGuideModalClose = () => {
    setGuideModalOpen(false)
    localStorage.setItem('guideRead', '1')
  }

  const restartUpload = () => {
    if (videoInfo?.recommend) {
      UserStore.declareChecked = false
    }
    cancelUpload()
    setVideoUrl('')
    setWarrantOsskey('')
    setCheckedWarrant(undefined)
    setVideoInfo(undefined)
  }

  const cancelUpload = () => {
    if (cancelTokenSource) {
      cancelTokenSource.current?.cancel('取消上传')
    }
  }

  const selectDemoVideo = (v: any) => {
    UserStore.declareChecked = true
    setVideoUrl(urlSource(v.url))
    setStep(2)
    setVideoInfo({
      oss_key: v.ossKey,
      recommend: true
    })
  }

  const completeCreate = async () => {
    if (!name?.trim() && !group?.id) {
      return message.warning('请输入数字人的名字')
    }

    if (!videoInfo?.oss_key) {
      return message.warning('请上传视频')
    }

    if (cloneMode === 1 && createOpen && !videoUrl) {
      if (!(warrantOsskey || checkedWarrant?.id)) {
        return message.warning('请上传授权视频')
      }
    }

    if (!declareChecked) {
      await showDeclare()
    }

    eventTracking('InstantCloneConfirm')
    setLoading(true)
    try {
      const params: any = {
        oss_key: videoInfo?.oss_key,
        with_group: {
          new_group_title: name
        },
        source_type: 1,
        also_clone_voice: cloneVoice,
        clone_mode: createOpen && !videoUrl ? cloneMode : undefined
      }

      if (checkedWarrant?.id) {
        params.warrant_video_id = checkedWarrant.id
      } else {
        params.warrant_voice_oss_key = warrantOsskey
      }

      if (group?.id) {
        params.title = title
        params.with_group = {
          group_id: group.id
        }
      }
      await homeApi.addDigitalHumans(params)
      if (createOpen && cloneMode === 1 && !videoUrl) {
        notification.success({
          message: '审核中，请稍等',
          description: '我们会尽快完成审核，审核通过后，您可开始使用，请您耐心等待！'
        })
      } else {
        notification.success({
          message: '数字人复刻完成',
          description: '快使用你的数字人去创建视频吧！'
        })
      }
      onCancel?.()
      onOk?.()
    } catch (error: any) {
      if (error.code === 1014) {
        eventTracking('AvatarCloneLimit')
        Modal.confirm({
          title: '数字人复刻到达数量上限',
          content: <div>免费用户数字人复刻的创建次数上限为5，成为会员后解锁更多创建次数</div>,
          okText: '开通会员',
          cancelText: '取消',
          onOk: () => {
            showPlanModal()
          }
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Drawer
      className="create-drawer create-avatar-drawer"
      open={open}
      title="视频生成数字人"
      width={600}
      onClose={onCancel}
      footer={
        <>
          <SystemDeclare />
          <Space>
            <div className="fee">
              <div className="score">
                <label className="light">限时免费</label>
              </div>
            </div>
            <Button onClick={onCancel}>取消</Button>
            <Button type="primary" loading={loading} onClick={completeCreate}>
              {loading ? '复刻中' : '提交'}
            </Button>
          </Space>
        </>
      }
    >
      <Spin spinning={loading} tip={'数字人复刻中'}>
        <div className="main">
          <div className="form-item">
            <div className="title">数字人名称</div>
            <Input
              maxLength={20}
              placeholder="请输入数字人名称"
              value={group?.title || name}
              onChange={(e) => setName(e.target.value)}
              disabled={group?.id}
            />
          </div>

          {group?.id && (
            <div className="form-item">
              <div className="title">形象名称</div>
              <Input
                maxLength={20}
                placeholder="请输入形象名称"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          )}

          <div className="form-item">
            <div className="title">
              上传视频
              <ExclamationCircleFilled
                onClick={() => {
                  eventTracking('InstantCloneTips')
                  setGuideModalOpen(true)
                }}
              />
            </div>
            {step === 0 && (
              <>
                <div className="warning">
                  <h4>视频要求</h4>
                  <div className="desc">
                    <div>
                      <label className="label">视频方向：</label>
                      <label>横向或纵向</label>
                    </div>
                    <div>
                      <label className="label">文件格式：</label>
                      <label>mp4、mov</label>
                    </div>
                    <div>
                      <label className="label">视频时长：</label>
                      <label>5秒~30分钟</label>
                    </div>
                    <div>
                      <label className="label">分辨率：</label>
                      <label>360p~4K</label>
                    </div>
                    <div>
                      <label className="label">文件大小：</label>
                      <label>小于500MB</label>
                    </div>
                  </div>
                </div>
              </>
            )}

            {!videoUrl && open && (
              <div className="content">
                <UploadVideo
                  uploadContent={
                    <>
                      <p className="ant-upload-text">请上传一段视频，作为驱动数字人的底版视频</p>
                      <p className="ant-upload-hint">将文件拖到此处，或点击此区域上传</p>
                    </>
                  }
                  onUploadSuccess={(video: any) => {
                    setVideoInfo({
                      oss_key: video.oss_key
                    })
                  }}
                  upateStep={(s) => setStep(s)}
                  onRestartUpload={restartUpload}
                  format={'mp4、mov'}
                  size={500}
                  duration={{ min: 5, max: 1800, desc: '5秒～30分钟' }}
                  dpi={{ min: 360, max: 4096, desc: '360p~4K' }}
                />
              </div>
            )}

            {videoInfo?.oss_key && (
              <div className="step-view">
                {videoUrl && (
                  <div className="step-view-box video">
                    <video controls src={videoUrl} />
                    <div className="trash" onClick={restartUpload}>
                      <DeleteOutlined />
                    </div>
                  </div>
                )}
                <div className="clone-voice">
                  <Checkbox
                    checked={cloneVoice}
                    onChange={(e) => {
                      setCloneVoice(e.target.checked)
                    }}
                  >
                    同时克隆视频中的人声<label className="gray">（人声质量较差时可能会克隆失败）</label>
                  </Checkbox>
                </div>
              </div>
            )}

            {videoInfo?.oss_key && createOpen && !videoUrl && (
              <div className="form-item clone-mode">
                <div className="title">
                  选择克隆模式
                  <Popconfirm
                    title=""
                    description={
                      <div className="popconfirm-list" style={{ width: 620 }}>
                        <div>
                          <strong>本人1:1复刻：</strong>
                          为了防止滥用技术，我们需要您提供授权视频，证明是您本人在复刻使用；
                        </div>
                        <div>
                          <strong>相似克隆：</strong>
                          保留您的核心风格，但会加入一些创意变化，生成一个既像您又稍有不同的版本；
                        </div>
                      </div>
                    }
                    okText="我知道了"
                    showCancel={false}
                    icon={null}
                  >
                    <ExclamationCircleFilled />
                  </Popconfirm>
                </div>
                <Radio.Group onChange={(e) => setCloneMode(e.target.value)} value={cloneMode}>
                  <Radio value={2}>相似克隆</Radio>
                  <Radio value={1}>本人1:1复刻</Radio>
                </Radio.Group>

                {cloneMode === 1 && (
                  <>
                    {checkedWarrant?.id ? (
                      <div className="step-view-box video">
                        <video controls src={checkedWarrant.url} />
                        <div
                          className="trash"
                          onClick={() => {
                            setWarrantOsskey('')
                            setCheckedWarrant(undefined)
                          }}
                        >
                          <DeleteOutlined />
                        </div>
                      </div>
                    ) : (
                      <div className="content">
                        <UploadVideo
                          uploadContent={
                            <>
                              <p className="ant-upload-text">请按照以下文本录制授权视频并上传</p>
                              <p className="ant-upload-hint" style={{ fontSize: '13px' }}>
                                我
                                <strong className="red" style={{ padding: '0 2px' }}>
                                  姓名
                                </strong>
                                特此声明，授权飞影使用我的视频来创作飞影数字人，并在我的飞影账号上创作
                              </p>
                            </>
                          }
                          onUploadSuccess={(video: any) => {
                            setWarrantOsskey(video.oss_key)
                          }}
                          onRestartUpload={() => {
                            setWarrantOsskey('')
                            setCheckedWarrant(undefined)
                          }}
                          format={'mp4、mov、avi、flv、webm、m4v、vob'}
                          size={500}
                        />
                      </div>
                    )}

                    {!!warrantVideos?.length && (
                      <div className="history-video">
                        <div className="title">使用过往授权视频</div>

                        <div className="warrant-list">
                          {warrantVideos.map((v: any) => (
                            <div className="warrant-list__item" key={v.id}>
                              <div className="box">
                                <img src={urlSource(v.url, 'video')} />
                                <div className="select">
                                  <Button
                                    type="primary"
                                    className="btn"
                                    onClick={() => {
                                      setCheckedWarrant(v)
                                    }}
                                  >
                                    选用
                                  </Button>
                                </div>
                              </div>
                              <div className="time">{dayjs(v.create_time * 1000).format('YYYY-MM-DD HH:mm')}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}

            {step === 0 && (
              <div className="video-demo">
                <div className="title">
                  示例视频<span className="yellow">暂时没有视频素材？使用我们的示例素材来感受效果吧！</span>
                </div>
                <div className="demo-list">
                  {demoVideos.map((v) => (
                    <div className="list-item" key={v.url}>
                      <div className="video" onClick={() => setPreview({ url: urlSource(v.url), title: '示例视频' })}>
                        <div
                          className="bg"
                          style={{
                            backgroundImage: `url(${urlSource(v.url, 'video')})`
                          }}
                        ></div>
                        <img src={urlSource(v.url, 'video')} />
                        <div className="play">
                          <Play />
                        </div>
                      </div>
                      <div className="bottom">
                        <Button
                          type="primary"
                          onClick={() => {
                            selectDemoVideo(v)
                          }}
                        >
                          选用
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <Modal
            title="视频要求"
            open={guideModalOpen}
            footer={
              <Button type="primary" onClick={onGuideModalClose}>
                知道了
              </Button>
            }
            closeIcon={false}
            width={580}
            onCancel={onGuideModalClose}
          >
            <div className="modal-guide">
              <ul>
                <li>
                  <label>1.</label>不要使用有多人的视频
                </li>
                <li>
                  <label>2.</label>确保人脸不要太小，建议人脸宽度占整体画面宽度的1/4以上
                </li>
                <li>
                  <label>3.</label>人脸不要太大，确保整张人脸都在屏幕区域内，人脸不要出屏幕
                </li>
                <li>
                  <label>4.</label>确保面部特征没有被遮挡，并努力让面部清晰可见
                </li>
                <li>
                  <label>5.</label>上传的视频分辨率不应低于360p，也不应超过3840p，建议分辨率为720p或1080p
                </li>
                <li>
                  <label>6.</label>视频长度不应少于5秒，不超过30分钟
                </li>
              </ul>
              <div className="guide-error">
                <div className="title-error">错误示例</div>
                <div className="error-list">
                  <div className="item">
                    <img className="img1" src={error1} />
                    <div>多张人脸</div>
                  </div>
                  <div className="item">
                    <img className="img2" src={error2} />
                    <div>人脸太大</div>
                  </div>
                  <div className="item">
                    <img className="img3" src={error3} />
                    <div>脸部遮挡</div>
                  </div>
                  <div className="item">
                    <img className="img3" src={error4} />
                    <div>检测不到人脸</div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </Spin>

      <VideoModal preview={preview} onCancel={() => setPreview(undefined)} />
    </Drawer>
  )
}

export default CreateAvatarDrawer
