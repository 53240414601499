import './style.scss'
import { FC, memo, useMemo } from 'react'
import PlanProduct from '@/components/PlanModal/product'
import { UserStore } from '@/global-states'

const NewUserPackage: FC = () => {
  const { userPackage } = UserStore

  const vip = useMemo(() => {
    return !!userPackage?.membership_expire_time
  }, [userPackage])

  if (vip) return null

  return (
    <div className="setting-newuser-package">
      <PlanProduct />
    </div>
  )
}

export default memo(NewUserPackage)
