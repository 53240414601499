import './style.scss'
import { message, Modal } from 'antd'
import React from 'react'
import LogoTitle from '@/assets/logo-title.webp'
import Logo from '@/assets/logo.webp'
import MobieLogin from '@/views/login/components/mobie-login'

interface IProps {
  open: boolean
  onCancel?: () => void
}

const LoginModal: React.FC<IProps> = (props) => {
  const { open, onCancel } = props

  const onLoginSuccess = async () => {
    message.success('登陆成功')
    onCancel?.()
  }

  return (
    <>
      <Modal zIndex={99999} className="login-modal" width={500} open={open} onCancel={onCancel} footer={null}>
        <div className="login-top">
          <img className="logo" src={Logo} />
          <img className="logo-title" src={LogoTitle} />
        </div>
        <MobieLogin onLoginSuccess={onLoginSuccess} />
      </Modal>
    </>
  )
}

export default LoginModal
