import './style.scss'
import { Dropdown, Input, message, Modal } from 'antd'
import { Buffer } from 'buffer'
import { FC, memo, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import wavConverter from 'wav-converter'
import * as homeApi from '@/api/home'
import { Ellipsis } from '@/assets/svg'
import { PlayGray } from '@/assets/svg/play-gray'
import CheckLogin from '@/components/CheckLogin'
import { UserStore } from '@/global-states'
import { eventTracking } from '@/libs/util'
import { DeleteOutlined, EditOutlined, LoadingOutlined, PauseOutlined, VideoCameraAddOutlined } from '@ant-design/icons'

interface IProps {
  list: any[]
  onItemClick: (t: any) => void
  onUpdate?: () => void
  upgradePlan?: () => void
}

const VoiceBox: FC<IProps> = (props) => {
  const { list, onUpdate, onItemClick, upgradePlan } = props
  const navigate = useNavigate()
  const { userPackage } = UserStore
  const audioRef = useRef<any>()
  const [previewId, setPreviewId] = useState()
  const [playingId, setPlayingId] = useState()
  const [renameModalOpen, setRenameModalOpen] = useState(false)
  const [editVoice, setEditVoice] = useState<any>()
  const [openId, setOpenId] = useState<any>()

  useEffect(() => {
    return () => closeAudio()
  }, [])

  const previewTts = async (tts: any, text: string, e: any) => {
    e.stopPropagation()

    if (playingId === tts.id) {
      closeAudio()
      return
    } else if (previewId === tts.id) {
      return
    }

    setPreviewId(tts.id)
    setPlayingId(undefined)
    eventTracking('PreviewTts', {
      tts_id: tts?.id
    })

    try {
      const res = await homeApi.previewTts(tts.id, { text, voice_parameters: tts.voice_parameters })
      setPlayingId(tts.id)

      if (audioRef.current) {
        audioRef.current.pause?.()
        audioRef.current.src = ''
      }

      const audio = new Audio()
      audio.src = `data:audio/wav;base64,${wavConverter
        .encodeWav(new Buffer(res.audio_base64, 'base64'), {
          numChannels: 1,
          sampleRate: 16000,
          byteRate: 32_000
        })
        .toString('base64')}`
      audio.play()
      audioRef.current = audio

      audio.addEventListener('ended', function () {
        closeAudio()
      })

      audio.addEventListener('pause', function () {
        closeAudio()
      })
    } catch {
      closeAudio()
    }
  }

  const closeAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause?.()
      audioRef.current.src = ''
    }
    setPlayingId(undefined)
    setPreviewId(undefined)
  }

  const toCreateVideo = async (t: any, e: any) => {
    e.stopPropagation()
    const res = await homeApi.getVoicGroupDetail(t.id)
    const l = res?.list?.find((l: any) => l.status === 1)
    if (!l) {
      message.warning('当前声音暂无可使用风格！')
    } else {
      if (!vipTip(l)) {
        localStorage.setItem('voiceId', l.id)
        eventTracking('CreatVoiceGroupClick')
        navigate(`/create-video`)
      }
    }
  }

  const vipTip = (voice: any) => {
    if (userPackage && (userPackage.current_membership_level || 0) < 20 && voice.level === 20) {
      eventTracking('HighvoiceCloneLimit')
      return Modal.confirm({
        title: '当前会员等级不够，无法使用',
        content: <div>高保真声音是尊享版及以上会员专属功能，请您确认当前会员等级是否匹配</div>,
        okText: '升级会员',
        cancelText: '取消',
        onOk: () => {
          upgradePlan?.()
        }
      })
    }
    return false
  }

  const delVoice = async (id: number, e: any) => {
    e.stopPropagation()
    setOpenId(undefined)
    Modal.confirm({
      title: '删除声音',
      content: <div className="red">声音删除时，会同时删除其关联的风格声音，且不可恢复。确认删除？</div>,
      onOk: async () => {
        await homeApi.deleteVoiceGroup(id)
        message.success('删除成功')
        onUpdate?.()
      }
    })
  }

  const renameVoice = (v: any, e: any) => {
    e.stopPropagation()
    setOpenId(undefined)
    setEditVoice(v)
    setRenameModalOpen(true)
  }

  const sureRenameVoice = async () => {
    await homeApi.updateVoiceGroup(editVoice.id, {
      title: editVoice.title
    })
    message.success('修改成功')
    setRenameModalOpen(false)
    onUpdate?.()
  }

  return (
    <>
      {list?.map((t) => (
        <div className="voice-box" key={t.id}>
          <CheckLogin>
            <div className={`box-audio ${openId === t.id ? 'hovered' : ''}`} onClick={() => onItemClick(t)}>
              <div className="left">
                <img src={t.cover_url} />
              </div>
              <div className="right">
                <div className="name ellipsis">{t.title}</div>

                <div className="op" onClick={(e) => e.stopPropagation()}>
                  <Dropdown
                    menu={{
                      items: t.global
                        ? [
                            {
                              key: '0',
                              label: (
                                <div className="dropdown-list" onClick={toCreateVideo.bind(this, t)}>
                                  <VideoCameraAddOutlined />
                                  去创作
                                </div>
                              )
                            }
                          ]
                        : [
                            {
                              key: '0',
                              label: (
                                <div className="dropdown-list" onClick={toCreateVideo.bind(this, t)}>
                                  <VideoCameraAddOutlined />
                                  去创作
                                </div>
                              )
                            },
                            {
                              key: '1',
                              label: (
                                <div className="dropdown-list" onClick={renameVoice.bind(this, t)}>
                                  <EditOutlined />
                                  重命名
                                </div>
                              )
                            },
                            {
                              key: '2',
                              label: (
                                <div className="dropdown-list" onClick={delVoice.bind(this, t.id)}>
                                  <DeleteOutlined />
                                  删除
                                </div>
                              )
                            }
                          ]
                    }}
                    open={openId === t.id}
                    onOpenChange={(open: boolean) => setOpenId(open ? t.id : undefined)}
                    placement="bottom"
                  >
                    <Ellipsis onClick={(e: any) => e.stopPropagation()} />
                  </Dropdown>
                </div>

                <div className="style-list">
                  {t.members?.map((m: any) => (
                    <div className={`style-item ${m.tts_level === 20 ? 'high' : ''}`} key={m.id}>
                      <div
                        className="item"
                        onClick={previewTts.bind(
                          this,
                          m,
                          m.preview_text || '现在的一切都是为将来的梦想编织翅膀，让梦想在现实中展翅高飞。'
                        )}
                      >
                        {previewId === m.id ? (
                          playingId === m.id ? (
                            <PauseOutlined />
                          ) : (
                            <LoadingOutlined />
                          )
                        ) : (
                          <PlayGray className="play" />
                        )}
                        <label>{m.title}</label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </CheckLogin>
        </div>
      ))}

      <Modal
        open={renameModalOpen}
        title="重命名"
        okText="确定"
        cancelText="取消"
        onCancel={() => setRenameModalOpen(false)}
        onOk={sureRenameVoice}
      >
        <div style={{ padding: 20 }}>
          <Input
            style={{ height: 40 }}
            maxLength={20}
            value={editVoice?.title}
            onChange={(e) =>
              setEditVoice({
                ...editVoice,
                title: e.target.value
              })
            }
          />
        </div>
      </Modal>
    </>
  )
}

export default memo(VoiceBox)
