/* eslint-disable react/jsx-no-undef */
import './style.scss'
import { Button, Modal } from 'antd'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import * as Api from '@/api/home'
import ArrowLeft from '@/assets/arrow-left.png'
import Empty from '@/assets/empty.png'
import { CreateVideo, Play } from '@/assets/svg'
import VideoModal from '@/components/VideoModal'
import { eventTracking, urlSource } from '@/libs/util'
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons'

interface IProps {
  selectAvatar: any
  open: boolean
  onCancel: () => void
  onSelect: (avatar: any) => void
}

const AvatarModal: FC<IProps> = (props) => {
  const { open, onCancel, onSelect, selectAvatar } = props
  const tabs = ['我的数字人', '公共数字人', '我的收藏']
  const [tabIndex, setTabIndex] = useState<number>(0)
  const [preview, setPreview] = useState<any>()
  const [openId, setOpenId] = useState<any>()
  const [data, setData] = useState<any[]>()
  const [globalData, setGlobalData] = useState<any[]>([])
  const [group, setGroup] = useState<any>()
  const [groupHumans, setGroupHumans] = useState<any[]>([])
  const [bookGroupHumans, setBookGroupHumans] = useState<any[]>([])
  const timeRef = useRef<any>()

  const showData = useMemo(() => {
    if (tabIndex === 0) {
      return data
    }
    if (tabIndex === 1) {
      return globalData
    }

    if (tabIndex === 2) {
      return bookGroupHumans
    }
  }, [tabIndex, globalData, data])

  useEffect(() => {
    if (open) {
      getData()
      getBookGroupHumans()
    } else {
      setGroup(undefined)
    }
  }, [open])

  useEffect(() => {
    if (group?.id) {
      getGroupHumans()
      loopGroupHumans()
    }
  }, [group])

  const getData = async () => {
    const [groups, globalGroups] = await Promise.all([Api.getDigitalHumanGroups(), Api.getGlobalDigitalHumanGroups()])
    setData((groups.list || []).filter((g: any) => g.member_count > 0))
    setGlobalData(globalGroups.list || [])
  }

  const getGroupHumans = async () => {
    const res = await Api.getDigitalHumansByGroup(group.id as number)
    setGroupHumans(res.list || [])
    if (!(res?.list || []).filter((d: any) => d.status === 1)?.length) {
      clearEvent()
    }
  }

  const getBookGroupHumans = async () => {
    try {
      const res = await Api.getBookmarkedCommunityHumans()
      setBookGroupHumans(
        (res?.list || []).map((l: any) => {
          return {
            cover_url: l.merchandise.id > 349 ? l.merchandise.covers[0] : l.merchandise.images[0],
            id: l.group_id,
            member_count: l.merchandise.videos.length || 0,
            title: l.merchandise.name
          }
        })
      )
    } catch {
      setBookGroupHumans([])
    }
  }

  const loopGroupHumans = () => {
    clearEvent()
    timeRef.current = setInterval(() => {
      getGroupHumans()
    }, 5000)
  }

  const clearEvent = () => {
    if (timeRef.current) {
      clearInterval(timeRef.current)
      timeRef.current = undefined
    }
  }

  const onTabChange = (index: number) => {
    if (index === 0) {
      eventTracking('MyInteractiveAvatar')
    }
    setTabIndex(index)
    setGroup(undefined)
  }

  const previeGroup = (g: any) => {
    setGroup(g)
  }

  const previewVideo = (d: any) => {
    setOpenId(undefined)
    setPreview({
      url: d.video_url,
      title: d.name
    })
  }

  const onBackGroups = () => {
    clearEvent()
    setGroup(undefined)
    setGroupHumans([])
    getData()
  }

  const onSelectAvatar = (avatar: any) => {
    onSelect(avatar)
    onCancel()
  }

  return (
    <Modal className="tab-avatar-modal" open={open} footer={null} width={1020} onCancel={onCancel}>
      <div className="tab-modal-content">
        <div className="tabs">
          {tabs.map((t, index) => (
            <div
              className={`tabs-item ${tabIndex === index ? 'actived' : ''}`}
              key={t}
              onClick={onTabChange.bind(this, index)}
            >
              <span className={tabIndex === index ? 'gradient' : ''}>{t}</span>
            </div>
          ))}
        </div>

        <div className="main">
          <div className="main-wrappper">
            {!group?.id ? (
              <>
                {showData && (
                  <>
                    {showData.length ? (
                      <div className="page-container">
                        {showData?.map((d) => (
                          <div className={`list-box ${d.id === selectAvatar?.group_id ? 'actived' : ''}`} key={d.id}>
                            <div className="box-main" onClick={() => previeGroup(d)}>
                              <div
                                className="bg"
                                style={{
                                  backgroundImage: `url(${d.cover_url})`
                                }}
                              ></div>

                              <div className="photo">
                                <img src={d.cover_url} />
                              </div>
                            </div>
                            <div className="bottom">
                              <div className="box-title">{d.title}</div>
                              <div className="num">{d.member_count || 0}个形象</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="data-empty">
                        <img className="empty" src={Empty} />
                        <p>
                          暂无任何数字人，
                          {tabIndex === 0 ? (
                            <label onClick={() => window.open('/')}>去复刻</label>
                          ) : (
                            <label onClick={() => window.open('/market/digital')}>去数字人市场</label>
                          )}
                        </p>
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <div className="common-title">
                  <img src={ArrowLeft} onClick={onBackGroups} className="arrow-left" />
                  <div className="text">{group.title}</div>
                </div>
                <div className="page-container">
                  {groupHumans.map((d) => (
                    <div className={`list-box ${openId === d.id ? 'hovered' : ''} `} key={d.id}>
                      <div className="box-main" onClick={() => d.status === 2 && previewVideo(d)}>
                        <div
                          className="bg"
                          style={{
                            backgroundImage: `url(${
                              d.source_type === 3 || (d.source_type === 2 && d.status !== 2)
                                ? d.video_url
                                : urlSource(d.video_url, 'video')
                            })`
                          }}
                        ></div>

                        {d.status === 2 && (
                          <div className="play">
                            <Play />
                          </div>
                        )}

                        {d.status === 1 && (
                          <div className="mask">
                            <div className="pending">
                              <LoadingOutlined />
                              <div>生成中, 请稍等</div>
                            </div>
                          </div>
                        )}

                        {d.status === 3 && (
                          <div className="status error">
                            <span>生成失败</span>
                          </div>
                        )}

                        {d.id === selectAvatar?.id && (
                          <div className="checked">
                            <CheckOutlined />
                          </div>
                        )}

                        <div className="photo">
                          <img
                            src={
                              d.source_type === 3 || (d.source_type === 2 && d.status !== 2)
                                ? d.video_url
                                : urlSource(d.video_url, 'video')
                            }
                          />
                        </div>
                      </div>

                      <div className="bottom">
                        <div className="box-title">{d.title}</div>
                        {d.status === 2 && (
                          <Button type="primary" className="btn" onClick={onSelectAvatar.bind(this, d)}>
                            选用
                            <CreateVideo />
                          </Button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <VideoModal preview={preview} onCancel={() => setPreview(undefined)} btnShow={false} />
    </Modal>
  )
}

export default AvatarModal
