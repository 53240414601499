import './style.scss'
import { Button, Dropdown, Input, message, Modal } from 'antd'
import { Buffer } from 'buffer'
import { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useNavigate } from 'react-router-dom'
import wavConverter from 'wav-converter'
import * as homeApi from '@/api/home'
import ArrowLeft from '@/assets/arrow-left.png'
import { Ellipsis } from '@/assets/svg'
import { PlayFill } from '@/assets/svg/play-fill'
import { UserStore } from '@/global-states'
import { eventTracking } from '@/libs/util'
import {
  CopyOutlined,
  DeleteOutlined,
  DeliveredProcedureOutlined,
  EditOutlined,
  LoadingOutlined,
  PauseOutlined,
  PlusCircleFilled,
  VideoCameraAddOutlined
} from '@ant-design/icons'
import VoiceGroups from '../VoiceGroups'

interface IProps {
  group: any
  onAdd?: () => void
  onBack: () => void
  upgradePlan?: () => void
}

const VoiceDetail = forwardRef((props: IProps, ref) => {
  const navigate = useNavigate()
  const { group, onBack, upgradePlan, onAdd } = props
  const { userPackage } = UserStore
  const [voiceList, setVoiceList] = useState<any[]>()
  const [previewId, setPreviewId] = useState()
  const [playingId, setPlayingId] = useState()
  const previewIdRef = useRef<any>()
  const [renameModalOpen, setRenameModalOpen] = useState(false)
  const [openId, setOpenId] = useState<any>()
  const [editVoice, setEditVoice] = useState<any>()
  const [voiceId, setVoiceId] = useState<any>()
  const audioRef = useRef<any>()
  const timerRef = useRef<any>()

  useEffect(() => {
    if (group?.id) {
      getDetail()
      timerRef.current = setInterval(() => {
        getDetail()
      }, 5000)
    }
    return () => {
      closeAudio()
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }
    }
  }, [group])

  useEffect(() => {
    previewIdRef.current = previewId
  }, [previewId])

  useImperativeHandle(ref, () => ({
    getDetail
  }))

  const getDetail = async () => {
    let res
    if (group.book) {
      res = await homeApi.getCommunityVoiceList(group.id)
    } else {
      res = await homeApi.getVoicGroupDetail(group.id)
    }

    setVoiceList([...(res?.list || [])])
  }

  const previewTts = async (tts: any, text: string) => {
    if (playingId === tts.id) {
      closeAudio()
      return
    } else if (previewId === tts.id) {
      return
    }

    setPreviewId(tts.id)
    setPlayingId(undefined)

    try {
      let res
      if (group.book) {
        res = await homeApi.previewCommunityVoice(group.id, tts.id, { text, voice_parameters: tts.voice_parameters })
      } else {
        res = await homeApi.previewTts(tts.id, { text, voice_parameters: tts.voice_parameters })
      }
      if (previewIdRef.current !== tts.id) {
        return
      }
      setPlayingId(tts.id)

      eventTracking('PreviewTts', {
        tts_id: tts?.id
      })

      if (audioRef.current) {
        audioRef.current.pause?.()
        audioRef.current.src = ''
      }

      const audio = new Audio()
      audio.src = `data:audio/wav;base64,${wavConverter
        .encodeWav(new Buffer(res.audio_base64, 'base64'), {
          numChannels: 1,
          sampleRate: 16000,
          byteRate: 32_000
        })
        .toString('base64')}`
      audio.play()
      audioRef.current = audio

      audio.addEventListener('ended', function () {
        closeAudio()
      })

      audio.addEventListener('pause', function () {
        closeAudio()
      })
    } catch {
      closeAudio()
    }
  }

  const closeAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause?.()
      audioRef.current.src = ''
    }
    setPlayingId(undefined)
    setPreviewId(undefined)
  }

  const delVoice = async (id: number, e: any) => {
    e.stopPropagation()
    setOpenId(undefined)
    await homeApi.deleteVoice(id)
    message.success('删除成功')
    getDetail()
  }

  const renameVoice = (v: any, e: any) => {
    e.stopPropagation()
    setOpenId(undefined)
    setEditVoice(v)
    setRenameModalOpen(true)
  }

  const sureRenameVoice = async () => {
    if (group.title.includes(editVoice.display_name)) {
      return message.warning('为你的声音风格起一个更贴切的名字吧，比如：高兴、悲伤、情感、激动...')
    }
    await homeApi.updateVoice(editVoice.id, {
      voice_name: editVoice.display_name
    })
    message.success('修改成功')
    setRenameModalOpen(false)
    getDetail()
  }

  const moveGroup = (id: number, e: any) => {
    e.stopPropagation()
    setOpenId(undefined)
    setVoiceId(id)
  }

  const toCreateVideo = (t: any, e: any) => {
    e.stopPropagation()
    setOpenId(undefined)
    if (userPackage && (userPackage.current_membership_level || 0) < 20 && t.level === 20) {
      return vipTip()
    }
    localStorage.setItem('voiceId', t.id)
    if (group?.book) {
      sessionStorage.setItem('bookId', group.id)
    }
    eventTracking('CreatVoiceClick')
    navigate(`/create-video`)
  }

  const vipTip = () => {
    eventTracking('HighvoiceCloneLimit')
    Modal.confirm({
      title: '当前会员等级不够，无法使用',
      content: <div>高保真声音是尊享版及以上会员专属功能，请您确认当前会员等级是否匹配</div>,
      okText: '升级会员',
      cancelText: '取消',
      onOk: () => {
        upgradePlan?.()
      }
    })
  }

  const cancelCollectVoice = async () => {
    await homeApi.delteBookmarkedCommunityVoice(group.id)
    onBack()
  }

  return (
    <>
      <div className="common-header">
        <img src={ArrowLeft} onClick={onBack} className="arrow-left" />
        <div className="text">{group.title}</div>
      </div>

      <div className="common-wrappeer">
        {' '}
        <div className="group-detail-wrapper">
          <div className="header">
            <div className="header-left">
              <div className="img">
                <img src={group.cover_url} />
              </div>
              <div className="detail">
                <div className="name">{group.title}</div>
                <div className="desc">{voiceList && <>{voiceList?.length || 0}个风格</>}</div>
              </div>
            </div>
            {group?.book && (
              <div className="header-right">
                <Button onClick={cancelCollectVoice}>取消收藏</Button>
              </div>
            )}
          </div>
          <div className="list">
            <div className="list-main">
              {!group.global && !group.book && (
                <div className="box-blank" onClick={onAdd}>
                  <PlusCircleFilled />
                  <label>添加更多风格</label>
                </div>
              )}

              {voiceList?.map((t) => (
                <div className={`box ${t.status === 1 ? '' : 'status'} ${openId === t.id ? 'hovered' : ''}`} key={t.id}>
                  <div className="box-audio">
                    <div
                      className="left"
                      onClick={() => {
                        if (userPackage && (userPackage.current_membership_level || 0) < 20 && t.level === 20) {
                          return vipTip()
                        }
                        previewTts(t, t.preview_text || '现在的一切都是为将来的梦想编织翅膀，让梦想在现实中展翅高飞。')
                      }}
                    >
                      {previewId === t.id ? (
                        playingId === t.id ? (
                          <PauseOutlined />
                        ) : (
                          <LoadingOutlined />
                        )
                      ) : (
                        <PlayFill className="play" />
                      )}
                    </div>
                    <div className="center">
                      <div className="name ellipsis">{t.display_name}</div>
                    </div>

                    {t.is_premium}

                    <div className="right">
                      {t.is_premium && (
                        <span className="premium">
                          <label className="gradient">精品</label>
                        </span>
                      )}
                      {t.level === 20 && (
                        <span className="high">
                          <label className="yellow-gradient">高保真</label>
                        </span>
                      )}
                    </div>
                  </div>

                  {(t.status === 1 || t.status === 3) && (
                    <div className="ellip">
                      <Dropdown
                        menu={{
                          items: [
                            {
                              key: '0',
                              label: (
                                <div className="dropdown-list" onClick={toCreateVideo.bind(this, t)}>
                                  <VideoCameraAddOutlined />
                                  去创作
                                </div>
                              )
                            },
                            {
                              key: '1',
                              label: (
                                <div className="dropdown-list" onClick={renameVoice.bind(this, t)}>
                                  <EditOutlined />
                                  重命名
                                </div>
                              )
                            },
                            {
                              key: '2',
                              label: (
                                <div className="dropdown-list" onClick={moveGroup.bind(this, t.id)}>
                                  <DeliveredProcedureOutlined />
                                  移动到
                                </div>
                              )
                            },
                            {
                              key: '3',
                              label: (
                                <div className="dropdown-list" onClick={delVoice.bind(this, t.id)}>
                                  <DeleteOutlined />
                                  删除
                                </div>
                              )
                            },
                            {
                              key: '4',
                              label: (
                                <CopyToClipboard
                                  text={t?.name || ''}
                                  onCopy={() => {
                                    eventTracking('CopyVoiceId', {
                                      name: t?.name || ''
                                    })
                                    message.success('已复制声音id')
                                  }}
                                >
                                  <div className="dropdown-list">
                                    <CopyOutlined />
                                    复制声音id
                                  </div>
                                </CopyToClipboard>
                              )
                            }
                          ].filter((l) => {
                            if (t.level === 20 && l.key === '3') {
                              return false
                            }
                            if ((group.global || group.book) && !['0', '4'].includes(l.key)) {
                              return false
                            }
                            return true
                          })
                        }}
                        placement="bottom"
                        open={openId === t.id}
                        onOpenChange={(open: boolean) => {
                          setOpenId(open ? t.id : undefined)
                        }}
                      >
                        <Ellipsis />
                      </Dropdown>
                    </div>
                  )}

                  {t.status === 2 && (
                    <div className="cloning">
                      <label>
                        克隆中
                        <LoadingOutlined />
                      </label>
                    </div>
                  )}

                  {t.status === 3 && (
                    <div className="error">
                      <label>克隆失败</label>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={renameModalOpen}
        title="重命名"
        okText="确定"
        cancelText="取消"
        onCancel={() => setRenameModalOpen(false)}
        onOk={sureRenameVoice}
      >
        <div style={{ padding: 20 }}>
          <Input
            style={{ height: 40 }}
            value={editVoice?.display_name}
            maxLength={20}
            onChange={(e) =>
              setEditVoice({
                ...editVoice,
                display_name: e.target.value
              })
            }
          />
        </div>
      </Modal>

      <VoiceGroups voiceId={voiceId} onSuccess={getDetail} onCancel={() => setVoiceId(undefined)} />
    </>
  )
})

VoiceDetail.displayName = 'VoiceDetail'

export default memo(VoiceDetail)
