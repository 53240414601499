import './style.scss'
import '../avatar-market/style.scss'
import { Button, Checkbox, Modal } from 'antd'
import { FC, memo, useEffect, useMemo, useState } from 'react'
import * as Api from '@/api/home'
import IconFilter from '@/assets/icon-filter.png'
import CheckLogin from '@/components/CheckLogin'
import { eventTracking } from '@/libs/util'
import MarketModal from './components/market-modal'

const CheckboxGroup = Checkbox.Group

const AvatarMarket: FC = () => {
  const [data, setData] = useState<any[]>([])
  const [marketModalOpen, setMarketModalOpen] = useState(false)
  const [marketDetail, setMarketDetail] = useState<any>()
  const [tags, setTags] = useState<string[]>([])
  const [tagList, setTagList] = useState<any[]>([])
  const specialTags = ['免费', '付费', '未雇佣', '已雇佣']
  const [filterOpen, setFilterOpen] = useState(false)
  const [filters, setFilters] = useState<any>({
    tags: [],
    pay: 0,
    sold: 0
  })
  const [popupFilters, setPopupFilters] = useState<any>({})

  const showData = useMemo(() => {
    return data
  }, [data])

  useEffect(() => {
    eventTracking('DigitalStore')
    getTags()
  }, [])

  useEffect(() => {
    getData()
  }, [tags])

  useEffect(() => {
    if (filters) {
      getData()
    }
  }, [filters])

  const getData = async () => {
    const { tags, sold, pay } = filters
    const tagStr = tags.map((t: string) => `tags=${t}`).join('&')

    const [merchandises] = await Promise.all([
      Api.getMerchandisesV3(`?pay_requirement=${pay}&sold_status=${pay === 1 ? 0 : sold}&${tagStr}`)
    ])
    setData(merchandises?.list || [])
  }

  const getTags = async () => {
    const res = await Api.getMerchandiseTags()
    setTagList(res?.tags || [])
  }

  const onFiltersChange = (key: string, value: any) => {
    setPopupFilters({
      ...popupFilters,
      [key]: value
    })
  }

  const resetFilters = () => {
    setPopupFilters({
      tags: [],
      pay: 0,
      sold: 0
    })
  }

  const onTagChange = (t: string) => {
    const pt = popupFilters.tags || []
    if (pt?.includes(t)) {
      onFiltersChange(
        'tags',
        pt.filter((p: any) => p !== t)
      )
    } else {
      onFiltersChange('tags', [...pt, t])
    }
  }

  const onMarketClick = (data: any) => {
    eventTracking('DigitalStoreClick', {
      id: data.id,
      name: data.name
    })
    setMarketDetail(data)
    setMarketModalOpen(true)
  }

  const onCancel = () => {
    getData()
    setMarketDetail(undefined)
    setMarketModalOpen(false)
  }

  return (
    <div className="page-common page-avatar-market">
      <div className="common-header">数字人市场</div>

      <div className="common-wrapper">
        <div className="nav">
          <div
            className="filter-box"
            onClick={() => {
              setPopupFilters(filters)
              setFilterOpen(true)
            }}
          >
            <span className="gradient">筛选</span>
            <img className="icon" src={IconFilter} />
          </div>
          <a
            target="_blank"
            className="link gradient"
            href="https://lingverse.feishu.cn/share/base/form/shrcnNx9YWvGeYFc7DEF20CrAAW"
            rel="noreferrer"
          >
            个性化定制
          </a>
        </div>

        <div className="page-container">
          {showData.map((d) => (
            <CheckLogin key={`${d.id}${d.name}`}>
              <div className="list-image-box" key={`${d.id}${d.name}`} onClick={onMarketClick.bind(this, d)}>
                <div className="box-main">
                  <div className="image">
                    <img src={d.covers?.[0]} className={`${d.name}`} />

                    {d.status === 2 && (
                      <div className="mask">
                        <span className="text">已被雇佣</span>
                      </div>
                    )}
                  </div>

                  <div className="name">
                    <span className="ellipsis">{d.name}</span>
                  </div>
                  <div className="bottom">
                    {d.type === 3 ? (
                      <div className="price gradient">免费</div>
                    ) : (
                      <div className="price ellipsis">
                        <label>{d.price || d.starting_price}</label>
                        <label className="credits">积分</label>
                      </div>
                    )}
                    <span className="num">
                      <label className="gradient">{d.videos?.length || 0}个形象</label>
                    </span>
                  </div>
                </div>
              </div>
            </CheckLogin>
          ))}
        </div>
      </div>

      <Modal
        className="title-modal filter-modal"
        title="数字人筛选"
        open={filterOpen}
        width={580}
        maskClosable={true}
        footer={
          <div className="filter-footer-confirm">
            <Button type="default" onClick={resetFilters}>
              重置
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setFilters(popupFilters)
                setFilterOpen(false)
              }}
            >
              确定
            </Button>
          </div>
        }
        onCancel={() => setFilterOpen(false)}
      >
        <div className="filter-fragment">
          <div className="title">付费状态</div>
          <div className="filte-list">
            <div
              className={`filter-item w50 ${popupFilters.pay === 1 ? 'actived' : ''}`}
              onClick={() => onFiltersChange('pay', popupFilters.pay === 1 ? 0 : 1)}
            >
              <label>免费</label>
            </div>
            <div
              className={`filter-item w50 ${popupFilters.pay === 2 ? 'actived' : ''}`}
              onClick={() => onFiltersChange('pay', popupFilters.pay === 2 ? 0 : 2)}
            >
              <label>付费</label>
            </div>
          </div>
        </div>
        <div className="filter-fragment">
          <div className="title">类型</div>
          <div className="filte-list">
            {tagList.map((t) => (
              <div
                className={`filter-item ${popupFilters?.tags?.includes(t) ? 'actived' : ''}`}
                key={t}
                onClick={onTagChange.bind(this, t)}
              >
                <label>{t}</label>
              </div>
            ))}
          </div>
        </div>
        <div className="filter-fragment">
          <div className="title">雇佣状态</div>
          <div className="filte-list">
            <div
              className={`filter-item w50 ${popupFilters.sold === 1 ? 'actived' : ''}`}
              onClick={() => onFiltersChange('sold', popupFilters.sold === 1 ? 0 : 1)}
            >
              <label>未雇佣</label>
            </div>
            <div
              className={`filter-item w50 ${popupFilters.sold === 2 ? 'actived' : ''}`}
              onClick={() => onFiltersChange('sold', popupFilters.sold === 2 ? 0 : 2)}
            >
              <label>已雇佣</label>
            </div>
          </div>
        </div>
      </Modal>

      <MarketModal open={marketModalOpen} marketDetail={marketDetail} onCancel={onCancel} />
    </div>
  )
}

export default memo(AvatarMarket)
