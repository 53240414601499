import { ConfigProvider, Modal } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import CryptoJS from 'crypto-js'
import { ReactElement, ReactNode, useState } from 'react'
import ReactDOM from 'react-dom/client'
import * as uuid from 'uuid'
import { cmsApi } from '@/api'
import PlanModal from '@/components/PlanModal'
import { UserStore } from '@/global-states'

const env = import.meta.env
const videSuffix = '?x-oss-process=video/snapshot,t_0,m_fast,ar_auto'

export const defaultPreviewText = '现在的一切都是为将来的梦想编织翅膀，让梦想在现实中展翅高飞。'

export const urlSource = (url: string, type?: string) => {
  let suffix = ''
  if (type === 'video' && isVideo(url)) {
    suffix = videSuffix
  }

  if (url?.indexOf('http') > -1 || url?.indexOf('//') > -1) {
    return `${url}${suffix}`
  }

  return `${env.VITE_API_BASE_URL_CDN}${url}${suffix}`
}

export const isVideo = (url: string) => {
  return /\.(mp4|mov|avi|wmv|flv|webm|mkv|3gp|mpeg|mpg|m4v)$/i.test(url)
}

export const urlOsskey = (osskey: string) => {
  return `${env.VITE_API_BASE_URL_CDN}${osskey.replace('public/', '')}`
}

export const tuple = <T extends any[]>(...elements: T) => {
  return elements
}

export const Render: (_: { children: () => ReactNode }) => ReactElement = (props: any) => <>{props.children()}</>

export const downlad = (urls: string, fileName: string) => {
  const x = new window.XMLHttpRequest()
  x.open('GET', urls, true)
  x.responseType = 'blob'

  x.onload = () => {
    const url = window.URL.createObjectURL(x.response)
    const a = document.createElement('a')
    a.href = url
    a.target = '_blank'
    a.download = fileName
    a.style.display = 'none'
    document.body.append(a)
    a.click()
    a.remove()
  }
  x.send()
  return x
}

export const findClosest = (arr: number[], target: number): number => {
  let closestIndex = 0
  let minDifference = Math.abs(arr[0] - target)

  for (let i = 1; i < arr.length; i++) {
    const difference = Math.abs(arr[i] - target)
    if (difference < minDifference) {
      minDifference = difference
      closestIndex = i
    }
  }

  return closestIndex
}

export const isMobile = () => {
  const userAgent: string = navigator.userAgent
  const mobileKeywords: string[] = ['Android', 'iPhone', 'iPad', 'Windows Phone', 'Mobile']
  const isMobile = mobileKeywords.some(function (keyword) {
    return userAgent.indexOf(keyword) !== -1
  })
  return isMobile
}

export const eventTracking = (event_name: string, properties = {}) => {
  const { uid } = UserStore
  let device_id = UserStore.device_id
  const tracked = localStorage.getItem('event_track')

  if (!device_id) {
    device_id = `di${uuid.v4()}`
    localStorage.setItem('di', `${device_id}`)
    UserStore.device_id = device_id
  }

  const track = {
    event_name,
    di: device_id,
    client_time: parseInt((Date.now() / 1000).toFixed()),
    properties: {
      page_route: location.href,
      uid: uid,
      platform: 'web',
      ...properties
    }
  } as any

  if (!tracked) {
    firstEventTracking(JSON.parse(JSON.stringify(track)))
  }
  track.properties = JSON.stringify(track.properties)
  localStorage.setItem('event_track', '1')
  return cmsApi.post('event_logs', track)
}

export const firstEventTracking = (track: any) => {
  const { userPackage } = UserStore
  track.properties.system = navigator.userAgent
  track.properties.invite_code = sessionStorage.getItem('code') || userPackage?.invitor_aff_code
  track.properties.refer = document?.referrer

  cmsApi.post('event_logs', {
    ...track,
    event_name: 'first_event_track',
    properties: JSON.stringify(track.properties)
  })
}

export const Encrypt = (word: string) => {
  const key = CryptoJS.enc.Utf8.parse('GHJjPa9YBDaDZtzx')
  const iv = CryptoJS.enc.Utf8.parse('0')
  const encrypted = CryptoJS.AES.encrypt(word, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.ciphertext.toString(CryptoJS.enc.Base64)
}

export const showPlanModal = () => {
  if (UserStore.planModalInstance) {
    UserStore.planModalInstance.setOpen(true)
    return
  }

  const div = document.createElement('div')
  document.body.appendChild(div)

  const root = ReactDOM.createRoot(div)

  root.render(
    <ConfigProvider
      locale={zhCN}
      theme={{
        token: {
          colorPrimary: '#6828a8' // 设置主题色
        }
      }}
    >
      <Render>
        {function showPlanModal() {
          const [open, setOpen] = useState(true)

          UserStore.planModalInstance = { setOpen }

          return (
            <PlanModal open={open} onCancel={() => setOpen(false)} onSuccess={() => UserStore.updateUserPackage()} />
          )
        }}
      </Render>
    </ConfigProvider>
  )
}

export const demoVideos = [
  {
    url: 'hf/local/demo-videos/1.mp4',
    ossKey: 'public/hf/local/demo-videos/1.mp4'
  },
  {
    url: 'hf/local/demo-videos/2.mp4',
    ossKey: 'public/hf/local/demo-videos/2.mp4'
  },
  {
    url: 'hf/local/demo-videos/3.mp4',
    ossKey: 'public/hf/local/demo-videos/3.mp4'
  }
]

export const demoAudios = [
  {
    id: 1,
    name: '坚强隐忍',
    url: 'hf/local/demo-audios/1.wav',
    ossKey: 'public/hf/local/demo-audios/1.wav'
  },
  {
    id: 2,
    name: '安心做自己',
    url: 'hf/local/demo-audios/2.wav',
    ossKey: 'public/hf/local/demo-audios/2.wav'
  },
  {
    id: 3,
    name: '给自己一点时间',
    url: 'hf/local/demo-audios/3.wav',
    ossKey: 'public/hf/local/demo-audios/3.wav'
  }
]

export const translateHtml = (text: string) => {
  if (text) {
    return text
      .replace(/<\/div>/g, '')
      .replace(/<div>/g, '\n')
      .replace(/<br>/g, '\n')
      .trim()
      .replace(/<(?!\/?(break|phoneme|category)\b)[^>]+>/g, '')
      .replace(/<break\s+class="[^"]*"\s+time="([^"]*)"\s+time-text="[^"]*"\s*>/g, '<break time="$1">')
      .replace(/&nbsp;/g, ' ')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '')
      .replace(/&nbsp;/g, "'")
      .replace(/&quot;/g, '"')
      .replace(/<(?!break\b[^>]*>)(?!\/break>)/g, '')
      .replace(/(<\/?break\b[^>]*>)|>/g, (match: any, p: any) => {
        // 如果匹配的是 <break> 或 </break>，则保留它
        if (p) return p
        // 否则，移除 '>'
        return ''
      })
  }
  return text
}

export const showDeclare = () => {
  return new Promise((resolve, reject) => {
    let index = 3

    const instance = Modal.confirm({
      width: 600,
      title: '使用者承诺须知',
      content: (
        <>
          <div>
            本声明将帮助您更好的在【飞影数字人】平台（下称“本平台”）使用相关工具上传和管理您的作品。您若上传作品，即视为您已充分知悉并充分接受以下内容：
          </div>
          <ul className="declare-list">
            <li>您作为使用者在本平台上传、发布的作品，应具有独立、完整的知识产权，不得侵犯他人知识产权等任何权利。</li>
            <li>
              您在使用本平台及上传、发布作品时，应当自觉遵守国家法律、法规，遵守公共秩序，尊重社会公德、社会主义制度、国家利益、公民合法权益、道德风尚和信息真实性等要求。如有违反，一经本平台发现将根据违规程度采取包括但不限于删除、下架、禁止发布内容、封禁账号等处理方式。如造成恶劣影响或涉嫌违法犯罪的，本平台将有权向有关管理机关或公安机关提交相关内容，并配合进行调查。
            </li>
            <li>
              若您上传的作品及作品中的素材（包括但不限于创意、文本、肖像、音频、图片、视频等）侵犯了任何第三方权利，本平台均有权在收到相关侵权投诉后对该被投诉的作品或用户账号依据相应规则，采取包括但不限于
              <label className="red">下架、警告、封禁账号</label>等处理方式。
            </li>
            <li>
              请勿使用我们的服务克隆或生成任何侵犯版权、违反道德伦理、或违反中华人民共和国法律法规的内容。我们生成的所有内容均带有详细日志，自动/人工复审，以及
              可溯源的隐形视频/音频水印，
              <label className="red">若发现您违反了相关规则，我们保留终止您的服务并上报公安机关等机构的权利。</label>
            </li>
            <li>
              更多信息请参阅
              <a target="_blank" href="/eula.html">
                用户协议
              </a>
              、
              <a target="_blank" href="/privacy_agreement.html">
                隐私协议
              </a>
              。
            </li>
          </ul>
        </>
      ),

      okText: (
        <div>
          我已知晓，同意<label style={{ display: 'inline-block', width: 36 }}>（{index}s）</label>
        </div>
      ),
      cancelText: '取消',
      okButtonProps: {
        disabled: true
      },
      onOk: () => {
        resolve(true)
        UserStore.declareChecked = true
      },
      onCancel: () => {
        reject()
        UserStore.declareChecked = false
      }
    })

    const si = setInterval(() => {
      index = index - 1
      instance.update({
        okText: (
          <div>
            我已知晓，同意{index > 0 ? <label style={{ display: 'inline-block', width: 36 }}>（{index}s）</label> : ''}
          </div>
        )
      })
      if (index < 1) {
        instance.update({
          okButtonProps: {
            disabled: false
          }
        })
        clearInterval(si)
      }
    }, 1000)
  })
}

export const randomTexts = [
  {
    key: 0,
    text: '从当下这一刻起，拒绝内耗。只因命运不会偏袒任何人，却会眷顾一直朝着光亮前进的人。我相信，路虽远，行则可至；事虽难，做则可成。一切好事将至，祝大家如愿以偿。'
  },
  {
    key: 1,
    text: '只需30秒，在哪儿都能拍，随时随地搞定一个超级逼真的数字分身。神态自然，表情丰富，拒绝呆板。无论是用于短视频创作、直播带货，还是虚拟客服，数字分身技术都能满足你的需求。'
  },
  {
    key: 2,
    text: '人生低谷时，不妨停下来，给自己一个微笑。坚持下去，曙光就在前方。你的故事，值得被听见。'
  },
  {
    key: 3,
    text: '早上起床后，喝一杯温水，能帮助清理肠道，促进新陈代谢。搭配简单的伸展运动，能唤醒全身细胞，让你一整天都充满活力。简单的小习惯，开启健康生活。'
  },

  {
    key: 4,
    text: '每天一杯红枣茶，补气养血，让你气色红润。搭配适量的坚果，补充维生素和矿物质。简单的小饮品，不仅能养生，还能提升幸福感。由内而外散发健康光彩，从一杯茶开始。'
  },
  {
    key: 5,
    text: '为什么我们打哈欠时会感到放松？这是因为打哈欠能增加大脑的氧气供应，同时排出二氧化碳。下次感到疲惫时，不妨深呼吸几次，效果也不错哦！'
  },
  {
    key: 6,
    text: '为什么星星在夜空中闪烁？其实是因为大气层的折射作用。当星光穿过大气层时，光线被折射，就像在“跳舞”一样。下次抬头看星星，感受宇宙的奇妙吧！'
  },
  {
    key: 7,
    text: '为什么手机信号不好时，我们总是不自觉地抬头？这是因为大脑会自动寻找信号更强的方向，而高处通常信号更好。下次试试这个小技巧，也许能改善信号哦！'
  },
  {
    key: 8,
    text: '用数字分身开启创作新纪元！30秒搞定一个超逼真的分身，告别单调内容。多场景应用，多角色切换，让视频更有趣，更吸粉！'
  }
]
