import './style.scss'
import { Button, Popover } from 'antd'
import { FC, memo, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Api from '@/api/home'
import ArrowLeft from '@/assets/arrow-left.png'
import InteractProduct from '@/assets/interact-product.png'
import { IconAvatar } from '@/assets/svg/icon-avatar'
import { IconKnowledge } from '@/assets/svg/icon-knowledge'
import { IconText } from '@/assets/svg/icon-text'
import { IconVoice } from '@/assets/svg/icon-voice'
import { InteractStore } from '@/global-states'
import { eventTracking, Render, showPlanModal, urlSource } from '@/libs/util'
import { CloseOutlined } from '@ant-design/icons'
import AvatarModal from './components/AvatarModal'
import Chatbox from './components/Chatbox'
import KnowledgeModal from './components/KnowledgeModal'
import ProductModal from './components/ProductModal'
import VoiceModal from './components/VoiceModal'

const InteractiveAvatar: FC = () => {
  const { selectAvatar, selectTts, selectKnowLedge, interactStart, roomId, duration_seconds } = InteractStore
  const navigate = useNavigate()
  const [avatarModalOpen, setAvatarModalOpen] = useState(false)
  const [voiceModalOpen, setVoiceModalOpen] = useState(false)
  const [knowledgeModalOpen, setKnowledgeModalOpen] = useState(false)
  const [productModalOpen, setProductModalOpen] = useState(false)
  const [popverOpen, setPopverOpen] = useState(false)
  const jmuxer = useRef<any>(null)
  const onBack = () => {
    navigate(-1)
  }

  useEffect(() => {
    eventTracking('InteractiveAvatarPage')
    InteractStore.initDigital()
    InteractStore.initVoice()
    InteractStore.initKnowledge()
    initRoom()
  }, [])

  const initRoom = async () => {
    const res = await Api.getInteractionList()
    if (res?.list?.length) {
      InteractStore.roomId = res.list[0].id
    }
  }

  const initJumxer = () => {
    jmuxer.current = new (window as any).JMuxer({
      node: 'video_output',
      mode: 'both',
      flushingTime: 500,
      fps: 25,
      debug: false,
      onError: function (e: any) {
        console.log('----------------------------Error: ' + e)
      },
      onMissingVideoFrames: function (e: any) {
        console.log('----------------------------onMissingVideoFrames: ' + e)
      },
      onMissingAudioFrames: function (e: any) {
        console.log('----------------------------onMissingAudioFrames: ' + e)
      }
    })
  }

  const onStart = () => {
    initJumxer()
  }

  const onStop = () => {
    InteractStore.interactStart = false
    jmuxer.current?.destroy()
  }

  const onVideoReceive = (video: Uint8Array) => {
    InteractStore.interactStart = true
    jmuxer.current?.feed({
      video
    })
  }

  const onAudioReceive = (audio: Uint8Array) => {
    jmuxer.current?.feed({
      audio
    })
  }

  const stopInteraction = async () => {
    if (roomId) {
      await Api.stopInteraction(roomId)
      InteractStore.interactStart = false
    }
  }

  const upgradePlan = () => {
    showPlanModal()
  }

  return (
    <div className="interactive-avatar">
      <div className="header">
        <img src={ArrowLeft} onClick={onBack} className="arrow-left" />
        <label>实时互动数字人</label>
        <Button
          type="primary"
          onClick={() => {
            eventTracking('IAPricing')
            setProductModalOpen(true)
          }}
        >
          <img src={InteractProduct} />
          定制服务
        </Button>
      </div>
      <div className="wrapper">
        <div className="video-box">
          <div className="video-box__main">
            {selectAvatar?.video_url && !interactStart && (
              <>
                <img className="poster" src={urlSource(selectAvatar.video_url, 'video')} />
              </>
            )}
            <video
              className="video-output"
              id="video_output"
              controls={true}
              controlsList="nodownload noremoteplayback noplaybackrate"
              autoPlay
              style={{ display: interactStart ? 'block' : 'none' }}
            ></video>
            <audio className="audio-output" id="audio_output" hidden></audio>
          </div>
          {!interactStart && (
            <div className="avatar-setting">
              {selectAvatar && (
                <div className="setting-item" onClick={() => setAvatarModalOpen(true)}>
                  <IconAvatar />
                  <span className="ellipsis">形象：{selectAvatar?.group_title}</span>
                </div>
              )}

              {selectTts && (
                <div className="setting-item" onClick={() => setVoiceModalOpen(true)}>
                  <IconVoice />
                  <span className="ellipsis">声音：{selectTts?.group_title}</span>
                </div>
              )}

              {selectKnowLedge && (
                <div className="setting-item" onClick={() => setKnowledgeModalOpen(true)}>
                  <IconKnowledge className="icon-knowledge" />
                  <span className="ellipsis">智能体：{selectKnowLedge?.name}</span>
                </div>
              )}

              <Popover
                placement="bottom"
                title={null}
                open={popverOpen}
                onOpenChange={(open) => setPopverOpen(open)}
                content={
                  <div style={{ width: '230px' }}>
                    <p>讲解模式可以指定剧本，让数字人按照剧本讲解。可用于知识分享、直播讲解等场景。</p>
                    <p>
                      在线体验版暂不支持此功能，请前往
                      <span
                        className="interact-link"
                        onClick={() => {
                          setProductModalOpen(true)
                          setPopverOpen(false)
                        }}
                      >
                        本地部署版
                      </span>
                      版本体验使用。
                    </p>
                  </div>
                }
              >
                <div className="setting-item">
                  <IconText />
                  <span className="ellipsis">剧本：讲解模式</span>
                </div>
              </Popover>
            </div>
          )}

          {interactStart && (
            <div className="video-box__top">
              <Render>
                {function countDown() {
                  const [timeLeft, setTimeLeft] = useState(duration_seconds)

                  useEffect(() => {
                    if (timeLeft === 0) {
                      stopInteraction()
                    }

                    const timer = setInterval(() => {
                      setTimeLeft((prevTime) => prevTime - 1)
                    }, 1000)

                    return () => clearInterval(timer)
                  }, [timeLeft])

                  return (
                    <span className="time">
                      剩余时间：<label>{timeLeft}</label>秒
                    </span>
                  )
                }}
              </Render>

              <CloseOutlined onClick={stopInteraction} />
            </div>
          )}

          <div style={{ display: interactStart ? 'none' : 'block' }}>
            <Chatbox
              onVideoReceive={onVideoReceive}
              onAudioReceive={onAudioReceive}
              onStart={onStart}
              onStop={onStop}
              upgradePlan={upgradePlan}
            />
          </div>
        </div>
      </div>

      <img src={urlSource('hiflys/interact/product-local.png')} hidden />
      <img src={urlSource('hiflys/interact/product-online.png')} hidden />

      <AvatarModal open={avatarModalOpen} onCancel={() => setAvatarModalOpen(false)} />

      <VoiceModal open={voiceModalOpen} onCancel={() => setVoiceModalOpen(false)} upgradePlan={upgradePlan} />

      <KnowledgeModal open={knowledgeModalOpen} onCancel={() => setKnowledgeModalOpen(false)} />

      <ProductModal open={productModalOpen} onCancel={() => setProductModalOpen(false)} />
    </div>
  )
}

export default memo(InteractiveAvatar)
