import './style.scss'
import { Button, Popover } from 'antd'
import dayjs from 'dayjs'
import { FC, memo, useMemo, useState } from 'react'
import CheckLogin from '@/components/CheckLogin'
import PayPointModal from '@/components/PayPointModal'
import { UserStore } from '@/global-states'
import { eventTracking } from '@/libs/util'
import { InfoCircleOutlined } from '@ant-design/icons'

interface IProps {
  onSub: () => void
}

const UserDetail: FC<IProps> = (props) => {
  const { onSub } = props
  const { phone, userPackage } = UserStore
  const [payPointModalOpen, setPayPointModalOpen] = useState(false)

  const vip = useMemo(() => {
    return !!userPackage?.membership_expire_time
  }, [userPackage])

  const vipExpired = useMemo(() => {
    return !!userPackage?.membership_expired
  }, [userPackage])

  const totalPoint = useMemo(() => {
    if (!userPackage) return 0
    return (
      (userPackage.membership_credits || 0) + (userPackage.permanent_credits || 0) + (userPackage.free_credits || 0)
    )
  }, [userPackage])

  const toPayPoint = () => {
    eventTracking('RechargeCredits')
    setPayPointModalOpen(true)
  }

  const freeToPayPointExpired = () => {
    eventTracking('ToPayPointLimit')
    setPayPointModalOpen(true)
  }

  const toPayPointExpired = () => {
    eventTracking('ToPayPointExpired')
    setPayPointModalOpen(true)
  }

  return (
    <div className="setting-user-detail">
      <div className="header-user">
        <div className="user">
          <div className="name">Hi</div>

          {phone ? (
            <div className="user-info">
              <div className="phone">
                <label>{phone}</label>
                {vip && !vipExpired && (
                  <label className="tag">{userPackage?.current_membership_package_title}会员</label>
                )}
              </div>
              {vip ? (
                <>
                  {vipExpired ? (
                    <label className="text red">
                      套餐已于{dayjs(userPackage.membership_expire_time * 1000).format('YYYY-MM-DD HH:mm')}过期
                    </label>
                  ) : (
                    <label className="text">
                      到期时间：{dayjs(userPackage.membership_expire_time * 1000).format('YYYY-MM-DD HH:mm')}
                    </label>
                  )}
                </>
              ) : (
                <label className="text">未开通会员</label>
              )}
            </div>
          ) : (
            <div className="user-info">
              <label className="text">未登录</label>
            </div>
          )}
        </div>
        {vip ? (
          <div className="btns">
            <Button
              type="primary"
              onClick={() => {
                eventTracking('RenewUpgrade')
                onSub()
              }}
            >
              {vipExpired ? '立即续费' : '续费 / 升级'}
            </Button>
          </div>
        ) : (
          <div className="btns">
            <CheckLogin>
              <Button
                type="primary"
                onClick={() => {
                  eventTracking('SubscribeNow')
                  onSub()
                }}
              >
                立即订阅
              </Button>
            </CheckLogin>
          </div>
        )}
      </div>
      <div className="header-desc">
        <div className="left">
          <label className="text">积分数量</label>
          <label className="num">{totalPoint}</label>
          <label className="icon">
            <Popover
              content={
                <div className="point-popover">
                  <div>
                    <label className="label">赠送积分:</label>
                    <label className="num gradient">{userPackage?.free_credits || 0}</label>
                  </div>
                  <div>
                    <label className="label">会员积分:</label>
                    <label className="num gradient">{userPackage?.membership_credits || 0}</label>
                  </div>
                  <div>
                    <label className="label">充值积分:</label>
                    <label className="num gradient">{userPackage?.permanent_credits || 0}</label>
                  </div>

                  <a
                    target="_blank"
                    className="link gradient"
                    href="https://lingverse.feishu.cn/sheets/IJeQsahvdhqX5btBSzycBKPRnr0"
                    rel="noreferrer"
                  >
                    查看积分说明
                  </a>
                </div>
              }
              placement="right"
            >
              <InfoCircleOutlined />
            </Popover>
          </label>
        </div>
        <div className="right">
          {vip ? (
            <>
              {vipExpired ? (
                <Button onClick={toPayPointExpired}>充值积分</Button>
              ) : (
                <Button className="point-pay" onClick={toPayPoint}>
                  充值积分
                  <div className="tag">特惠</div>
                </Button>
              )}
            </>
          ) : (
            <CheckLogin>
              <Button onClick={freeToPayPointExpired}>充值积分</Button>
            </CheckLogin>
          )}
        </div>
      </div>

      <PayPointModal open={payPointModalOpen} onCancel={() => setPayPointModalOpen(false)} />
    </div>
  )
}

export default memo(UserDetail)
