import { Button, message, Progress } from 'antd'
import { RcFile } from 'antd/es/upload'
import Dragger from 'antd/es/upload/Dragger'
import axios from 'axios'
import { FC, useEffect, useRef, useState } from 'react'
import { cmsApi, interactApi } from '@/api'
import VideoModal from '@/components/VideoModal'
import { CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons'

interface IProps {
  uploadContent?: React.ReactNode
  format?: string
  size?: number
  duration?: {
    min: number
    max: number
    desc?: string
  }
  dpi?: {
    min: number
    max: number
    desc?: string
  }
  type?: string
  upateStep?: (step: number) => void
  onUploadSuccess?: (data: any) => void
  onRestartUpload?: () => void
}

const UploadVideo: FC<IProps> = (props) => {
  const { onUploadSuccess, onRestartUpload, upateStep, uploadContent, format, size, duration, dpi, type } = props
  const [currentStep, setCurrentStep] = useState(0)
  const [videoUrl, setVideoUrl] = useState<string>(undefined as any)
  const [videoFile, setVideoFile] = useState<RcFile>(undefined as any)
  const [percent, setPercent] = useState(0)
  const cancelTokenSource = useRef<any>()
  const [preview, setPreview] = useState<any>()

  useEffect(() => {
    if (videoFile) {
      setCurrentStep(1)
      setVideoUrl(URL.createObjectURL(videoFile))
    } else {
      setVideoUrl(undefined as any)
    }
  }, [videoFile])

  useEffect(() => {
    upateStep?.(currentStep)
  }, [currentStep])

  // media_type -  0 未定义  1 视频  2 音频
  const uploadInteractFile = async (file: RcFile, media_type: 0 | 1 | 2) => {
    try {
      const segs = (file.name || '').split(/\./)
      const { upload_url, oss_key, content_type } =
        (await interactApi.post('upload_url', {
          extension: segs[segs.length - 1],
          media_type
        })) || {}
      if (!upload_url) {
        throw new Error('failed to upload file')
      }

      cancelTokenSource.current = axios.CancelToken.source()
      setPercent(0)
      setCurrentStep(1)

      await interactApi.upload(upload_url.replace(/^http:\/\//, 'https://').replace('-internal', ''), file, {
        onUploadProgress: (progress) => {
          const percent = Math.round((progress.progress || 0) * 100)
          setPercent(percent)
        },
        headers: {
          'Content-Type': content_type
        },
        cancelToken: cancelTokenSource.current?.token
      })

      setCurrentStep(2)
      if (media_type === 1) {
        onUploadSuccess?.({
          title: segs[0],
          oss_key
        })
      }
    } catch (err: any) {
      if (err?.code !== 'ERR_CANCELED') {
        message.error(err?.message || err)
      }
    }
  }

  // media_type -  0 未定义  1 视频  2 音频
  const uploadFile = async (file: RcFile, media_type: 0 | 1 | 2) => {
    try {
      const segs = (file.name || '').split(/\./)
      const { upload_url, oss_key, content_type } =
        (await cmsApi.post('upload_url', {
          extension: segs[segs.length - 1],
          media_type
        })) || {}
      if (!upload_url) {
        throw new Error('failed to upload file')
      }

      cancelTokenSource.current = axios.CancelToken.source()
      setPercent(0)
      setCurrentStep(1)

      await cmsApi.upload(upload_url.replace(/^http:\/\//, 'https://').replace('-internal', ''), file, {
        onUploadProgress: (progress) => {
          const percent = Math.round((progress.progress || 0) * 100)
          setPercent(percent)
        },
        headers: {
          'Content-Type': content_type
        },
        cancelToken: cancelTokenSource.current?.token
      })

      setCurrentStep(2)
      if (media_type === 1) {
        onUploadSuccess?.({
          title: segs[0],
          oss_key
        })
      }
    } catch (err: any) {
      if (err?.code !== 'ERR_CANCELED') {
        message.error(err?.message || err)
      }
    }
  }

  const beforeVideoUpload = async (file: RcFile) => {
    return new Promise((resolve) => {
      const type = file?.name?.split(/\./)?.slice(-1)?.[0]?.toLocaleLowerCase() as string
      const f = format?.split('、')
      if (f?.includes(type)) {
        const fileSize = file?.size || 0
        if (size && fileSize / 1000 / 1000 > size) {
          message.warning('视频大小不能大于 500MB')
          resolve(false)
        }
        const videoElement = document.createElement('video')
        videoElement.src = URL.createObjectURL(file)
        videoElement.addEventListener('loadedmetadata', () => {
          console.log('videoElement', videoElement.videoWidth)

          const { videoWidth, videoHeight } = videoElement

          if (dpi) {
            const { min, max, desc } = dpi
            if (videoWidth === 0) {
              message.warning('视频处理失败, 建议你使用h264编码格式的mp4文件')
              return resolve(false)
            }

            if (max === 1080) {
              if (
                videoWidth < min ||
                Math.max(videoWidth, videoHeight) >= 2560 ||
                Math.min(videoWidth, videoHeight) > max
              ) {
                message.warning('视频素材分辨率要求为720p～1080p')
                return resolve(false)
              }
            } else {
              if (videoElement.videoWidth < min || videoElement.videoWidth > max) {
                message.warning(`视频素材分辨率要求为${desc}`)
                return resolve(false)
              }
            }
          }

          if (duration) {
            const { min, max, desc } = duration
            if (videoElement.duration < min || videoElement.duration > max) {
              message.warning(`视频时长要求为${desc}`)
              return resolve(false)
            }
          }

          resolve(true)
        })
      } else {
        message.warning(`请上传${format}格式视频文件`)
        resolve(false)
      }
    })
  }

  const onDrop = async (file: RcFile) => {
    const type = file?.name?.split(/\./)?.slice(-1)?.[0]?.toLocaleLowerCase() as string
    const f = format?.split('、')
    if (!f?.includes(type)) {
      message.warning(`请上传${format}格式视频文件`)
    }
  }

  const restartUpload = () => {
    cancelUpload()
    setCurrentStep(0)
    setPercent(0)
    setVideoFile(undefined as any)
    onRestartUpload?.()
  }

  const cancelUpload = () => {
    if (cancelTokenSource) {
      cancelTokenSource.current?.cancel('取消上传')
    }
  }

  return (
    <>
      {currentStep === 0 && (
        <Dragger
          accept={
            format
              ? format
                  ?.split('、')
                  ?.map((f) => `.${f}`)
                  .join(',')
              : '.mp4,.mov'
          }
          showUploadList={false}
          beforeUpload={async (file) => {
            const flag = (await beforeVideoUpload(file)) as any
            if (flag) {
              setVideoFile(file)
              if (type === 'interact') {
                uploadInteractFile(file, 1)
              } else {
                uploadFile(file, 1)
              }
            }
            return flag
          }}
          onDrop={(e) => onDrop(e.dataTransfer.files?.[0] as any)}
        >
          <p className="ant-upload-drag-icon">
            <CloudUploadOutlined />
          </p>
          {uploadContent || (
            <>
              <p className="ant-upload-text">请上传一段视频</p>
              <p className="ant-upload-hint">将文件拖到此处，或点击此区域上传</p>
            </>
          )}
        </Dragger>
      )}
      {currentStep === 1 && (
        <div className="step-progress">
          <div className="step-progress-content">
            <div className="percent">{percent}%</div>
            <Progress percent={percent} showInfo={false} />
            <div className="tips">视频上传中</div>
          </div>
          <div className="btns">
            <Button onClick={restartUpload}>取消</Button>
          </div>
        </div>
      )}
      {currentStep === 2 && (
        <div className="step-view">
          <div className="step-view-box video">
            <video controls src={videoUrl} />
            <div className="trash" onClick={restartUpload}>
              <DeleteOutlined />
            </div>
          </div>
        </div>
      )}

      <VideoModal preview={preview} onCancel={() => setPreview(undefined)} />
    </>
  )
}

export default UploadVideo
