import './style.scss'
import { FC, memo, useMemo, useState } from 'react'
import { UserStore } from '@/global-states'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'

const VipAgent: FC = () => {
  const { userPackage } = UserStore
  const [viewAgent, setViewAgent] = useState(false)
  const showAgent = useMemo(() => {
    const id = userPackage?.hifly_id
    if (viewAgent) {
      return id
    }
    return id?.replace(/./g, '*')
  }, [userPackage?.hifly_id, viewAgent])

  if (!showAgent) return null

  return (
    <div className="vip-agent">
      <div className="title">智能体信息</div>
      <div className="agent-detail">
        <div className="label">hifly_agent_token：</div>
        <div className="agent">{showAgent}</div>
        <div className="icon" onClick={() => setViewAgent(!viewAgent)}>
          {!viewAgent ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        </div>
      </div>
    </div>
  )
}

export default memo(VipAgent)
