import './style.scss'
import { Button, message, Table } from 'antd'
import dayjs from 'dayjs'
import { FC, memo, useEffect, useRef, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import * as Api from '@/api/account'
import IconScore from '@/assets/icon-score.png'
import InvitePublish from '@/assets/invite-publish.png'
import InviteRecharge from '@/assets/invite-recharge.png'
import InviteRegister from '@/assets/invite-register.png'
import InviteTitle from '@/assets/invite-title.png'
import Douban from '@/assets/plat-douban.png'
import Douyin from '@/assets/plat-douyin.png'
import Kuaishou from '@/assets/plat-kuaishou.png'
import Shipinhao from '@/assets/plat-shipinhao.png'
import Weibo from '@/assets/plat-weibo.webp'
import Xiaohongshu from '@/assets/plat-xiaohongshu.png'
import Zhihu from '@/assets/plat-zhihu.png'
import QrcodeMini from '@/assets/qrcode-mini.png'
import { eventTracking } from '@/libs/util'

const Invite: FC = () => {
  const [profile, setProfile] = useState<any>()
  const [logs, setLogs] = useState<any>([])
  const logsRef = useRef<any>()

  useEffect(() => {
    eventTracking('MyInvitePage')
    getProfile()
    getCreditsLogs()
    const id = location.href?.split('#')[1]
    if (id) {
      document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  const getProfile = async () => {
    const res = await Api.getProfile()
    setProfile(res)
  }

  const getCreditsLogs = async () => {
    const res = await Api.getCreditsLogs()
    setLogs(res.list || [])
  }

  const submit = () => {
    window.open('https://lingverse.feishu.cn/share/base/form/shrcn2DoUSTZLMcCLGNEYqUiSph')
  }

  return (
    <div className="page-common page-invite">
      <div className="common-wrapper">
        <div className="page-invite-wrapper">
          <div className="page-invite-main">
            <div className="invite-header">
              <div className="invite-title">
                <img className="icon" src={IconScore} />
                <img className="title" src={InviteTitle} />
              </div>

              <div className="invite-info">
                <div className="info-item">
                  <div className="text">已获得赠送积分</div>
                  <div
                    className="num under"
                    onClick={() =>
                      logsRef.current?.scrollIntoView({
                        behavior: 'smooth'
                      })
                    }
                  >
                    {profile?.total_gained_credits || 0}
                  </div>
                </div>
                <div className="info-item">
                  <div className="text">已邀请成单次数</div>
                  <div className="num">{profile?.affiliated_membership_order_count || 0}</div>
                </div>
                <div className="info-item">
                  <div className="text">已邀请注册用户</div>
                  <div className="num">{profile?.affiliated_new_user_count || 0}</div>
                </div>
              </div>
            </div>

            <div className="invite-main" id="recharge">
              <div className="content">
                <div className="content-header">
                  <img src={InviteRecharge} />
                </div>
                <div className="content-main">
                  <div className="left">
                    <div className="content-title">邀请好友下单，好友成功支付后，你和好友都可获得积分，多邀多得！</div>
                    <div className="content-desc l28">
                      好友在购买单月会员时填写你的邀请码，成功支付后，则双方均可获得<label className="num">5000</label>
                      积分奖励
                    </div>
                    <div className="content-desc l28">
                      好友在购买年度会员时填写你的邀请码，成功支付后，则双方均可获得<label className="num">60000</label>
                      积分奖励
                    </div>
                    <div className="form-item">
                      <div className="form-content">
                        <div className="link">
                          <div className="h">{`${location.origin}?code=${profile?.affiliate_code || ''}`}</div>
                        </div>
                        <CopyToClipboard
                          text={`${location.origin}?code=${profile?.affiliate_code || ''}`}
                          onCopy={() => {
                            eventTracking('CopyInviteAddress')
                            message.success('已复制邀请链接')
                          }}
                        >
                          <Button type="primary">复制邀请链接</Button>
                        </CopyToClipboard>
                      </div>
                    </div>
                    <div className="form-item">
                      <div className="form-content">
                        <div className="link">
                          <div className="h">{profile?.affiliate_code || ''}</div>
                        </div>
                        <CopyToClipboard
                          text={profile?.affiliate_code || ''}
                          onCopy={() => {
                            eventTracking('CopyInviteCode')
                            message.success('已复制邀请码')
                          }}
                        >
                          <Button type="primary">复制邀请码</Button>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="invite-main" id="regeister">
              <div className="content">
                <div className="content-header">
                  <img src={InviteRegister} />
                </div>
                <div className="content-main">
                  <div className="left">
                    <div className="content-title">
                      邀请好友注册，好友成功注册登录后，你和好友都可获得积分，多邀多得！
                    </div>
                    <div className="content-desc">
                      新用户使用你的小程序邀请链接，注册并成功登录“飞影数字人”小程序，则双方均可获得{' '}
                      <label className="num">100</label>积分奖励
                    </div>

                    <div className="content-title mt36">邀请链接获取方式</div>
                    <div className="content-desc">
                      微信进入“飞影数字人”小程序，登录后点击“我的 - 免费获取积分”即可看到
                    </div>
                  </div>
                  <div className="right">
                    <img className="img" src={QrcodeMini} />
                    <div className="tip">微信扫一扫，进入小程序</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="invite-main" id="publish">
              <div className="content">
                <div className="content-header">
                  <img src={InvitePublish} />
                </div>
                <div className="content-main">
                  <div className="recommend">
                    <div className="content-title">在以下任一平台上发布你使用飞影数字人的真实感受</div>
                    <div className="steps">
                      <div className="recommend-content">
                        <div className="title">1、种草推荐</div>
                        <div className="platform">
                          <div className="platform-item">
                            <a
                              target="_blank"
                              href="https://creator.douyin.com/creator-micro/content/upload?enter_from=dou_web"
                              rel="noreferrer"
                            >
                              <img src={Douyin} />
                            </a>
                            <div className="text">抖音</div>
                          </div>
                          <div className="platform-item">
                            <a
                              target="_blank"
                              href="https://creator.xiaohongshu.com/publish/publish?source=official"
                              rel="noreferrer"
                            >
                              <img src={Xiaohongshu} />
                            </a>
                            <div className="text">小红书</div>
                          </div>
                          <div className="platform-item">
                            <a
                              target="_blank"
                              href="https://cp.kuaishou.com/article/publish/video?origin=www.kuaishou.com"
                              rel="noreferrer"
                            >
                              <img src={Kuaishou} />
                            </a>
                            <div className="text">快手</div>
                          </div>
                          <div className="platform-item">
                            <a
                              target="_blank"
                              href="https://channels.weixin.qq.com/platform/post/create"
                              rel="noreferrer"
                            >
                              <img src={Shipinhao} />
                            </a>
                            <div className="text">视频号</div>
                          </div>
                          <div className="platform-item">
                            <a target="_blank" href="https://www.douban.com/" rel="noreferrer">
                              <img src={Douban} />
                            </a>
                            <div className="text">豆瓣</div>
                          </div>
                          <div className="platform-item">
                            <a target="_blank" href="https://www.zhihu.com/creator" rel="noreferrer">
                              <img src={Zhihu} />
                            </a>
                            <div className="text">知乎</div>
                          </div>
                          <div className="platform-item">
                            <a target="_blank" href="https://weibo.com/" rel="noreferrer">
                              <img src={Weibo} />
                            </a>
                            <div className="text">微博</div>
                          </div>
                        </div>
                      </div>
                      <div className="recommend-content">
                        <div className="title">2、提交验证</div>

                        <div className="recommend-content-tip">发布推荐内容即可参与活动，具体规则请您与我们联系。</div>
                        <Button type="primary" onClick={submit}>
                          开始提交
                        </Button>
                      </div>

                      <div className="invite-tips">
                        <div className="title">活动说明</div>
                        <ul>
                          <li>本活动严禁不真实数据作弊行为，一经核实，取消奖励；</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="invite-table" ref={logsRef}>
              <div className="table-title">奖励发放记录</div>
              <Table
                pagination={
                  logs?.length > 8
                    ? {
                        pageSize: 8,
                        showSizeChanger: false
                      }
                    : false
                }
                locale={{
                  emptyText: <div style={{ padding: '40px 0' }}>暂无记录</div>
                }}
                columns={[
                  {
                    title: '类型',
                    dataIndex: 'title'
                  },
                  {
                    title: '发放时间',
                    dataIndex: 'create_time',
                    render: (time: number) => <div>{dayjs(time * 1000).format('YYYY-MM-DD HH:mm')}</div>
                  },
                  {
                    title: '积分',
                    dataIndex: 'credits'
                  }
                ]}
                dataSource={logs}
              ></Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Invite)
