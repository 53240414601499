import './style.scss'
import { Button, Input, Modal } from 'antd'
import { FC, memo, useEffect, useState } from 'react'
import * as homeApi from '@/api/home'

interface IProps {
  open: boolean
  onSuccess?: () => void
  onCancel?: () => void
}

const QaModal: FC<IProps> = (props) => {
  const { open, onSuccess, onCancel } = props
  const [qaStep, setQaStep] = useState(0)
  const [questions, setQuestions] = useState<any[]>([])
  const [answers, setAnswers] = useState<any[]>([])
  const [answerInput, setAnswerInput] = useState('')
  const [answerInputShow, setAnswerInputShow] = useState(false)
  const [closeConfirm, setCloseConfirm] = useState(false)
  const [successModalOpen, setSuccessModalOpen] = useState(false)

  useEffect(() => {
    if (open) {
      getQuestion()
    }
    setCloseConfirm(false)
    setQaStep(0)
    setAnswers([])
    setAnswerInput('')
    setAnswerInputShow(false)
  }, [open])

  const getQuestion = async () => {
    const res = await homeApi.getQuestions()
    setQuestions(res.questions || [])
  }

  const submitQa = async () => {
    await homeApi.submitQa({
      answers: answers.map((a) => {
        return a.answerInput
          ? {
              id: a.id,
              answer: a.answerInput || a.answer
            }
          : a
      })
    })
    onCancel?.()
    onSuccess?.()
    setSuccessModalOpen(true)
  }

  return (
    <>
      <Modal
        width={510}
        title={null}
        onCancel={() => {
          closeConfirm ? onCancel?.() : setCloseConfirm(true)
        }}
        open={!!open}
        footer={null}
        className="commom-modal qa-modal"
      >
        <div className="qa-modal-body">
          <div className="header">填写问卷领取500积分</div>
          <div className="main">
            {!closeConfirm ? (
              <>
                <div className="desc">
                  <div className="welcome ">你好！新朋友</div>
                  <div className="text ">为了给您提供最佳的使用体验，请花一点时间完成以下问题的填写。</div>
                </div>
                <div className="question">{questions[qaStep]?.title}</div>
                <div className="answer">
                  {questions[qaStep]?.templates?.map((t: any) => (
                    <div
                      key={t}
                      className={`answer-box ${answers[qaStep]?.answer === t ? 'actived' : ''}`}
                      onClick={() => {
                        const id = questions[qaStep].id
                        const r = {
                          id,
                          answer: t
                        }
                        setAnswerInputShow(t === '其他')
                        setAnswerInput('')
                        if (answers?.length) {
                          setAnswers([...answers.filter((a) => a.id !== id), r])
                        } else {
                          setAnswers([r])
                        }
                      }}
                    >
                      {t}
                    </div>
                  ))}
                </div>

                {answerInputShow && (
                  <div className="other">
                    <div className="text">其他：</div>
                    <Input
                      className="answer-input"
                      maxLength={100}
                      placeholder="在此输入"
                      value={answerInput}
                      onInput={(e: any) => {
                        const value = e.target.value
                        const id = questions[qaStep].id
                        setAnswerInput(value)
                        setAnswers(
                          answers.map((a) => {
                            return a.id === id
                              ? {
                                  ...a,
                                  answerInput: value
                                }
                              : a
                          })
                        )
                      }}
                    />
                  </div>
                )}

                <div className="bottom">
                  {qaStep + 1 >= questions.length ? (
                    <Button
                      className={`btn ${
                        (answers[qaStep]?.answer && answers[qaStep]?.answer !== '其他') ||
                        (answers[qaStep]?.answer === '其他' && answers[qaStep]?.answerInput)
                          ? ''
                          : 'unable'
                      }`}
                      type="primary"
                      onClick={submitQa}
                    >
                      提交问卷
                    </Button>
                  ) : (
                    <Button
                      className={`btn ${
                        (answers[qaStep]?.answer && answers[qaStep]?.answer !== '其他') ||
                        (answers[qaStep]?.answer === '其他' && answers[qaStep]?.answerInput)
                          ? ''
                          : 'unable'
                      }`}
                      type="primary"
                      onClick={() => {
                        setQaStep(qaStep + 1)
                        setAnswerInput('')
                        setAnswerInputShow(false)
                      }}
                    >
                      下一个
                    </Button>
                  )}
                </div>
              </>
            ) : (
              <div className="close-confirm">
                <div className="confirm-title">确定不继续填写吗?</div>
                <div className="confirm-desc">
                  快速填写完{questions.length}个问题就可以额外获得500积分啦，确定暂时退出吗？
                </div>
                <div className="btns">
                  <Button type="default" onClick={onCancel}>
                    退出
                  </Button>
                  <Button type="primary" onClick={() => setCloseConfirm(false)}>
                    继续填写
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        width={450}
        title={null}
        onCancel={() => setSuccessModalOpen(false)}
        open={successModalOpen}
        footer={null}
        className="commom-modal qa-succes-modal"
        closeIcon={null}
      >
        <div className="qa-succes-modal-body">
          <div className="title gradient">您已经获得500积分!</div>
          <div className="desc">欢迎使用飞影数字人！开始使用积分体验数字人生成吧！</div>
          <Button className="primary" type="primary" onClick={() => setSuccessModalOpen(false)}>
            开始体验
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default memo(QaModal)
