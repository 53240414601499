import './style.scss'
import { Badge, Popover, Table } from 'antd'
import dayjs from 'dayjs'
import { FC, memo, useEffect, useMemo, useState } from 'react'
import { UserStore } from '@/global-states'
import { InfoCircleOutlined } from '@ant-design/icons'

const PointDetail: FC = () => {
  const { creditLog, productOrder, pointOrder, payStatus, loginStatus } = UserStore
  const [tabIndx, setTabIndx] = useState(0)

  const tableData = useMemo(() => {
    if (tabIndx === 1) {
      return productOrder
    }
    if (tabIndx === 2) {
      return pointOrder
    }
    return creditLog
  }, [tabIndx, creditLog, productOrder, pointOrder])

  const onPageChange = (currentPage: number, currentPageSize: number) => {
    getData(currentPage - 1, currentPageSize)
  }

  useEffect(() => {
    getData()
  }, [tabIndx])

  useEffect(() => {
    if (payStatus || loginStatus) {
      getData()
    }
  }, [payStatus, loginStatus])

  const getData = (page?: number, pageSize?: number) => {
    if (tabIndx === 0) {
      UserStore.getCreditLogs(page, pageSize)
    }
    if (tabIndx === 1) {
      UserStore.getProductOrders(page, pageSize)
    }
    if (tabIndx === 2) {
      UserStore.getPointOrders(page, pageSize)
    }
  }

  const columns = useMemo(() => {
    if (tabIndx === 0) {
      return [
        {
          title: '类型',
          dataIndex: 'description',
          width: 300
        },
        {
          title: '积分变动数量',
          dataIndex: 'credits_delta',
          render: (num: number) => <div>{num > 0 ? `+${num}` : !num ? 0 : num}</div>,
          width: 300
        },
        {
          title: '时间',
          dataIndex: 'create_time',
          width: 300,
          render: (time: number) => <div>{dayjs(time * 1000).format('YYYY-MM-DD HH:mm')}</div>
        }
      ]
    } else {
      return [
        {
          title: '类型',
          dataIndex: 'title',
          width: 400
        },
        {
          title: '金额',
          dataIndex: 'amount',
          render: (num: number) => <div>{(num || 0) / 100}</div>,
          width: 250
        },
        {
          title: '订单号',
          dataIndex: 'pay_order_id',
          width: 300
        },
        {
          title: '状态',
          dataIndex: 'status',
          width: 200,
          render: (status: number) => (
            <>
              {status === 1 && (
                <div style={{ display: 'flex', alignItems: 'center', gap: '0 4px' }}>
                  <Badge color="blue" text="待支付" />
                  <Popover
                    content={
                      <div>
                        <div>
                          待支付订单需在 <strong className="red">25</strong> 分钟内完成支付，否则将自动取消。
                        </div>
                        <div>未使用的代金券会自动返还，不会影响您的权益</div>
                      </div>
                    }
                    placement="bottom"
                  >
                    <InfoCircleOutlined />
                  </Popover>
                </div>
              )}
              {status === 2 && <Badge color="green" text="已支付" />}
              {status === 3 && <Badge color="volcano" text="支付失败" />}
              {status === 4 && <Badge color="orange" text="已过期" />}
            </>
          )
        },
        {
          title: '时间',
          dataIndex: 'create_time',
          width: 300,
          render: (time: number) => <div>{dayjs(time * 1000).format('YYYY-MM-DD HH:mm')}</div>
        }
      ]
    }
  }, [tabIndx])

  return (
    <div className="setting-table">
      <div className="tabs">
        <span className={`text ${tabIndx === 0 ? 'actived' : ''}`} onClick={() => setTabIndx(0)}>
          积分收支明细
        </span>
        <span className="split">/</span>
        <span className={`text ${tabIndx === 1 ? 'actived' : ''}`} onClick={() => setTabIndx(1)}>
          会员订单管理
        </span>
        <span className="split">/</span>
        <span className={`text ${tabIndx === 2 ? 'actived' : ''}`} onClick={() => setTabIndx(2)}>
          积分订单管理
        </span>
      </div>
      <Table
        pagination={
          tableData?.total > 8
            ? {
                pageSize: 8,
                showSizeChanger: false,
                total: tableData.total,
                onChange: onPageChange
              }
            : false
        }
        columns={columns}
        locale={{
          emptyText: <div style={{ padding: '40px 0' }}>暂无记录</div>
        }}
        dataSource={tableData?.list || []}
      ></Table>
    </div>
  )
}

export default memo(PointDetail)
