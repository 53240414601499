import './style.scss'
import { Carousel } from 'antd'
import { FC, memo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { cdnApi } from '@/api'
import { eventTracking, urlSource } from '@/libs/util'

interface IProps {
  activityBannerClick?: () => void
}

const SettingBanner: FC<IProps> = (props) => {
  const { activityBannerClick } = props
  const navigate = useNavigate()
  const [banners, setBanners] = useState<any[]>([])

  useEffect(() => {
    getActivity()
  }, [])

  const getActivity = async () => {
    const res = await cdnApi.get(`hiflys/personal-activity/config-new.json?date=${Date.now()}`)
    setBanners(res || [])
  }

  const onBannerClick = (b: any) => {
    if (b.activity) {
      eventTracking('ActivityBannerClick')
      activityBannerClick?.()
    } else if (b.linkUrl) {
      window.open(b.linkUrl)
    } else if (b.route) {
      navigate(b.route)
    }
  }

  return (
    <div className="setting-banner-swipper">
      <Carousel autoplay>
        {banners?.map((b: any) => (
          <div className="banner" key={b.url}>
            <img src={urlSource(b.url)} onClick={onBannerClick.bind(this, b)} />
          </div>
        ))}
      </Carousel>
    </div>
  )
}

export default memo(SettingBanner)
