import './style.scss'
import dayjs from 'dayjs'
import { FC, memo, useMemo } from 'react'
import { UserStore } from '@/global-states'

const UserModalHeader: FC = () => {
  const { phone, userPackage } = UserStore

  const vip = useMemo(() => {
    return !!userPackage?.membership_expire_time
  }, [userPackage])

  const vipExpired = useMemo(() => {
    return !!userPackage?.membership_expired
  }, [userPackage])

  return (
    <div className="user-content-header">
      <div className="photo">
        <span className="gradient">Hi</span>
      </div>
      <div className="user-info">
        <div className="phone">
          <label>{phone}</label>
        </div>
        {vip && userPackage ? (
          <>
            {vipExpired ? (
              <label className="text gray">
                套餐已于{dayjs(userPackage.membership_expire_time * 1000).format('YYYY-MM-DD HH:mm')}过期
              </label>
            ) : (
              <label className="text">
                到期时间：{dayjs(userPackage.membership_expire_time * 1000).format('YYYY-MM-DD HH:mm')}
              </label>
            )}
          </>
        ) : (
          <label className="text">未开通会员</label>
        )}
      </div>
    </div>
  )
}

export default memo(UserModalHeader)
