import './style.scss'
import { Button, Modal, Popover, Radio, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import * as Api from '@/api/account'
import * as homeApi from '@/api/home'
import Code from '@/assets/code.webp'
import IconChecked from '@/assets/icon-checked.png'
import { IProduct, UserStore } from '@/global-states'
import { eventTracking } from '@/libs/util'
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import CheckLogin from '../CheckLogin'
import ContractModal from '../ContractModal'
import PayModal from '../PayModal'

export interface IProductList {
  base: IProduct[]
  high: IProduct[]
}

interface IProps {
  onCancel?: () => void
}

const PlanProduct = forwardRef((props: IProps, ref) => {
  const { onCancel } = props
  const { userPackage, payStatus } = UserStore
  const [type, setType] = useState<'month' | 'year'>('month')
  const [tabIndex, setTabIndex] = useState(0)
  const [payModalOpen, setPayModalOpen] = useState(false)
  const [showProducts, setShowProducts] = useState<IProduct[]>([])
  const [payProduct, setPayProduct] = useState<IProduct>()
  const [contractModalOpen, setContractModalOpen] = useState(false)

  const baseProduct = useMemo(() => {
    return showProducts?.find((p) => p?.level === 10 && (type === 'month' ? p?.units === 1 : p?.units === 12))
  }, [showProducts, type])

  const highProduct = useMemo(() => {
    return showProducts?.find((p) => p?.level === 20 && (type === 'month' ? p?.units === 1 : p?.units === 12))
  }, [showProducts, type])

  const blackVipProduct = useMemo(() => {
    return showProducts?.find((p) => p?.level === 50 && (type === 'month' ? p?.units === 1 : p?.units === 12))
  }, [showProducts, type])

  const companyProduct = useMemo(() => {
    return showProducts?.find((p) => p?.level === 100 && (type === 'month' ? p?.units === 1 : p?.units === 12))
  }, [showProducts, type])

  useImperativeHandle(ref, () => ({
    // 暴露给父组件的方法
    update: () => {
      getProductList()
    }
  }))

  useEffect(() => {
    getProductList()
  }, [])

  const getProductList = async () => {
    const { list }: { list: IProduct[] } = await Api.getProductList()

    const showProducts = [
      list.find((l) => l.level === 10 && l.units === 1),
      list.find((l) => l.level === 10 && l.units === 12),
      list.find((l) => l.level === 20 && l.units === 1),
      list.find((l) => l.level === 20 && l.units === 12),
      list.find((l) => l.level === 50 && l.units === 1),
      list.find((l) => l.level === 100 && l.units === 1),
      list.find((l) => l.level === 100 && l.units === 12)
    ]
    setShowProducts(showProducts as IProduct[])

    const vipProduct = list.find((l) => l.level === 50 && l.units === 1)

    if (vipProduct && vipProduct.sale_opening_time) {
      const time = vipProduct.sale_opening_time * 1000 - Date.now()
      if (time > 0) {
        setTimeout(() => {
          getProductList()
        }, time)
      }
    }
  }

  const upgradeTip = (e: any) => {
    e.stopPropagation()
    Modal.warn({
      title: '升级规则',
      content: (
        <div className="declare-list" style={{ marginLeft: -20 }}>
          <ul style={{ paddingLeft: 10 }}>
            <li style={{ padding: '8px 0' }}>升级后功能权益立即生效, 升级后套餐的积分即刻到账;</li>
            <li style={{ padding: '8px 0' }}>旧套餐不做退补差价，已经给到的积分不会失效;</li>
            <li style={{ padding: '8px 0' }}>
              旧套餐剩余权益(会员时间等)自动延后，在升级套餐结束后继续生效。例如: 用户当前是基础单月会员(2030-02-01 ~
              2030-03-01)，该会员将在2030-03-01到期，如果用户在2030-02-15当天选择升级尊享单月会员，尊享单月会员立即生效(2030-02-15
              ~ 2030-03-15)，尊享单月会员在2030-03-15到期后，旧的基础单月会员还有15天权益，将于(2030-03-15 ~
              2030-04-01)继续生效。
            </li>
          </ul>
        </div>
      ),
      okText: '知道了'
    })
  }

  const onSub = (product?: IProduct) => {
    onCancel?.()
    setPayProduct(product)
    setPayModalOpen(true)
  }

  const onSubCompany = async () => {
    const res = await homeApi.checkContract()
    onCancel?.()
    setPayProduct(companyProduct)
    if (res?.require_new_contract) {
      setContractModalOpen(true)
    } else {
      setPayModalOpen(true)
    }
  }

  return (
    <>
      <div className="plan-content-main" style={{ opacity: showProducts?.length ? 1 : 0 }}>
        <Radio.Group
          value={tabIndex}
          onChange={(e) => {
            const index = e.target.value
            setTabIndex(index)
          }}
          buttonStyle="solid"
        >
          <Radio.Button value={0}>个人版</Radio.Button>
          <Radio.Button value={1}>企业版</Radio.Button>
        </Radio.Group>

        <Radio.Group
          value={type}
          onChange={(e) => {
            const type: 'month' | 'year' = e.target.value
            setType(type)
          }}
        >
          <Radio value={'month'}>月会员</Radio>
          <Radio value={'year'}>年会员</Radio>
        </Radio.Group>

        {tabIndex === 0 ? (
          <div className="plan-list">
            <div className="list base">
              <div className="title">基础版</div>
              <div className="price">
                <label className="num">
                  ¥{((baseProduct?.discounted_unit_price || 0) * (baseProduct?.units || 0)) / 100}
                </label>
                <label className="unit">/{type === 'month' ? '月' : '年'}</label>
              </div>
              <div className="vip-desc">
                包含<label className="gradient">{(highProduct?.unit_credits || 0) * (highProduct?.units || 0)}</label>
                积分，约可生成<label className="gradient">{type === 'year' ? 360 : 30}</label>分钟视频
              </div>

              {baseProduct?.status === 1 && (
                <CheckLogin onLoginSuccess={getProductList}>
                  <Button
                    type="primary"
                    onClick={() => {
                      eventTracking('BuyNow')
                      onSub(baseProduct)
                    }}
                  >
                    立即购买
                  </Button>
                </CheckLogin>
              )}
              {baseProduct?.status === 2 && (
                <Button
                  type="primary"
                  onClick={() => {
                    eventTracking('UpgradeNow')
                    onSub(baseProduct)
                  }}
                >
                  立即升级
                  <div className="tips" onClick={upgradeTip}>
                    <label>升级说明</label>
                    <InfoCircleOutlined />
                  </div>
                </Button>
              )}
              {baseProduct?.status === 3 && (
                <Button
                  type="primary"
                  onClick={() => {
                    eventTracking('RenewNow')
                    onSub(baseProduct)
                  }}
                >
                  立即续费
                </Button>
              )}
              {baseProduct?.status === 4 && (
                <Button disabled type="default">
                  暂不支持会员降级
                </Button>
              )}

              <div className="vip-rights">
                <div className="rights-item">
                  <div className="left">
                    <div className="title">会员去水印</div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>
                <div className="rights-item">
                  <div className="left">
                    <div className="title">形象复刻</div>
                    <div className="desc gradient">每月100次</div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>
                <div className="rights-item">
                  <div className="left">
                    <div className="title">数字人创作</div>
                    <div className="desc gradient">10积分/秒</div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>
                <div className="rights-item">
                  <div className="left">
                    <div className="title">输出分辨率</div>
                    <div className="desc gradient">最高1080P</div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>
                <div className="rights-item">
                  <div className="left">
                    <div className="title">声音克隆</div>
                    <div className="desc gradient">基础版</div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>

                <div className="rights-item">
                  <div className="left">
                    <div className="title">积分充值</div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>
              </div>
            </div>

            <div className="list actived">
              <div className="list-tag">
                <label className="gradient">最受欢迎</label>
              </div>
              <div className="title">尊享版</div>
              <div className="price">
                <label className="num">
                  ¥{((highProduct?.discounted_unit_price || 0) * (highProduct?.units || 0)) / 100}
                </label>
                <label className="unit">/{type === 'month' ? '月' : '年'}</label>
              </div>
              <div className="vip-desc">
                包含<label className="gradient">{(highProduct?.unit_credits || 0) * (highProduct?.units || 0)}</label>
                积分，约可生成<label className="gradient">{type === 'year' ? 360 : 30}</label>分钟视频
              </div>

              {highProduct?.status === 1 && (
                <CheckLogin onLoginSuccess={getProductList}>
                  <Button
                    type="primary"
                    onClick={() => {
                      eventTracking('BuyNow')
                      onSub(highProduct)
                    }}
                  >
                    立即购买
                  </Button>
                </CheckLogin>
              )}
              {highProduct?.status === 2 && (
                <Button
                  type="primary"
                  onClick={() => {
                    eventTracking('UpgradeNow')
                    onSub(highProduct)
                  }}
                >
                  立即升级
                  <div className="tips" onClick={upgradeTip}>
                    <label>升级说明</label>
                    <InfoCircleOutlined />
                  </div>
                </Button>
              )}
              {highProduct?.status === 3 && (
                <Button
                  type="primary"
                  onClick={() => {
                    eventTracking('RenewNow')
                    onSub(highProduct)
                  }}
                >
                  立即续费
                </Button>
              )}
              {highProduct?.status === 4 && (
                <Button disabled type="default">
                  暂不支持会员降级
                </Button>
              )}

              {highProduct?.status === 5 && (
                <Button disabled type="default">
                  已售罄
                </Button>
              )}

              <div className="vip-rights">
                <div className="rights-item">
                  <div className="left">
                    <div className="title">包含基础版所有权益</div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>
                <div className="rights-item">
                  <div className="left">
                    <div className="title">形象复刻</div>
                    <div className="desc gradient">不限次数</div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>
                <div className="rights-item">
                  <div className="left">
                    <div className="title">数字人创作</div>
                    <div className="desc gradient">9积分/秒</div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>
                <div className="rights-item">
                  <div className="left">
                    <div className="title">输出分辨率</div>
                    <div className="desc gradient">最高4K</div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>
                <div className="rights-item">
                  <div className="left">
                    <div className="title">声音克隆</div>
                    <div className="desc gradient">
                      高保真
                      <Popover
                        content={
                          <div className="common-popover w100">
                            <div>
                              只需录制5~30s录音,即可极速拥有专属AI定制音色。高度还原真人音色特点、说话风格、口音和声学环境。
                              <strong>尊享版会员有高保真声音的创建权和使用权，高保真声音需搭配尊享版会员使用。</strong>
                              详情请查看
                              <a
                                target="_blank"
                                href="https://lingverse.feishu.cn/docx/U6qiddhObonp56x3w1ucAlJ6nqb"
                                rel="noreferrer"
                                style={{ paddingLeft: 2, textDecoration: 'underline' }}
                              >
                                高保真声音介绍文档
                              </a>
                            </div>
                          </div>
                        }
                        placement="top"
                      >
                        <ExclamationCircleOutlined />
                      </Popover>
                    </div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>
                <div className="rights-item">
                  <div className="left">
                    <div className="title">优先队列</div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>
              </div>
            </div>
            {blackVipProduct && (
              <div className="list">
                <div className="list-tag black">
                  <label>高性价比</label>
                </div>
                <div className="title">黑金版</div>
                <div className="price">
                  <label className="num">
                    ¥{((blackVipProduct?.discounted_unit_price || 0) * (blackVipProduct?.units || 0)) / 100}
                  </label>
                  <label className="unit">/{type === 'month' ? '月' : '年'}</label>
                </div>
                <div className="vip-desc">
                  包含
                  <label className="gradient">
                    {(blackVipProduct?.unit_credits || 0) * (blackVipProduct?.units || 0)}
                  </label>
                  积分，约可生成<label className="gradient">{type === 'year' ? 360 : 30}</label>分钟视频
                </div>

                {blackVipProduct?.status === 1 && (
                  <CheckLogin onLoginSuccess={getProductList}>
                    <Button
                      type="primary"
                      onClick={() => {
                        eventTracking('BuyNow')
                        onSub(blackVipProduct)
                      }}
                    >
                      立即购买
                    </Button>
                  </CheckLogin>
                )}
                {blackVipProduct?.status === 2 && (
                  <Button
                    type="primary"
                    onClick={() => {
                      eventTracking('UpgradeNow')
                      onSub(blackVipProduct)
                    }}
                  >
                    立即升级
                    <div className="tips" onClick={upgradeTip}>
                      <label>升级说明</label>
                      <InfoCircleOutlined />
                    </div>
                  </Button>
                )}
                {blackVipProduct?.status === 3 && (
                  <Button
                    type="primary"
                    onClick={() => {
                      eventTracking('RenewNow')
                      onSub(blackVipProduct)
                    }}
                  >
                    立即续费
                  </Button>
                )}
                {blackVipProduct?.status === 4 && (
                  <Button disabled type="default">
                    暂不支持会员降级
                  </Button>
                )}

                {blackVipProduct?.status === 5 && (
                  <Button disabled type="default">
                    {blackVipProduct.sale_opening_time
                      ? `${dayjs(blackVipProduct.sale_opening_time * 1000).format('YYYY-MM-DD HH:mm')}开售`
                      : '已售罄'}
                    <span className="tag">
                      {userPackage?.current_membership_level === 50 ? '限量发售' : '敬请期待'}
                    </span>
                    {blackVipProduct.sale_opening_time && <span className="time"></span>}
                  </Button>
                )}

                <div className="vip-rights">
                  <div className="rights-item">
                    <div className="left">
                      <div className="title">包含尊享版所有权益</div>
                    </div>
                    <div className="right">
                      <img src={IconChecked} />
                    </div>
                  </div>
                  <div className="rights-item">
                    <div className="left">
                      <div className="title">解锁暗黑模式</div>
                      <div className="desc gradient">
                        创作视频不消耗积分
                        <Popover
                          content={
                            <div className="common-popover w100">
                              <div>
                                暗黑模式常见问题请查看 常见问题-【黑金会员专区】
                                <a
                                  target="_blank"
                                  href="https://lingverse.feishu.cn/docx/VxnydIE4ao5NCyx7fnycqPX7npd#share-Evkidf4qYoA4AfxzoZJclzRkndh"
                                  rel="noreferrer"
                                  style={{ paddingLeft: 2, textDecoration: 'underline' }}
                                >
                                  常见问题-【黑金会员专区】
                                </a>
                              </div>
                            </div>
                          }
                          placement="bottom"
                        >
                          <ExclamationCircleOutlined />
                        </Popover>
                      </div>
                    </div>
                    <div className="right">
                      <img src={IconChecked} />
                    </div>
                  </div>
                  <ul>
                    <li>
                      服务器繁忙时（例如工作日白天）任务优先级低于其他用户；繁忙时间段合成速度无法保证。若您对合成时效有要求，请勿选择此模式
                    </li>
                    <li>单个作品最大时长：180s</li>
                    <li>本套餐不支持API方式接入</li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="plan-list">
            <div className="list">
              <div className="title">企业OEM</div>
              <div className="contact">
                <div className="left">
                  <img src={Code} />
                </div>
                <div className="right">
                  <div className="desc-t">OEM定制方案</div>
                  <div className="text">联系商务</div>
                </div>
              </div>
              <Tooltip title="扫描上方二维码联系商务" trigger={['click']}>
                <Button type="primary">联系商务</Button>
              </Tooltip>
              <div className="vip-rights">
                <div className="rights-item">
                  <div className="left">
                    <div className="title">更多会员权益</div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>
                <div className="rights-item">
                  <div className="left">
                    <div className="title">OEM解决方案</div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>
                <div className="rights-item">
                  <div className="left">
                    <div className="title">在线服务支持</div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>
              </div>
            </div>

            <div className="list actived">
              <div className="title">企业API</div>
              <div className="price">
                <label className="num">
                  ¥{((companyProduct?.discounted_unit_price || 0) * (companyProduct?.units || 0)) / 100}
                </label>
                <label className="unit">/{type === 'month' ? '月' : '年'}</label>
              </div>
              <div className="vip-company-desc">
                <div>不含积分</div>
                <div>请根据实际使用情况，自行充值积分</div>
              </div>
              {companyProduct?.status === 1 && (
                <CheckLogin onLoginSuccess={getProductList}>
                  <Button
                    type="primary"
                    onClick={() => {
                      eventTracking('BuyNow')
                      onSubCompany()
                    }}
                  >
                    立即购买
                  </Button>
                </CheckLogin>
              )}
              {companyProduct?.status === 2 && (
                <Button
                  type="primary"
                  onClick={() => {
                    eventTracking('UpgradeNow')
                    onSubCompany()
                  }}
                >
                  立即升级
                  <div className="tips" onClick={upgradeTip}>
                    升级规则 <InfoCircleOutlined />
                  </div>
                </Button>
              )}
              {companyProduct?.status === 3 && (
                <Button
                  type="primary"
                  onClick={() => {
                    eventTracking('RenewNow')
                    onSubCompany()
                  }}
                >
                  立即续费
                </Button>
              )}
              {companyProduct?.status === 4 && <Button disabled>暂不支持会员降级</Button>}
              <div className="vip-rights">
                <div className="rights-item">
                  <div className="left">
                    <div className="title">更多会员权益</div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>

                <div className="rights-item">
                  <div className="left">
                    <div className="title">API接口调用</div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>
                <div className="rights-item">
                  <div className="left">
                    <div
                      className="title border gradient"
                      onClick={() => {
                        window.open('https://api.lingverse.co/hifly.html')
                      }}
                    >
                      API接口文档
                    </div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>
                <div className="rights-item">
                  <div className="left">
                    <div className="title">优先队列</div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>
                <div className="rights-item">
                  <div className="left">
                    <div className="title">在线服务支持</div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>
              </div>
            </div>

            <div className="list">
              <div className="title">更多定制需求</div>
              <div className="contact">
                <div className="left">
                  <img src={Code} />
                </div>
                <div className="right">
                  <div className="desc">
                    <label className="desc-t">企业定制需求</label>
                  </div>
                  <div className="text">联系商务</div>
                </div>
              </div>
              <Tooltip title="扫描上方二维码联系商务" trigger={['click']}>
                <Button type="primary">联系商务</Button>
              </Tooltip>
              <div className="vip-rights">
                <div className="rights-item">
                  <div className="left">
                    <div className="title">更多会员权益</div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>
                <div className="rights-item">
                  <div className="left">
                    <div className="title">企业个性化定制需求</div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>
                <div className="rights-item">
                  <div className="left">
                    <div className="title">在线服务支持</div>
                  </div>
                  <div className="right">
                    <img src={IconChecked} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <PayModal open={payModalOpen} onCancel={() => setPayModalOpen(false)} product={payProduct} />

      <ContractModal
        open={contractModalOpen}
        onCancel={() => setContractModalOpen(false)}
        product={companyProduct}
        onSuccess={() => setPayModalOpen(true)}
      />
    </>
  )
})

PlanProduct.displayName = 'PlanProduct'

export default PlanProduct
