import './style.scss'
import { Button, Checkbox, Input, message, Modal, QRCode, Select } from 'antd'
import { FC, memo, useEffect, useRef, useState } from 'react'
import * as Api from '@/api/account'
import * as HomeApi from '@/api/home'
import { IProduct, UserStore } from '@/global-states'
import { eventTracking } from '@/libs/util'
import { AlipayOutlined, RightOutlined, WechatOutlined } from '@ant-design/icons'
import CheckLogin from '../CheckLogin'
import Coupon from '../Coupon'
import UserModalHeader from '../UserModalHeader'

interface IProps {
  product?: IProduct
  open: boolean
  hiddenInviteCode?: boolean
  onCancel?: () => void
  onSuccess?: () => void
}

const PayModal: FC<IProps> = (props) => {
  const { product, open, hiddenInviteCode, onCancel } = props
  const { userPackage } = UserStore
  const [payMethod, setPayMethod] = useState(1)
  const [loading, setLoading] = useState(false)
  const [payUrl, setPayUrl] = useState('')
  const [checked, setChecked] = useState(true)
  const payTimer = useRef<any>()
  const [affiliateCode, setAffiliateCode] = useState('')
  const [couponList, setCouponList] = useState<any[]>([])
  const [coupon, setCoupon] = useState<any>()
  const [couponOpen, setCouponOpen] = useState(false)
  const [couponId, setCouponId] = useState<number>()

  useEffect(() => {
    setPayMethod(1)
    setPayUrl('')
    clearPayTimer()
    if (open) {
      getClaimedCoupons()
      getUnclaimedCoupons()
      const code = sessionStorage.getItem('code')
      if (code) {
        setAffiliateCode(code)
      } else if (userPackage?.invitor_aff_code) {
        setAffiliateCode(userPackage.invitor_aff_code)
      } else {
        setAffiliateCode('')
      }
    }
  }, [open])

  const getClaimedCoupons = async () => {
    const res = await HomeApi.getClaimedCoupons({
      status: 2
    })
    const list = res?.list || []
    const selectOptions = [
      {
        label: `不使用代金券`,
        value: -1,
        id: -1,
        deduct_amount: 0
      },
      ...(list.map((l: any) => ({
        ...l,
        label: l.title,
        value: l.id
      })) || [])
    ]
    setCouponList(selectOptions)
    const maxItem = selectOptions.reduce((max: any, item: any) => (item.deduct_amount > max.deduct_amount ? item : max))
    if (maxItem) {
      setCoupon(maxItem)
    }
  }

  const getUnclaimedCoupons = async () => {
    const res = await UserStore.getUnclaimedCoupons()
    if (res?.id) {
      setCouponOpen(true)
      setCouponId(res.id)
    }
  }

  const changePayMethod = (method: number) => {
    setPayMethod(method)
  }

  const toPay = async () => {
    setLoading(true)

    try {
      const params: any = {
        package_id: product?.id,
        client_type: 1,
        affiliate_code: affiliateCode
      }
      if (coupon?.id && coupon?.id !== -1) {
        params.coupon_id = coupon.id
      }
      const { pay_order_id, completed } = await Api.orderCreate(params)

      if (completed) {
        message.success('支付成功')
        onCancel?.()
        UserStore.updatePayStatus()
        return
      }

      const buyParams = {
        orderId: pay_order_id,
        payType: payMethod === 0 ? 'ALIPAY' : 'WECHATPAY'
      }

      eventTracking('PayNow', buyParams)

      const buyRes = await Api.paymentInit(buyParams)
      UserStore.updatePayStatus()

      if (payMethod === 1) {
        setPayUrl(buyRes?.pay_list.wechatpayNative?.code_url)
        paymentCheck(pay_order_id)
        payTimer.current = setInterval(() => {
          paymentCheck(pay_order_id)
        }, 3000)
      } else {
        location.href = buyRes?.pay_list?.alipay
      }
    } catch (e: any) {
      message.destroy()
      if (e.code === 14) {
        message.error('套餐已失效，无法购买')
      } else {
        message.error(e.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const paymentCheck = async (orderId: string) => {
    const res = await Api.paymentCheck({
      orderId
    })
    if (res?.is_pay === 1) {
      message.success('支付成功')
      onCancel?.()
      UserStore.updatePayStatus()
    }
  }

  const clearPayTimer = () => {
    if (payTimer.current) {
      clearInterval(payTimer.current)
      payTimer.current = undefined
    }
  }

  return (
    <>
      <Modal className="pay-modal" footer={null} width={660} onCancel={() => onCancel?.()} open={open}>
        <div className="pay-content">
          <UserModalHeader />
          <div className="pay-content-main">
            {!payUrl ? (
              <>
                <div className="pay-methods">
                  <div className="name">支付方式</div>
                  <div className="pay-list">
                    <div
                      className={`item wechat ${payMethod === 1 ? 'actived' : ''}`}
                      onClick={changePayMethod.bind(this, 1)}
                    >
                      <WechatOutlined />
                      <div>微信支付</div>
                    </div>
                    <div
                      className={`item alipay ${payMethod === 0 ? 'actived' : ''}`}
                      onClick={changePayMethod.bind(this, 0)}
                    >
                      <AlipayOutlined />
                      <div>支付宝</div>
                    </div>
                  </div>
                </div>

                {!!product?.details?.length && (
                  <div className="tips">
                    <div className="name">购买获得以下内容:</div>
                    <ul>
                      {product.details.map((d, index) => (
                        <li key={index}>{d}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {!hiddenInviteCode && (
                  <div className="invite-code">
                    <div className="name">
                      <label>输入对方邀请码</label>
                      <span className="text">
                        （成功支付后，双方均可获得
                        <label className="gradient">
                          {product?.duration_type === 1 && product.units === 1 && '5000'}
                          {product?.duration_type === 1 && product.units === 12 && '60000'}
                        </label>
                        积分）
                      </span>
                    </div>
                    <Input
                      value={affiliateCode}
                      placeholder="输入对方邀请码"
                      onChange={(e) => setAffiliateCode(e.target.value)}
                    />
                  </div>
                )}

                <div className="pay-coupon">
                  <div className="name">代金券</div>
                  <div className="detail">
                    {coupon?.id === -1 ? (
                      <>
                        <label className="unuse">不使用代金劵</label>
                      </>
                    ) : (
                      <>
                        <label>- ¥ {coupon?.deduct_amount / 100}</label>
                        <label className="desc">{coupon?.title}</label>
                      </>
                    )}

                    <RightOutlined className="arrow-right" />

                    <Select
                      className="select-coupon"
                      value={coupon?.id}
                      options={couponList}
                      onChange={(_, c) => {
                        setCoupon(c)
                      }}
                      defaultValue={-1}
                    ></Select>
                  </div>
                </div>

                <div className="plan">
                  <div className="name">应付金额</div>
                  {coupon && coupon.deduct_amount > 0 ? (
                    <div className="money">
                      {(product?.total_price || 0) > coupon.deduct_amount ? (
                        <label className="gradient">
                          ￥{((product?.total_price || 0) - coupon.deduct_amount) / 100}
                        </label>
                      ) : (
                        <label className="gradient">￥0</label>
                      )}
                      <label className="origin-price">￥{(product?.total_price || 0) / 100}</label>
                    </div>
                  ) : (
                    <div className="money gradient">￥{(product?.total_price || 0) / 100}</div>
                  )}
                </div>

                <div className="btn">
                  <CheckLogin>
                    <Button disabled={!checked} loading={loading} type="primary" onClick={toPay}>
                      立即支付
                    </Button>
                  </CheckLogin>
                </div>
                <div className="checkbox">
                  <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}></Checkbox>
                  <div>
                    我已阅读并同意
                    <a className="gradient" href="/service_agreement.html" target="_blank">
                      《服务协议》
                    </a>
                  </div>
                </div>
              </>
            ) : (
              <div className="pay-qrcode">
                <div className="pay-desc">
                  <div>微信扫一扫付款（元）</div>
                  {coupon && coupon.deduct_amount > 0 ? (
                    <>
                      {(product?.total_price || 0) > coupon.deduct_amount ? (
                        <div className="mount gradient">
                          {((product?.total_price || 0) - coupon.deduct_amount) / 100}
                        </div>
                      ) : (
                        <div className="mount gradient">{(product?.total_price || 0) / 100}</div>
                      )}
                    </>
                  ) : (
                    <div className="mount gradient">{(product?.total_price || 0) / 100}</div>
                  )}
                </div>
                <QRCode value={payUrl} />
              </div>
            )}
          </div>
        </div>
      </Modal>

      <Coupon
        open={couponOpen}
        couponId={couponId}
        onCancel={() => setCouponOpen(false)}
        onSuccess={() => {
          getClaimedCoupons()
          setCouponOpen(false)
        }}
      />
    </>
  )
}

export default memo(PayModal)
