import './style.scss'
import { FC, memo, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import * as Api from '@/api/account'
import ActivityModal from '@/components/ActivityModal'
import Coupon from '@/components/Coupon'
import { UserStore } from '@/global-states'
import { eventTracking, showPlanModal } from '@/libs/util'
import SettingBanner from './components/Banner'
import NewUserPackage from './components/NewUserPackage'
import PointDetail from './components/PointDetail'
import UserDetail from './components/UserDetail'
import VipAgent from './components/VipAgent'
import VipCompany from './components/VipCompany'

const Setting: FC = () => {
  const { userPackage, payStatus } = UserStore
  const [apiInfo, setApiInfo] = useState<any>({})
  const [activityModalOpen, setActivityModalOpen] = useState(false)
  const [searchParams] = useSearchParams()
  const recharge = searchParams.get('recharge')
  const [couponOpen, setCouponOpen] = useState(false)
  const [couponId, setCouponId] = useState<number>()

  useEffect(() => {
    eventTracking('PersonalCenterPage')
    getUnclaimedCoupons()
    UserStore.checkUserPackage()
  }, [])

  useEffect(() => {
    if (payStatus) {
      setTimeout(() => {
        onPaySuccess()
      }, 300)
    }
  }, [payStatus])

  useEffect(() => {
    if (recharge) {
      showPlanModal()
    }
  }, [recharge])

  useEffect(() => {
    getApiInfo()
  }, [userPackage])

  const getApiInfo = async () => {
    if (userPackage?.current_membership_level >= 100) {
      const res = await Api.getApiInfo()
      setApiInfo(res || {})
    }
  }

  const getUnclaimedCoupons = async () => {
    const res = await UserStore.getUnclaimedCoupons()
    if (res?.id) {
      setCouponOpen(true)
      setCouponId(res.id)
    }
  }

  const onSub = () => {
    showPlanModal()
  }

  const onPaySuccess = async () => {
    await UserStore.updateUserPackage()
    getApiInfo()
  }

  const showActivity = () => {
    setActivityModalOpen(true)
  }

  return (
    <div className="page-common page-setting">
      <div className="common-header">个人中心</div>

      <div className="common-wrapper">
        {userPackage && (
          <>
            <div className="page-setting-user">
              <SettingBanner activityBannerClick={showActivity} />

              <UserDetail onSub={onSub} />

              <VipAgent />

              {userPackage.current_membership_level >= 100 && <VipCompany apiInfo={apiInfo} onSave={getApiInfo} />}

              <NewUserPackage />

              <PointDetail />
            </div>
          </>
        )}
      </div>

      <ActivityModal
        open={activityModalOpen}
        onCancel={() => {
          onPaySuccess()
          setActivityModalOpen(false)
        }}
      />

      <Coupon
        open={couponOpen}
        couponId={couponId}
        onCancel={() => setCouponOpen(false)}
        onSuccess={() => {
          setCouponOpen(false)
          showPlanModal()
        }}
      />
    </div>
  )
}

export default memo(Setting)
